import React, { SVGProps } from 'react';
import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from '../config-icon';

interface Props extends SVGProps<SVGSVGElement> {
  modal_success?: boolean;
}

const SuccessIcon = (props: Props) => {
  const { modal_success = false, ...newProps } = props;
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];

  const secondaryColor = modal_success
    ? config_icon.success
    : config_icon.secondary;
  const primaryColor = modal_success
    ? config_icon.success
    : config_icon.primary;

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...newProps}
    >
      <path
        style={{
          fill: 'none',
          stroke: secondaryColor,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="m6.6 12 3.2 3.2 7.6-7.5"
      />
      <circle
        cx={12}
        cy={12}
        r={10.8}
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  );
};

export default SuccessIcon;
