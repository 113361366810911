import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { userInfoService } from 'services';
import { setAuthObserver } from '../../services/errorHandler/errorHandler';

export type UserAuth = {
  logged: boolean;
  userInfo: UserInfo | null;
};

export interface Actions {
  sessionExpired: () => void;
}

export type AuthContextData = {
  userAuth: UserAuth;
  status: LoadingStatus;
  actions: Actions;
};

type AuthProviderProps = {
  children: ReactNode;
  authUserState?: UserAuth;
};

export const AuthContext = createContext({
  status: 'LOADED',
  userAuth: {
    logged: false,
  },
  actions: {} as Actions,
} as AuthContextData);

const AuthProvider = ({ children, authUserState }: AuthProviderProps) => {
  const [user, setUser] = useState<UserAuth>(
    authUserState || {
      logged: false,
      userInfo: null,
    }
  );
  const [status, setStatus] = useState<LoadingStatus>('LOADING');

  const navigate = useNavigate();

  function sessionExpired() {
    navigate('/session-expired');
  }

  const actions = { sessionExpired };
  const value = { status, userAuth: user, actions };

  const loadUserFromAuth0 = async () => {
    try {
      setStatus('LOADING');
      const response = await userInfoService.getUserInfo();
      setUser({
        logged: true,
        userInfo: response.data,
      });
      setStatus('LOADED');
    } catch (error) {
      setUser({
        logged: false,
        userInfo: null,
      });
      setStatus('ERROR');
    }
  };

  useEffect(() => {
    if (!authUserState) {
      loadUserFromAuth0();
    }
  }, []);

  useEffect(() => {
    setAuthObserver(actions);
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
