import { Translations } from 'hooks/Intl';
import { appointmentsService } from 'services';
import { formatDateByIdiom, stringToDateWithoutTimezone } from 'utils/date';
import { Idiom } from 'utils/intl';
import { listCountries } from 'utils/intl/countries';
import { listIdioms } from 'utils/intl/idioms';
import { listPhones } from 'utils/intl/phones';
import { getStatus } from 'utils/status';

const loadAppointmentsDetails = async (
  appointments: Appointment[]
): Promise<AppointmentDetail[]> => {
  try {
    const promisesAppointmentsDetails =
      appointments?.map(appointment =>
        appointmentsService.getDetail(
          appointment.appointment_details.appointment_id
        )
      ) || [];

    return Promise.all(promisesAppointmentsDetails).then(axiosResponses =>
      axiosResponses.map(response => response.data)
    );
  } catch (error) {
    return [];
  }
};

export const buildCSV = async (
  appointments: Appointment[],
  idiom: Idiom,
  intl: Translations<string>
) => {
  const appointmentsDetails = await loadAppointmentsDetails(appointments);

  const header = `${intl.TEXT_DATE}, ${intl.TEXT_TIME}, ${intl.TEXT_NAME}, ${intl.TEXT_STATUS}, ${intl.TEXT_PHONE}, ${intl.TEXT_EMAIL}, ${intl.TEXT_COUNTRY}, ${intl.TEXT_LANGUAGE}, ${intl.TEXT_QUESTIONS_COVID}, ${intl.TEXT_INFORMATION_NOTES}`;
  let csv = `${header}\n`;

  appointmentsDetails.forEach(row => {
    const { appointment_details, patient_info } = row;

    const formatDate = formatDateByIdiom(
      stringToDateWithoutTimezone(appointment_details?.date),
      idiom
    );
    const formatTime = appointment_details?.date.substring(11, 16);

    const status = getStatus(appointment_details?.status);

    let country = listCountries.find(
      country => country.initials === appointment_details?.location?.country
    );

    let aptmentIdiom = listIdioms.find(
      idiom => idiom.initials === patient_info?.preferred_language
    );
    const phoneInformations = `${patient_info?.phone?.international_prefix} ${patient_info?.phone?.number}`;

    let textPhone = listPhones.find(
      phone =>
        phone.initialEN === patient_info?.phone?.phone_type ||
        phone.initialES === patient_info?.phone?.phone_type ||
        phone.initialPT === patient_info?.phone?.phone_type
    );

    if (country === undefined) {
      country = {
        pt_BR: '',
        en_US: '',
        es_ES: '',
        prioritary: '',
        initials: '',
        idiom: '',
        currency: '',
        continent: '',
        order: 0,
      };
    }
    if (aptmentIdiom === undefined) {
      aptmentIdiom = { pt_BR: '', en_US: '', es_ES: '', initials: '' };
    }
    if (textPhone === undefined) {
      textPhone = {
        pt_BR: '',
        en_US: '',
        es_ES: '',
        initialPT: '',
        initialES: '',
        initialEN: '',
      };
    }

    csv += ` ${formatDate}`;
    csv += `, ${formatTime}`;
    csv += `, ${patient_info?.first_name} ${patient_info?.last_name}`;
    csv += `, ${intl[`TEXT_${status}`]}`;
    csv += `, ${textPhone && textPhone[idiom]} ${phoneInformations}`;
    csv += `, ${patient_info?.email}`;
    csv += `, ${country && country[idiom]} (${
      appointment_details?.location?.state || ''
    } - ${appointment_details?.location?.subdivision || ''})`;
    csv += `, ${aptmentIdiom && aptmentIdiom[idiom]}`;
    csv += ',';
    for (
      let index = 0;
      index < appointment_details?.initial_questions.join(' ').length;
      index++
    ) {
      csv +=
        appointment_details?.initial_questions.join(' ').charAt(index) ===
          ',' ||
        appointment_details?.initial_questions.join(' ').charAt(index) === '\n'
          ? '|'
          : appointment_details?.initial_questions.join(' ').charAt(index);
    }
    csv += ',';
    for (
      let index = 0;
      index < appointment_details?.additional_comment?.length;
      index++
    ) {
      csv +=
        appointment_details?.additional_comment.charAt(index) === ',' ||
        appointment_details?.additional_comment.charAt(index) === '\n'
          ? '|'
          : appointment_details?.additional_comment.charAt(index);
    }
    csv += `\n`;
  });

  const linkHidden = document.createElement('a');
  linkHidden.href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`;
  linkHidden.target = '_blank';
  linkHidden.rel = 'noopener noreferrer';
  const timestamp = new Date().getTime();
  linkHidden.download = `appointments-${timestamp}.csv`;
  linkHidden.click();
};
