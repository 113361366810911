import React from 'react';
import { useTheme } from 'hooks/theme';
import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import { useUser } from 'hooks/User';
import ProfileEnum from 'utils/profile-enum';
import logoNAL from '../../assets/images/logoNAL-2.svg';

const Footer = () => {
  const { theme } = useTheme();
  const { translate } = useIntl();

  const intl = translate(translations);
  const { user } = useUser();
  const profiles = user?.user?.affiliations?.map(
    affiliation => affiliation.role
  );

  const isAdmin = profiles?.includes(ProfileEnum.ADMINISTRATOR);
  const isNurse = profiles?.includes(ProfileEnum.NURSE);

  return (
    <footer className={`${styles.footerContainer} ${styles[theme]}`}>
      <hr className={`${styles.separator} ${styles[theme]}`}></hr>
      <div className={styles.bodyFooter}>
        <div
          className={`${styles.testing} ${
            !(isAdmin || isNurse) && styles.centerContent
          }`}
        >
          <img className={`${styles.logoNAL}`} src={logoNAL} />
        </div>

        {(isAdmin || isNurse) && (
          <div>
            <ul>
              {isAdmin && (
                <li>
                  <Link
                    className={`${styles.footerLink} ${styles[theme]}`}
                    to="/consult-users"
                  >
                    {intl.USERS}
                  </Link>
                </li>
              )}
              {(isAdmin || isNurse) && (
                <li>
                  <Link
                    className={`${styles.footerLink} ${styles[theme]}`}
                    to="/consult-appointments"
                  >
                    {intl.APPOINTMENTS}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <div className={styles.downFooter}>
        <span>{intl.TEXT_COPYRIGHT}</span>
      </div>
    </footer>
  );
};

export default Footer;
