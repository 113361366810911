import { useIntl } from '../../hooks/Intl/index';
import { useUser } from 'hooks/User';
import * as translations from './intl';
import styles from './UnfitProfileNotice.module.scss';
import { useTheme } from 'hooks/theme';
import RedBar from 'components/RedBar/RedBar';

export const UnfitProfileStatus = {
  PATIENT: 'PROFILE-HAVE-NO-ACCESS',
  NOPROFILE: 'MISSING-ADMIN-APPROVAL',
};

const UnfitProfileNotice = () => {
  const { translate } = useIntl();
  const { user } = useUser();
  const { theme } = useTheme();
  const intl = translate(translations);

  const getStatus = () => {
    const splitParams = location.href.split('status=');
    if (splitParams.length < 2) return null;
    const status = splitParams[1].split('?')[0];
    if (
      status !== UnfitProfileStatus.PATIENT &&
      status !== UnfitProfileStatus.NOPROFILE
    )
      return null;
    return status;
  };

  const getMatchingStatusIntl = () => {
    const status = getStatus();
    switch (status) {
      case UnfitProfileStatus.PATIENT:
        return {
          title: intl.TITLE_PAGE_PATIENT,
          description: intl.DESCRIPTION_PATIENT,
        };
      case UnfitProfileStatus.NOPROFILE:
        return {
          title: intl.TITLE_PAGE_MISSING_APPROVED_BY_ADMIN,
          description: intl.DESCRIPTION_MISSING_APPROVED_BY_ADMIN,
        };
      default:
        return {
          title: intl.TITLE_PAGE_MISSING_APPROVED_BY_ADMIN,
          description: intl.DESCRIPTION_MISSING_APPROVED_BY_ADMIN,
        };
    }
  };
  const { description } = getMatchingStatusIntl();

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      {!!description && <RedBar message={description} />}
      <div className={`${styles.page} ${styles[theme]}`}>
        <div className={`row`}>
          <h1>
            {intl.HELLO_TEXT}, {user.user?.first_name} {user.user?.last_name}.
          </h1>
          <p>{intl.MESSAGE_TEXT}</p>
        </div>
      </div>
    </div>
  );
};

export default UnfitProfileNotice;
