import styles from './index.module.scss';
import DotIcon from 'assets/icons/DotIcon';
import { useTheme } from 'hooks/theme';
import { getStatus, getColor } from './utils';
import Typography from 'components/Typography';

interface StepProps {
  stepNumber: number;
  currentStep: number;
}

const Step = ({ stepNumber, currentStep }: StepProps) => {
  const { theme } = useTheme();

  const status = getStatus(stepNumber, currentStep);
  const color = getColor(status);

  return (
    <div className={`${styles.container}`}>
      <div className={styles.item}>
        <DotIcon
          className={`${styles[theme]} ${styles[color]} ${styles.dot}`}
        />
        <Typography
          type="h2"
          weight={status === 'SELECTED' ? 'semibold' : 'regular'}
          color={status === 'SELECTED' ? 'secondary' : 'tertiary'}
          className={styles.label}
        >
          {stepNumber + 1}
        </Typography>
      </div>
    </div>
  );
};

export default Step;
