import { useAuth } from 'hooks/Auth';
import { useUser } from 'hooks/User';
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';

export enum Theme {
  DEFAULT = 'theme-default',
  VISA = 'theme-visa',
}
interface PropsThemeContext {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  isVisa: boolean;
}

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeContext = createContext<PropsThemeContext>({
  theme: Theme.DEFAULT,
  setTheme: () => null,
  isVisa: true,
});

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { user } = useUser();
  const { userAuth } = useAuth();
  const [theme, setTheme] = useState<Theme>(Theme.VISA);

  const isVisa = useMemo(() => theme === Theme.VISA, [theme]);

  useEffect(() => {
    if (userAuth.logged) {
      if (
        user.status === 'LOADED' &&
        user.user !== null &&
        user?.user?.affiliations &&
        user?.user?.affiliations.length > 0
      ) {
        const themeKey = Object.keys(Theme).find(
          partner =>
            user?.user?.affiliations &&
            user?.user?.affiliations[0] &&
            user?.user?.affiliations[0].partner_name === partner
        );
        if (!themeKey) {
          setTheme(Theme.DEFAULT);
        } else {
          setTheme(Object(Theme)[themeKey]);
        }
      }
    } else {
      setTheme(Theme.VISA);
    }
  });

  return (
    <ThemeContext.Provider value={{ theme, setTheme, isVisa }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};

export default ThemeProvider;
