import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'hooks/Intl';
import { formsTranslations } from 'intls';
import getValidationSchema from './validationSchema';
import getInitialValues, {
  NurseAvailabilityForm as INurseAvailabilityForm,
} from './initialValues';
import Input from 'components/Input';
import { useModal } from 'hooks/Modal';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import { settingsService } from 'services';
import { formatNurseAvailabilityToAPI } from './formatToAPI';
import * as translations from './intl';
import FooterButtons from 'components/FooterButtons/FooterButtons';

interface Props {
  currentNurseAvailability: NurseAvailability | undefined;
  current_schedules_allowed: SchedulesRules | undefined;
  reloadPage: VoidFunction;
  partner_id: string;
}

const NurseAvailabilityForm = ({
  currentNurseAvailability,
  reloadPage,
  partner_id,
  current_schedules_allowed,
}: Props) => {
  const { actions } = useModal();
  const { translate } = useIntl();

  const intlForm = translate(formsTranslations);
  const intl = translate(translations);

  const initialValues = useMemo(
    () => getInitialValues(currentNurseAvailability),
    []
  );
  const validationSchema = useMemo(() => getValidationSchema(intlForm), []);

  const onSubmit = async (values: INurseAvailabilityForm) => {
    try {
      actions.showLoading();

      const formatedValues = formatNurseAvailabilityToAPI(values);
      await settingsService.updateRules(partner_id, {
        ...formatedValues,
        schedule_rules: current_schedules_allowed as SchedulesRules,
      });

      actions.hideLoading();
      actions.showModal(
        '',
        <StyledModal type={ModalType.SUCCESS} title={intl.UPDATE_SUCCESS} />,
        false
      );
      reloadPage();
    } catch (error) {
      actions.hideLoading();
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <div className="row">
        <Input
          label={intl.LABEL_NURSE_AVAILABILYT_MON_FRI}
          name="weekdays_7_00"
          formikProps={formik}
          className="col-12 col-md-6"
        />
        <Input
          label={intl.LABEL_NURSE_AVAILABILYT_MON_FRI_07_15}
          name="weekdays_7_15"
          formikProps={formik}
          className="col-12 col-md-6"
        />
        <Input
          label={intl.TEXT_WORKING_DAYS}
          name="weekends_and_nights"
          formikProps={formik}
          className="col-12 col-md-6"
        />
      </div>
      <div className="row">
        <FooterButtons onClickButton={formik.handleSubmit} />
      </div>
    </>
  );
};

export default NurseAvailabilityForm;
