import { PropsWithChildren, useEffect, useState } from 'react';
import {
  getHeaderConfigByTheme,
  HeaderTypes,
  HeaderTypeStyle,
} from 'components/Header/headerConfig';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './MainLayout.module.scss';
import { useTheme } from 'hooks/theme';
import BlueBackground from 'components/BlueBackground';
import { useIntl } from 'hooks/Intl';
import * as translations from 'intls/titlesHeader';

interface MainLayout {
  showNavigation?: boolean;
  logoutOnly?: boolean;
  headerType?: HeaderTypes;
  hasBlueBackground?: boolean;
  titleBlueBackground?: string;
  empty?: boolean;
}

const titleConfig = (intl: any): Record<string, any> => ({
  home: intl.HOME,
  consultUsers: intl.USER_ACCOUNT,
  consultAppoinments: intl.APPOINTMENTS,
  profile: intl.CONTACT_DETALIS,
  admin: intl.SETTINGS,
  completeRegistration: intl.COMPLETE_YOUR_REGISTRATION,
});

const MainLayout = (props: PropsWithChildren<MainLayout>) => {
  const [loading, setLoading] = useState(true);
  const [headerTypeConfig, setHeadertypeConfig] = useState<HeaderTypeStyle>();
  const { theme } = useTheme();
  const { translate } = useIntl();
  const intl = translate(translations);

  useEffect(() => {
    const headerConfig = getHeaderConfigByTheme(theme);
    setHeadertypeConfig(
      headerConfig[props.headerType || HeaderTypes.HEADER_PRIVATE]
    );
    setLoading(false);
  }, [theme, props.headerType]);

  if (loading) {
    return <></>;
  }

  const config = titleConfig(intl);
  const title = props.titleBlueBackground
    ? config[props.titleBlueBackground]
    : '';

  return (
    <>
      <Header
        showNavigation={props.showNavigation}
        headerType={headerTypeConfig as HeaderTypeStyle}
        logoutOnly={props.logoutOnly}
        empty={props.empty}
      />
      {props.hasBlueBackground && <BlueBackground title={title} />}
      <div
        className={`${styles.body} ${
          !props.hasBlueBackground ? styles.marginHeader : ''
        }`}
      >
        {props.children}
      </div>
      <Footer />
    </>
  );
};

export default MainLayout;
