import React from 'react';
import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from './config-icon';

const ChevronRightIcon = props => {
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];

  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="m5 1 5.586 5.586a2 2 0 0 1 0 2.828L5 15"
          stroke={config_icon.secondary}
          strokeWidth={2}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="rotate(-90 8 8)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronRightIcon;
