import React, { useEffect, useState } from 'react';
import { useUser } from 'hooks/User';
import * as translations from './intl';
import { useIntl } from '../../hooks/Intl';
import styles from './index.module.scss';
import UserIcon from 'assets/icons/UserIcon';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { appointmentsService, userService } from 'services';
import GenerateBanner from './AmountInfo/GenerateBanner/GenerateBanner';
import AppointmentIcon from 'assets/icons/AppointmentIcon';
import ProfileEnum from 'utils/profile-enum';
import InfoAmountMultiple from 'components/InfoAmount/InfoAmountMultiple';
import { format, lastDayOfMonth, lastDayOfWeek, startOfWeek } from 'date-fns';
import { AppointmentStatus } from 'utils/category';

const Dashboard = () => {
  const { user } = useUser();
  const { translate } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [amountsInfo, setAmountsInfo] = useState({
    amountAppointmentsPendingToday: 0,
    amountAppointmentsDoneToday: 0,
    amountAppointmentsThisWeek: 0,
    amountAppointmentsThisMonth: 0,
    amountUsersWithoutProfile: 0,
    amountUsersActive: 0,
  });
  const intl = translate(translations);

  const profiles = user?.user?.affiliations?.map(
    affiliation => affiliation.role
  );
  const isAdmin = profiles?.includes(ProfileEnum.ADMINISTRATOR);

  const loadAmountInfos = async () => {
    setLoading(true);
    try {
      const promises = [];
      const today = new Date();

      promises.push(
        appointmentsService.getBy(
          user?.user?.affiliations[0]?.partner_id as string,
          {
            from_date: format(today, 'yyyy-MM-dd'),
            to_date: format(today, 'yyyy-MM-dd'),
            status: AppointmentStatus.PENDING,
          }
        )
      );
      promises.push(
        appointmentsService.getBy(
          user?.user?.affiliations[0]?.partner_id as string,
          {
            from_date: format(today, 'yyyy-MM-dd'),
            to_date: format(today, 'yyyy-MM-dd'),
            status: AppointmentStatus.DONE,
          }
        )
      );

      if (isAdmin) {
        const promisesGetByPartners = [];
        promisesGetByPartners.push(
          userService.getBy(user?.user?.affiliations[0]?.partner_id as string, {
            status: 'UNAFFILIATED',
          })
        );

        if (user.user && user?.user?.affiliations.length > 0) {
          user.user?.affiliations.forEach(affiliation => {
            promisesGetByPartners.push(
              userService.getBy(affiliation.partner_id as string, {
                status: 'AFFILIATED',
              })
            );
          });
        }

        const responses = await Promise.all(promises);
        const responsesByPartners = await Promise.all(promisesGetByPartners);

        const amountPendingUsers =
          responsesByPartners[0].data.paginator.amount_items;

        const amountActiveUsers = responsesByPartners.reduce(
          (accumulator, currentValue, index) => {
            if (index !== 0) {
              return currentValue.data.paginator.amount_items + accumulator;
            }
            return 0;
          },
          0
        );

        setAmountsInfo({
          amountAppointmentsPendingToday:
            responses[0].data.paginator.amount_items,
          amountAppointmentsDoneToday: responses[1].data.paginator.amount_items,
          amountAppointmentsThisWeek: 0,
          amountAppointmentsThisMonth: 0,
          amountUsersWithoutProfile: amountPendingUsers,
          amountUsersActive: amountActiveUsers,
        });
      } else {
        const firstDateOfWeek = format(startOfWeek(today), 'yyyy-MM-dd');
        const lastDateOfWeek = format(lastDayOfWeek(today), 'yyyy-MM-dd');

        const firstDateOfMonth = format(today, 'yyyy-MM-01');
        const lastDateOfMonth = format(lastDayOfMonth(today), 'yyyy-MM-dd');

        promises.push(
          appointmentsService.getBy(
            user?.user?.affiliations[0]?.partner_id as string,
            {
              from_date: firstDateOfWeek,
              to_date: lastDateOfWeek,
            }
          )
        );
        promises.push(
          appointmentsService.getBy(
            user?.user?.affiliations[0]?.partner_id as string,
            {
              from_date: firstDateOfMonth,
              to_date: lastDateOfMonth,
            }
          )
        );

        const responses = await Promise.all(promises);

        setAmountsInfo({
          amountAppointmentsPendingToday:
            responses[0].data.paginator.amount_items,
          amountAppointmentsDoneToday: responses[1].data.paginator.amount_items,
          amountAppointmentsThisWeek: responses[2].data.paginator.amount_items,
          amountAppointmentsThisMonth: responses[3].data.paginator.amount_items,
          amountUsersWithoutProfile: 0,
          amountUsersActive: 0,
        });
      }
    } catch (error) {
      setAmountsInfo({
        amountAppointmentsPendingToday: 0,
        amountAppointmentsDoneToday: 0,
        amountAppointmentsThisWeek: 0,
        amountAppointmentsThisMonth: 0,
        amountUsersWithoutProfile: 0,
        amountUsersActive: 0,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAmountInfos();
  }, [user]);

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <div className="row">
        <h1>
          {intl.HELLO_TEXT},{' '}
          {`${user.user?.first_name} ${user.user?.last_name}.`}
        </h1>
        <p>{intl.MESSAGE_TEXT}</p>
      </div>

      <div className={`row ${styles.content} ${isAdmin ? '' : styles.center}`}>
        {isAdmin && (
          <div className={`col-lg-6 col-md-12 ${styles.box}`}>
            <GenerateBanner
              blue
              icon={<UserIcon width={30} height={30} white />}
              title={intl.TITLE_ASSOCIATE_PROFILE}
              url="/consult-users"
            >
              <div className={styles.infosContent}>
                <InfoAmountMultiple
                  Icon={[UserIcon, UserIcon]}
                  amounts={[
                    amountsInfo.amountUsersWithoutProfile,
                    amountsInfo.amountUsersActive,
                  ]}
                  labels={[intl.TEXT_NO_PROFILE_USERS, intl.TEXT_ACTIVE_USERS]}
                />
              </div>
            </GenerateBanner>
          </div>
        )}
        <div
          className={`col-lg-6 col-md-12 ${styles.box} ${
            isAdmin ? '' : styles.adaptiveBox
          }`}
        >
          {isAdmin ? (
            <GenerateBanner
              blue
              icon={<AppointmentIcon width={30} height={30} white />}
              title={intl.TITLE_STATUS_APPOINTMENT}
              url="/consult-appointments"
            >
              <div className={styles.infosContent}>
                <InfoAmountMultiple
                  Icon={[AppointmentIcon, AppointmentIcon]}
                  amounts={[
                    amountsInfo.amountAppointmentsPendingToday,
                    amountsInfo.amountAppointmentsDoneToday,
                  ]}
                  labels={[
                    intl.TEXT_APPOINTMENT_PENDING,
                    intl.TEXT_APPOINTMENT_DONE,
                  ]}
                />
              </div>
            </GenerateBanner>
          ) : (
            <GenerateBanner
              big
              blue
              icon={<AppointmentIcon width={30} height={30} white />}
              title={intl.TITLE_STATUS_APPOINTMENT}
              url="/consult-appointments"
            >
              <div className={`${styles.infosContent} ${styles.big}`}>
                <InfoAmountMultiple
                  big
                  Icon={[
                    AppointmentIcon,
                    AppointmentIcon,
                    AppointmentIcon,
                    AppointmentIcon,
                  ]}
                  amounts={[
                    amountsInfo.amountAppointmentsThisWeek,
                    amountsInfo.amountAppointmentsThisMonth,
                    amountsInfo.amountAppointmentsPendingToday,
                    amountsInfo.amountAppointmentsDoneToday,
                  ]}
                  labels={[
                    intl.TEXT_APPOINTMENT_WEEK,
                    intl.TEXT_APPOINTMENT_MONTH,
                    intl.TEXT_APPOINTMENT_PENDING,
                    intl.TEXT_APPOINTMENT_DONE,
                  ]}
                />
              </div>
            </GenerateBanner>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
