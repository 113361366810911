const COLORS = {
  CONCLUDED: 'const_green',
  SELECTED: 'secondary',
  EMPTY: 'base_gray',
};

export const getStatus = (stepNumber: number, currentStep: number) => {
  if (stepNumber === currentStep) {
    return 'SELECTED';
  } else if (stepNumber < currentStep) {
    return 'CONCLUDED';
  } else {
    return 'EMPTY';
  }
};

export const getColor = (status: string) => {
  switch (status) {
    case 'SELECTED':
      return COLORS.SELECTED;
    case 'CONCLUDED':
      return COLORS.CONCLUDED;
    default:
      return COLORS.EMPTY;
  }
};
