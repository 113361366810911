import Input from 'components/Input';
import Select from 'components/Select';
import { FormikProps } from 'formik';
import { useIntl } from 'hooks/Intl';
import { formsTranslations } from 'intls';
import { ChangeEvent, useMemo } from 'react';
import { RegistrationFormValues } from '../../initialValues';

interface PhoneAreaProps {
  formikProps: FormikProps<RegistrationFormValues>;
}

const PhoneArea = ({ formikProps }: PhoneAreaProps) => {
  const { setFieldValue, handleChange } = formikProps;

  const { translate, idiom } = useIntl();
  const intl = useMemo(() => translate(formsTranslations), [idiom]);

  return (
    <>
      <Select
        label={intl.PHONE_TYPE}
        formikProps={formikProps}
        name="phone.type"
        className="col-12 col-sm-12 col-md-3"
      >
        <option value="">{intl.PHONE_TYPE}</option>
        <option value="LANDLINE">{intl.VALUE_PHONE_TYPE_LANDLINE}</option>
        <option value="MOBILE">{intl.VALUE_PHONE_TYPE_MOBILE}</option>
      </Select>
      <Input
        label={intl.PREFIX}
        formikProps={formikProps}
        name="phone.international_prefix"
        className="col-12 col-sm-12 col-md-3"
        tooltip={intl.INCLUDE_COUNTRY_CODE}
        onChange={(event: ChangeEvent) => {
          setFieldValue('phone.number', '');
          handleChange(event);
        }}
        mask="+0000"
        maxLength={5}
      />
      <Input
        label={intl.AREA_CODE_AND_NUMBER}
        formikProps={formikProps}
        name="phone.number"
        className="col-12 col-sm-12 col-md-6"
        mask={/^[0-9-(-)+$|^$|^\s$]*$/}
        maxLength={17}
      />
    </>
  );
};

export default PhoneArea;
