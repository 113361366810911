import React from 'react';
import styles from './ModalLoading.module.scss';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { useModal } from '../../../hooks/Modal/index';
import Typography from 'components/Typography';
import { useIntl } from '../../../hooks/Intl/index';
import * as translations from './intl';

const ModalLoading = () => {
  const { state } = useModal();
  const { translate } = useIntl();

  const intl = translate(translations);
  const { loadingMessage } = state;

  return (
    <div className={styles.loadingContainer}>
      <LoadingSpinner />
      <Typography type="h1" color="primary" weight="light">
        {loadingMessage || intl.LOADING}
        <span className={styles.dot}>.</span>
        <span className={styles.dot}>.</span>
        <span className={styles.dot}>.</span>
      </Typography>
    </div>
  );
};

export default ModalLoading;
