import YourAccount from './YourAccount/YourAccount';

export const isNull = (prop: any) => {
  return prop ? prop : '-';
};

const Profile = () => {
  return (
    <div>
      <YourAccount />
    </div>
  );
};

export default Profile;
