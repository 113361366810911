const nationalitiesPt = {
  values: [
    'AI',
    'AG',
    'AR',
    'BS',
    'BB',
    'BZ',
    'BO',
    'BR',
    'CL',
    'CO',
    'CR',
    'CU',
    'DM',
    'EC',
    'SV',
    'GD',
    'GT',
    'GY',
    'HT',
    'HN',
    'JM',
    'MX',
    'NI',
    'PA',
    'PY',
    'PE',
    'DO',
    'VC',
    'LC',
    'SR',
    'TT',
    'UY',
    'VE',
    'AU',
    'US',
    'ES',
    'GB',
    'CA',
    'AF',
    'AL',
    'DE',
    'AD',
    'AO',
    'TF',
    'AN',
    'AQ',
    'DZ',
    'AM',
    'AW',
    'SA',
    'AC',
    'AZ',
    'BH',
    'BD',
    'BJ',
    'BM',
    'BY',
    'BW',
    'BN',
    'BG',
    'BF',
    'BI',
    'BT',
    'BE',
    'BA',
    'CV',
    'CM',
    'KH',
    'QA',
    'KZ',
    'TD',
    'CN',
    'CY',
    'CC',
    'KM',
    'CG',
    'KR',
    'CI',
    'HR',
    'CW',
    'DK',
    'DJ',
    'EG',
    'UA',
    'ER',
    'SK',
    'SI',
    'FM',
    'EE',
    'ET',
    'FJ',
    'PH',
    'FI',
    'FR',
    'GA',
    'GH',
    'GE',
    'GI',
    'GL',
    'GR',
    'GP',
    'GU',
    'GN',
    'GF',
    'GN',
    'GQ',
    'GW',
    'GM',
    'NL',
    'HK',
    'HU',
    'CX',
    'UM',
    'NF',
    'RE',
    'UM',
    'KY',
    'NZ',
    'CK',
    'FK',
    'FO',
    'MP',
    'MH',
    'YT',
    'SB',
    'TC',
    'VG',
    'WF',
    'ID',
    'IQ',
    'IE',
    'IM',
    'IS',
    'IL',
    'IT',
    'YE',
    'JP',
    'JO',
    'KI',
    'XK',
    'KW',
    'LA',
    'LS',
    'LV',
    'LR',
    'LI',
    'LT',
    'LU',
    'LB',
    'LY',
    'MO',
    'MK',
    'MG',
    'MV',
    'ML',
    'MT',
    'MY',
    'MW',
    'MA',
    'MQ',
    'MR',
    'MU',
    'MD',
    'MN',
    'MS',
    'MZ',
    'MM',
    'MC',
    'NA',
    'NR',
    'NP',
    'KN',
    'NE',
    'NG',
    'NU',
    'NO',
    'NC',
    'NZ',
    'OM',
    'PW',
    'PG',
    'PK',
    'PF',
    'PL',
    'PR',
    'PT',
    'KG',
    'KE',
    'AE',
    'CF',
    'CZ',
    'RO',
    'RW',
    'RU',
    'MP',
    'SW',
    'AS',
    'SM',
    'SN',
    'SL',
    'SC',
    'SG',
    'SO',
    'LK',
    'SH',
    'PM',
    'SZ',
    'SE',
    'CH',
    'ST',
    'RS',
    'TH',
    'TW',
    'TJ',
    'TZ',
    'TG',
    'TO',
    'TK',
    'TN',
    'TM',
    'TR',
    'TV',
    'UA',
    'UG',
    'VI',
    'UZ',
    'VU',
    'VA',
    'VN',
    'YU',
    'ZR',
    'TZ',
    'ZW',
    'ZM',
    'ZA',
    'AT',
    'IN',
  ],
  labels: [
    'Anguilano',
    'Antiguano',
    'Argentino',
    'Bahamense',
    'Barbadense',
    'Belizenho',
    'Boliviano',
    'Brasileiro',
    'Chileno',
    'Colombiano',
    'Costarriquenho',
    'Cubano',
    'Dominicano',
    'Equatoriano',
    'Salvadorenho',
    'Granadino',
    'Guatemalteco',
    'Guianense',
    'Haitiano',
    'Hondurenho',
    'Jamaicano',
    'Mexicano',
    'Nicaraguense',
    'Panamenho',
    'Paraguaio',
    'Peruano',
    'Dominicana',
    'São Vicente e Granadinas',
    'Santa-luciense',
    'Surinamês',
    'Trinitino-tobaguense',
    'Uruguaio',
    'Venezuelano',
    'Australiano',
    'Americano',
    'Espanhol',
    'Britânico',
    'Canadense',
    'Afegão',
    'Albanês',
    'Alemão',
    'Andorrano',
    'Angolano',
    'Antilhas Francesas',
    'Antilhano',
    'Antártica',
    'Argelino',
    'Armeno',
    'Arubano',
    'Saudita',
    'Ascension',
    'Azeri',
    'Barenita',
    'Bangladechiano',
    'Beninense',
    'Bermudense',
    'Bielorrusso',
    'Bechuano',
    'Bruneano',
    'Búlgaro',
    'Burquinense',
    'Burundês',
    'Butanês',
    'Belga',
    'Bósnio',
    'Cabo-verdiano',
    'Camaronense',
    'Cambojano',
    'Qatarense',
    'Cazaque',
    'Chadiano',
    'Chinês',
    'Cipriota',
    'Ilhas Cocos',
    'Comorense',
    'Congolense',
    'Sul-coreano',
    'Marfinense',
    'Croata',
    'Curaçauense',
    'Dinamarquês',
    'Djibutiano',
    'Egípcio',
    'Árabe',
    'Eritreu',
    'Eslovaco',
    'Esloveno',
    'Micronésio',
    'Estoniano',
    'Etíope',
    'Fijiano',
    'Filipino',
    'Finlandês',
    'Francês',
    'Gabonense',
    'Ganés',
    'Geórgico',
    'Gibraltino',
    'Groenlandês',
    'Grego',
    'Guadalupense',
    'Guamês',
    'Guianês',
    'Guianense',
    'Guineano',
    'Guinéu-equatoriano',
    'Guineense',
    'Gambiano',
    'Holandês',
    'Honconguês',
    'Húngaro',
    'Ilha de Natal',
    'Ilha Midway',
    'Ilha Norfolk',
    'Ilha Reunião',
    'Ilha Wake',
    'Caimanês',
    'Ilhas Chatham',
    'Cookense',
    'Ilhas Falkland',
    'Faroense',
    'Ilhas Mariana',
    'Marshallino',
    'Ilhas Mayotte',
    'Salomônico',
    'Ilhas Turks e Caicos',
    'virginense (Britânicas)',
    'Wallis Ilhéu',
    'Indonésio',
    'Iraquiano',
    'Irlandês',
    'Ilha de Man',
    'Islandês',
    'Israelita',
    'Italiano',
    'Iemenita',
    'Japonês',
    'Jordão',
    'Quiribatiano',
    'Kosovar',
    'Kuwaitiano',
    'Laociano',
    'Lesotiano',
    'Letoniano',
    'Liberiano',
    'Liechtensteinense',
    'Lituano',
    'Luxemburguês',
    'Libanês',
    'Líbio',
    'Macaense',
    'Macedônio',
    'Madagascarense',
    'Maldivo',
    'Maliano',
    'Maltês',
    'Malaio',
    'Malauiano',
    'Marroquino',
    'Martinicano',
    'Mauritano',
    'Mauriciano',
    'Moldavo',
    'Mongol',
    'Monserratense',
    'Moçambicano',
    'Birmanês',
    'Monegasco',
    'Namibiano',
    'Nauruano',
    'Nepalês',
    'São-cristovense',
    'Nigerino',
    'Nigeriano',
    'Niuano',
    'Noruego',
    'Neocaledónio',
    'Neozelandês',
    'Omani',
    'Palauense',
    'Papuásio',
    'Paquistanês',
    'Polinésio',
    'Polonês',
    'Portorriquenho',
    'Português',
    'Quirguiz',
    'Queniano',
    'Emiradense',
    'Centroafricano',
    'Tcheco',
    'Romeno',
    'Ruandês',
    'Russo',
    'Saipan',
    'Samoano',
    'Samoense',
    'São-marinense',
    'Senegalense',
    'Serra-leonês',
    'Seichelense',
    'Singapurense',
    'Somali',
    'Cingalês',
    'Santa Helena',
    'São Pedro e Miquelão',
    'Suazi',
    'Sueco',
    'Suíço',
    'São-tomense',
    'Sérvio',
    'Tailandês',
    'Taiwanês',
    'Tajique',
    'Tanzaniano',
    'Togolês',
    'Tonganês',
    'Toquelauana',
    'Tunisiano',
    'Turcomeno',
    'Turco',
    'Tuvaluano',
    'Ucraniano',
    'Ugandense',
    'virginense (Americanas)',
    'Usbeque',
    'Vanuatuense',
    'Vaticanense',
    'Vietnamita',
    'Iugoslavo',
    'Zairense',
    'Zanzibar',
    'Zimbabuense',
    'Zambiano',
    'Sul-africano',
    'Austríaco',
    'Indiano',
  ],
};
const nationalitiesEn = {
  values: [
    'AI',
    'AG',
    'AR',
    'BS',
    'BB',
    'BZ',
    'BO',
    'BR',
    'CL',
    'CO',
    'CR',
    'CU',
    'DM',
    'EC',
    'SV',
    'GD',
    'GT',
    'GY',
    'HT',
    'HN',
    'JM',
    'MX',
    'NI',
    'PA',
    'PY',
    'PE',
    'DO',
    'VC',
    'LC',
    'SR',
    'TT',
    'UY',
    'VE',
    'AU',
    'US',
    'ES',
    'GB',
    'CA',
    'AF',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AQ',
    'AM',
    'AW',
    'AC',
    'AT',
    'AZ',
    'BH',
    'BD',
    'BY',
    'BE',
    'BJ',
    'BM',
    'BT',
    'GW',
    'BA',
    'BW',
    'VG',
    'BN',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CV',
    'KY',
    'CF',
    'TD',
    'NZ',
    'CN',
    'CX',
    'CC',
    'KM',
    'CG',
    'CK',
    'HR',
    'CW',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'EG',
    'GQ',
    'ER',
    'EE',
    'ET',
    'FK',
    'FO',
    'FM',
    'FJ',
    'FI',
    'FR',
    'TF',
    'GF',
    'PF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GP',
    'GU',
    'GN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'CI',
    'JP',
    'JO',
    'KZ',
    'KE',
    'KI',
    'XK',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MP',
    'MH',
    'MQ',
    'MR',
    'MU',
    'YT',
    'UM',
    'MD',
    'MC',
    'MN',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'AN',
    'NL',
    'NC',
    'NZ',
    'NE',
    'NG',
    'NU',
    'NF',
    'NO',
    'OM',
    'PK',
    'PW',
    'PG',
    'PH',
    'PL',
    'PT',
    'PR',
    'QA',
    'RS',
    'RE',
    'RO',
    'RU',
    'RW',
    'MP',
    'SM',
    'ST',
    'SA',
    'SN',
    'SC',
    'SL',
    'SG',
    'SK',
    'SI',
    'VN',
    'SB',
    'SO',
    'ZA',
    'KR',
    'LK',
    'SH',
    'KN',
    'PM',
    'SZ',
    'SE',
    'CH',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TG',
    'TK',
    'TO',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'VI',
    'UZ',
    'VU',
    'VA',
    'UM',
    'WF',
    'SW',
    'YE',
    'YU',
    'ZR',
    'ZM',
    'TZ',
    'ZW',
  ],
  labels: [
    'Anguillian',
    'Antiguan and Barbudan',
    'Argentinian',
    'Bahamian',
    'Barbadian',
    'Belizean',
    'Bolivian',
    'Brazilian',
    'Chilean',
    'Colombian',
    'Costa Rican',
    'Cuban',
    'Dominican',
    'Ecuadorian',
    'Salvadorian',
    'Grenadian',
    'Guatemalan',
    'Guyanese',
    'Haitian',
    'Honduran',
    'Jamaican',
    'Mexican',
    'Nicaraguan',
    'Panamanian',
    'Paraguayan',
    'Peruvian',
    'Dominican',
    'Vicentinian',
    'St Lucian',
    'Surinamese',
    'Trinidadian',
    'Uruguayan',
    'Venezuelan',
    'Australian',
    'American',
    'Spanish',
    'British',
    'Canadian',
    'Afghan',
    'Albanian',
    'Algerian',
    'Samoan',
    'Andorran',
    'Angolan',
    'Antarctica',
    'Armenian',
    'Aruban',
    'Ascension',
    'Austrian',
    'Azerbaijani',
    'Bahraini',
    'Bangladeshi',
    'Belarusian',
    'Belgian',
    'Beninese',
    'Bermudian',
    'Bhutanese',
    'Bissau–guinean',
    'Bosnian',
    'Motswana',
    'British Virgin Islander',
    'Bruneian',
    'Bulgarian',
    'Burkinabé',
    'Burundian',
    'Cambodian',
    'Cameroonian',
    'Cape Verdean',
    'Cayman Islander',
    'Central-african',
    'Chadian',
    'Chatham Islander NZ',
    'Chinese',
    'Christmas Islander',
    'Cocos Islander',
    'Comoran',
    'Congolese',
    'Cook Islander',
    'Croatian',
    'Curacaoan',
    'Cypriot',
    'Czech',
    'Danish',
    'Djiboutian',
    'Egyptian',
    'Equatoguinean',
    'Eritrean',
    'Estonian',
    'Ethiopian',
    'Falkland Islanders',
    'Faroese',
    'Micronesian',
    'Fijian',
    'Finnish',
    'French',
    'French Antilles',
    'Guyanese',
    'French Polynesian',
    'Gabonese',
    'Gambian',
    'Georgian',
    'German',
    'Ghanaian',
    'Gibraltarian',
    'Greek',
    'Greenlander',
    'Guadeloupean',
    'Guamanian',
    'Guinean',
    'Hongkonger',
    'Hungarian',
    'Icelander',
    'Indian',
    'Indonesian',
    'Iraqi',
    'Irish',
    'Manx',
    'Israeli',
    'Italian',
    'Ivorian',
    'Japanese',
    'Jordanian',
    'Kazakh',
    'Kazakh',
    'I-kiribati',
    'Kosovar',
    'Kwaiti',
    'Kyrgyzstani',
    'Laotian',
    'Latvian',
    'Lebanese',
    'Basotho',
    'Liberian',
    'Libyan',
    'Liechtensteiner',
    'Lithuanian',
    'Luxembourgish',
    'Macau',
    'Macedonian',
    'Malagasy',
    'Malawian',
    'Malaysian',
    'Maldivian',
    'Malian',
    'Maltese',
    'Mariana Islander',
    'Marshall Islander',
    'Martinican',
    'Mauritanian',
    'Mauritian',
    'Mahoran',
    'Midway Islands',
    'Moldovan',
    'Monacan',
    'Mongolian',
    'Montserratian',
    'Moroccan',
    'Mozambican',
    'Burmese',
    'Namibian',
    'Nauruan',
    'Nepali',
    'Netherlands Antillean',
    'Dutch',
    'New Caledonian',
    'New Zealander',
    'Nigerien',
    'Nigerian',
    'Niuean',
    'Norfolk Islander',
    'Norwegian',
    'Omani',
    'Pakistanese',
    'Palauan',
    'Papua New Guinean',
    'Philippine',
    'Polish',
    'Portuguese',
    'Puerto Rican',
    'Qatari',
    'Serbian',
    'Réunionese',
    'Romanian',
    'Russian',
    'Rwandan',
    'Saipanese',
    'San Marinan',
    'Saint Thomas and Prince',
    'Saudi',
    'Senegalese',
    'Seychellois',
    'Sierra Leonean',
    'Singaporean',
    'Slovak',
    'Slovene',
    'Vietnamese',
    'Solomon Islander',
    'Somali',
    'South African',
    'Korean',
    'Sri Lankan',
    'Saint Helenian',
    'Kittian',
    'Saint-Pierrais',
    'Swazi',
    'Swedish',
    'Swiss',
    'Taiwanese',
    'Tajiki',
    'Tanzanian',
    'Thai',
    'Togolese',
    'Tokelauan',
    'Tongan',
    'Tunisian',
    'Turkish',
    'Turkmen',
    'Turks and Caicos Islander',
    'Tuvaluan',
    'Ugandan',
    'Ukrainian',
    'Emirati',
    'Virgin Islander (US',
    'Uzbek',
    'Ni-vanuatu',
    'Vatican City',
    'Wakean',
    'Wallis and Futuna Islander',
    'Samoan',
    'Yemeni',
    'Yugoslavian',
    'Zairian',
    'Zambian',
    'Zanzibari',
    'Zimbabwean',
  ],
};

const nationalitiesEs = {
  values: [
    'AI',
    'AG',
    'AR',
    'BS',
    'BB',
    'BZ',
    'BO',
    'BR',
    'CL',
    'CO',
    'CR',
    'CU',
    'DM',
    'EC',
    'SV',
    'GD',
    'GT',
    'GY',
    'HT',
    'HN',
    'JM',
    'MX',
    'NI',
    'PA',
    'PY',
    'PE',
    'DO',
    'VC',
    'LC',
    'SR',
    'TT',
    'UY',
    'VE',
    'AU',
    'US',
    'ES',
    'GB',
    'CA',
    'AF',
    'AL',
    'DE',
    'AD',
    'AO',
    'TF',
    'AN',
    'AQ',
    'SA',
    'DZ',
    'AM',
    'AW',
    'AC',
    'AT',
    'AZ',
    'BH',
    'BD',
    'BY',
    'BJ',
    'BM',
    'BA',
    'BW',
    'BN',
    'BG',
    'BF',
    'BI',
    'BT',
    'BE',
    'KH',
    'CM',
    'CV',
    'QA',
    'TD',
    'CN',
    'CY',
    'KM',
    'CG',
    'KR',
    'CI',
    'HR',
    'CW',
    'DK',
    'DJ',
    'EG',
    'AE',
    'ER',
    'SK',
    'SI',
    'FM',
    'EE',
    'ET',
    'PH',
    'FI',
    'FJ',
    'FR',
    'GA',
    'GM',
    'GE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GP',
    'GU',
    'GF',
    'GQ',
    'GW',
    'HK',
    'HU',
    'IN',
    'ID',
    'IQ',
    'IE',
    'IM',
    'CX',
    'YT',
    'NF',
    'RE',
    'UM',
    'WF',
    'IS',
    'KY',
    'NZ',
    'CC',
    'CK',
    'FK',
    'FO',
    'MP',
    'MH',
    'UM',
    'SB',
    'VG',
    'VI',
    'IL',
    'IT',
    'JP',
    'JO',
    'KZ',
    'KE',
    'KG',
    'KI',
    'XK',
    'KW',
    'LA',
    'LS',
    'LV',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'LB',
    'MO',
    'MK',
    'MG',
    'MY',
    'MW',
    'MV',
    'MT',
    'ML',
    'MA',
    'MQ',
    'MU',
    'MR',
    'MD',
    'MN',
    'MS',
    'MZ',
    'MM',
    'MC',
    'NA',
    'NR',
    'NP',
    'NG',
    'NU',
    'NO',
    'NC',
    'NZ',
    'NE',
    'OM',
    'PK',
    'PW',
    'PG',
    'NL',
    'PF',
    'PL',
    'PT',
    'PR',
    'GN',
    'CF',
    'CZ',
    'RW',
    'RO',
    'RU',
    'MP',
    'SW',
    'AS',
    'SM',
    'ST',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'VN',
    'SO',
    'LK',
    'SH',
    'KN',
    'PM',
    'ZA',
    'SE',
    'CH',
    'SZ',
    'TH',
    'TW',
    'TZ',
    'TJ',
    'TG',
    'TK',
    'TO',
    'TC',
    'TM',
    'TR',
    'TV',
    'TN',
    'UA',
    'UG',
    'UZ',
    'VU',
    'VA',
    'YE',
    'YU',
    'ZR',
    'ZM',
    'TZ',
    'ZW',
  ],
  labels: [
    'Anguilense',
    'Antiguano',
    'Argentino',
    'Bahamés',
    'Barbadense',
    'Beliceño',
    'Boliviano',
    'Brasileño',
    'Chileno',
    'Colombiano',
    'Costarricense',
    'Cubano',
    'Dominiqués',
    'Ecuatoriano',
    'Salvadoreño',
    'Granadino',
    'Guatemalteco',
    'Guyanés',
    'Haitiano',
    'Hondureño',
    'Jamaicano',
    'Mexicano',
    'Nicaragüense',
    'Panameño',
    'Paraguayo',
    'Peruano',
    'Dominicano',
    'Sanvicentino',
    'Santaluciano',
    'Surinamés',
    'Trinitario',
    'Uruguayo',
    'Venezolano',
    'Australiano',
    'Estadounidense',
    'Español',
    'Británico',
    'Canadiense',
    'Afgano',
    'Albanés',
    'Alemán',
    'Andorrano',
    'Angoleño',
    'Antillas Francesas',
    'Antillas Neerlandesas',
    'Antártida',
    'Saudita',
    'Argelino',
    'Armeno',
    'Arubeño',
    'Ascension',
    'Austriaco',
    'Azerbaiyano',
    'Bareiní',
    'Blangladeshí',
    'Bielorrusos',
    'Beninés',
    'Bermudeño',
    'Bosnio',
    'Botsuano',
    'Bruneano',
    'Búlgaro',
    'Burkinés',
    'Burundés',
    'Butanés',
    'Belga',
    'Camboyano',
    'Camerunés',
    'Caboverdiano',
    'Qatarí',
    'Chadiano',
    'Chino',
    'Chipriota',
    'Comorense',
    'Congoleño',
    'Surcoreano',
    'Marfileño',
    'Croata',
    'Curazoleño',
    'Danés',
    'Yibutiano',
    'Egipcio',
    'Emiratí',
    'Eritreo',
    'Eslovaco',
    'Esloveno',
    'Micronesio',
    'Estonio',
    'Etíope',
    'Filipino',
    'Finlandés',
    'Fiyiano',
    'Francés',
    'Gabonés',
    'Gambiano',
    'Georgiano',
    'Ghanés',
    'Gibraltareño',
    'Griego',
    'Groenlandés',
    'Guadalupeño',
    'Guameño',
    'Francoguayanés',
    'Ecuatoguineano',
    'Bisauguineano',
    'Hongkonés',
    'Húngaro',
    'Indiano',
    'Indonesio',
    'Iraquí',
    'Irlandés',
    'Manés',
    'Navideño',
    'Isla Mayotte',
    'Norfolkense',
    'Reunionés',
    'Isla Wake',
    'Walisiano',
    'Islandés',
    'Caimanés',
    'Islas Chathan',
    'Cocano',
    'Cookiano',
    'Islas Falkland',
    'Feroés',
    'Chamorro',
    'Marshalés',
    'Islas Midway',
    'Salomonense',
    'Virgenense británico',
    'Virgenense estadounidense',
    'Israelí',
    'Italiano',
    'Japonés',
    'Jordano',
    'Kazajo',
    'Keniano',
    'Kirguís',
    'Kiribatiano',
    'Kosovar',
    'Kuwaití',
    'Laosiano',
    'Lesotense',
    'Letón',
    'Liberiano',
    'Libio',
    'Liechtensteiniano',
    'Lituano',
    'Luxemburgués',
    'Libanés',
    'Macaense',
    'Macedonio',
    'Malgache',
    'Malasio',
    'Malauí',
    'Maldivo',
    'Maltés',
    'Maliense',
    'Marroquí',
    'Martiniqués',
    'Mauriciano',
    'Mauritano',
    'Moldavo',
    'Mongol',
    'Monserratino',
    'Mozambiqueño',
    'Birmano',
    'Monegasco',
    'Namibio',
    'Nauruano',
    'Nepalí',
    'Nigeriano',
    'Niueño',
    'Noruego',
    'Neocaledonio',
    'Neozelandés',
    'Nigerino',
    'Omanés',
    'Pakistaní',
    'Palauano',
    'Papú',
    'Neerlandés',
    'francopolinesio',
    'Polaco',
    'Portugués',
    'Puertorriqueño',
    'Guineano',
    'Centroafricano',
    'Checo',
    'Ruandés',
    'Rumano',
    'Ruso',
    'Saipanés',
    'Samoano',
    'Samoamericano',
    'Sanmarinense',
    'Santotomense',
    'Senegalés',
    'Serbio',
    'Seychellense',
    'Sierraleonés',
    'Singapurense',
    'Vietnamita',
    'Somalí',
    'Cingalés',
    'St Helena',
    'San Cristóbal y Nieves',
    'Sanpedrin',
    'Sudafricano',
    'Sueco',
    'Suizo',
    'Suazi',
    'Tailandés',
    'Taiwanés',
    'Tanzano',
    'Tayiko',
    'Togolés',
    'Tokelauense',
    'Tongano',
    'Turcocaiqueño',
    'Turcomano',
    'Turco',
    'Tuvaluano',
    'Tunecino',
    'Ucraniano',
    'Ugandés',
    'Uzbeko',
    'Vanuatuense',
    'Vaticano',
    'Yemení',
    'Yugoslavo',
    'Zaire',
    'Zambiano',
    'zanzibarí',
    'Zimbabuense',
  ],
};

export const NATIONALITY_OPTIONS = {
  en_US: nationalitiesEn,
  pt_BR: nationalitiesPt,
  es_ES: nationalitiesEs,
};
