import { FormikHelpers, FormikProps } from 'formik';
import { useIntl } from 'hooks/Intl';
import { useTheme } from 'hooks/theme';
import { FocusEvent, MouseEvent, SyntheticEvent } from 'react';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import { safeGet } from 'utils/objects';
import styles from './index.module.scss';
import './datepicker.scss';
import { Idiom } from 'utils/intl';
import { onChangeRaw } from './utils';
import iconCalendar from '../../assets/images/icon-calendar.png';
import InputLabel from 'components/InputLabel';
import inputStyles from 'styles/input.module.scss';
import InputErrorLabel from 'components/InputErrorLabel';

interface CalendarProps {
  label: string;
  name: string;
  value?: ReactDatePickerProps['selected'];
  minDate?: ReactDatePickerProps['minDate'];
  maxDate?: ReactDatePickerProps['maxDate'];
  touched?: boolean;
  error?: string;
  formikProps?: FormikProps<any>;
  setFieldValue?: FormikHelpers<any>['setFieldValue'];
  onChange?: ReactDatePickerProps['onChange'];
  className?: string;
  includeDates?: ReactDatePickerProps['includeDates'];
  calendarRef?: any;
}

registerLocale(Idiom.es_ES, es);
registerLocale(Idiom.en_US, en);
registerLocale(Idiom.pt_BR, pt);

const Calendar = ({
  label,
  name,
  value,
  minDate,
  maxDate,
  touched,
  error,
  formikProps,
  setFieldValue,
  onChange,
  className,
  includeDates,
  calendarRef,
}: CalendarProps) => {
  const { idiom } = useIntl();

  if (formikProps) {
    value = safeGet<Date | null>(formikProps, 'values.' + name, null);
    touched = safeGet(formikProps, 'touched.' + name, false);
    error = safeGet(formikProps, 'errors.' + name, '');
    setFieldValue = formikProps.setFieldValue;
  }

  const { theme } = useTheme();

  const updateValue = (date: Date | null, event: SyntheticEvent<any>): void => {
    if (setFieldValue) setFieldValue(name, date);
    else if (onChange) onChange(date, event);
  };

  const handleOnChangeRaw = (event: FocusEvent<HTMLInputElement, Element>) =>
    onChangeRaw(event, updateValue, idiom);

  const openCalendar = (event: MouseEvent<HTMLImageElement>) => {
    if (event?.currentTarget?.parentNode) {
      const parentCalendar = event?.currentTarget?.parentNode;
      const inputCalendar = parentCalendar?.querySelector('input');
      if (inputCalendar) {
        inputCalendar?.click();
      }
    }
  };

  const dateFormat = idiom === Idiom.en_US ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
  const showError = !!error && touched;

  let handledClassName = `${inputStyles.input} ${inputStyles[theme]}`;
  if (showError) handledClassName += ` ${inputStyles.error}`;

  const textClassName = `${styles.text} ${styles[theme]}`;

  let containerClassName = inputStyles.container;
  if (className) containerClassName += ` ${className}`;

  return (
    <div className={containerClassName}>
      <InputLabel label={label} showLabel={!!value} />
      <DatePicker
        onChange={updateValue}
        selected={value}
        className={handledClassName}
        dayClassName={() => textClassName}
        weekDayClassName={() => textClassName}
        popperClassName={styles.popper}
        locale={idiom}
        showPopperArrow={false}
        placeholderText={label}
        dateFormat={dateFormat}
        onChangeRaw={handleOnChangeRaw}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        includeDates={includeDates}
        ref={calendarRef}
      />
      <InputErrorLabel error={error} showError={showError} />
      <img
        src={iconCalendar}
        className={`${styles.icon} ${styles[theme]}`}
        onClick={openCalendar}
      />
    </div>
  );
};

export default Calendar;
