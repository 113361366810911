import React, { useEffect } from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { controller } from 'services';
import { clearListCookies } from 'utils/cookies';

const Logout = () => {
  useEffect(() => {
    controller.abort();
    sessionStorage.clear();

    setTimeout(() => {
      window.location.href = '/logout';
    }, 1000);

    return () => {
      clearListCookies();
    };
  }, []);

  return <LoadingSpinner />;
};

export default Logout;
