import { schedulesAllowedForm } from './initialValues';

export const formatScheduleAllowedToAPI = (
  values: schedulesAllowedForm
): { schedule_rules: SchedulesRules } => {
  return {
    schedule_rules: {
      advance_days_cooldown: Number(values.advance_days_cooldown),
      schedule_period_length: Number(values.schedule_period_length),
    } as SchedulesRules,
  };
};
