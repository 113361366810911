import React, { PropsWithChildren } from 'react';
import { useIntl } from 'hooks/Intl';
import styles from '../index.module.scss';
import * as translations from './intl';
import InfoLabelValue from 'components/InfoLabelValue';
import { useModal } from 'hooks/Modal';
import SchedulesForm from './SchedulesForm/SchedulesForm';
import Typography from 'components/Typography';
import EditIcon from 'assets/icons/EditIcon';
import ButtonWithIcon from 'components/ButtonWithIcon/ButtonWithIcon';

interface Props extends PropsWithChildren {
  schedules_allowed: SchedulesRules | undefined;
  nurse_availability: NurseAvailability | undefined;
  reloadPage: VoidFunction;
  partner_id: string;
}

const SchedulesArea = ({
  schedules_allowed,
  reloadPage,
  partner_id,
  nurse_availability,
}: Props) => {
  const { translate } = useIntl();
  const { actions } = useModal();

  const intl = translate(translations);

  const editSchedulesAllowed = async () => {
    actions.showModal(
      intl.EDIT_SCHEDULES_ALLOWED,
      <SchedulesForm
        schedules_allowed={schedules_allowed}
        current_nurse_availability={nurse_availability}
        reloadPage={reloadPage}
        partner_id={partner_id}
      />,
      true
    );
  };

  if (!schedules_allowed) {
    return <></>;
  }

  return (
    <div className={styles.subContainer}>
      <Typography color="primary" type="h3" weight="light">
        {intl.TITLE_SCHEDULES_ALLOWED}
      </Typography>

      <div className="row">
        <div className="row">
          <InfoLabelValue
            label={intl.LABEL_MINIMUN_DAYS_IN_ADVANCE}
            value={String(schedules_allowed?.advance_days_cooldown)}
            customStyle="col-12 col-md-6"
          />
          <InfoLabelValue
            label={intl.LABEL_MAX_DAYS_IN_ADVANCE}
            value={String(schedules_allowed?.schedule_period_length)}
            customStyle="col-12 col-md-6"
          />
        </div>
        <div className="row">
          <ButtonWithIcon
            Icon={EditIcon}
            label={intl.BTN_EDIT}
            onClick={editSchedulesAllowed}
          />
        </div>
      </div>
    </div>
  );
};

export default SchedulesArea;
