import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipIcon from 'assets/icons/TooltipIcon';
import InputErrorLabel from 'components/InputErrorLabel';
import InputLabel from 'components/InputLabel';
import { FormikProps } from 'formik';
import { useTheme } from 'hooks/theme';
import { HTMLInputTypeAttribute } from 'react';
import { IMaskInput } from 'react-imask';
import inputStyles from 'styles/input.module.scss';
import { safeGet } from 'utils/objects';
import styles from './index.module.scss';
import { sanitize } from 'dompurify';

interface InputProps {
  label: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  touched?: boolean;
  error?: string;
  formikProps?: FormikProps<any>;
  onChange?: FormikProps<any>['handleChange'];
  onBlur?: FormikProps<any>['handleBlur'];
  maxLength?: number;
  className?: string;
  mask?: any;
  tooltip?: string;
  rightIcon?: boolean;
  disabled?: boolean;
  innerRef?: any;
}

interface ChangeInputEvent {
  target: HTMLInputElement;
}

const Input = ({
  label,
  name,
  type = 'text',
  value,
  touched,
  error,
  formikProps,
  onChange,
  onBlur,
  maxLength,
  className,
  mask,
  tooltip,
  rightIcon,
  disabled,
  innerRef,
}: InputProps) => {
  const { theme } = useTheme();

  if (formikProps) {
    value = safeGet<string>(formikProps, 'values.' + name, '');
    touched = safeGet(formikProps, 'touched.' + name, false);
    error = safeGet(formikProps, 'errors.' + name, '');
    onChange = onChange || formikProps.handleChange;
  }

  const showError = !!error && touched;

  let logicClassName = '';
  if (showError) logicClassName += ` ${inputStyles.error}`;
  if (disabled) logicClassName += ` ${styles.disabled}`;

  return (
    <div className={`${inputStyles.container} ${className}`}>
      <InputLabel label={label} showLabel={!!value} />
      {mask ? (
        <IMaskInput
          //@ts-ignore
          disabled={disabled}
          type={type}
          value={value}
          className={`${inputStyles.input} ${inputStyles[theme]} ${logicClassName}`}
          placeholder={label}
          name={name}
          onChange={(event: ChangeInputEvent) => {
            const clean = sanitize(event.target.value, {
              ALLOWED_TAGS: ['nal-tag'],
            });
            event.target.value = clean;
            onChange && onChange(event);
          }}
          onBlur={onBlur}
          maxLength={maxLength}
          mask={mask}
          autofix
        />
      ) : (
        <input
          type={type}
          value={value}
          className={`${inputStyles.input} ${inputStyles[theme]} ${logicClassName}`}
          placeholder={label}
          name={name}
          ref={innerRef}
          onChange={(event: ChangeInputEvent) => {
            const clean = sanitize(event.target.value, {
              ALLOWED_TAGS: ['nal-tag'],
            });
            event.target.value = clean;
            onChange && onChange(event);
          }}
          onBlur={onBlur}
          maxLength={maxLength}
          disabled={disabled}
        />
      )}
      {rightIcon && (
        <FontAwesomeIcon
          icon={faSearch}
          className={`${styles.searchIcon} ${styles[theme]}`}
        />
      )}
      {tooltip && (
        <span
          data-tooltip
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          datatitle={tooltip}
          className={`${styles.tooltip} ${styles[theme]}`}
        >
          <TooltipIcon className={`${styles.tooltipIcon} ${styles[theme]}`} />
        </span>
      )}
      <InputErrorLabel error={error} showError={showError} />
    </div>
  );
};

export default Input;
