import React, { SVGProps } from 'react';
import { CONFIG_ICON } from './config-icon';
import { useTheme } from 'hooks/theme';

const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M12.9492 3.05029L8.70658 7.29293"
        stroke={config_icon.primary}
        strokeWidth={2}
      />
      <path
        d="M5.87891 10.1211L3.05048 12.9495"
        stroke={config_icon.secondary}
        strokeWidth={2}
      />
      <path
        d="M3.05078 3.05029L12.9503 12.9498"
        stroke={config_icon.primary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default CloseIcon;
