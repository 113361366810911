import React from 'react';
import styles from './YourAccount.module.scss';
import { useUser } from 'hooks/User';
import ProfileInformation from './ProfileInformation/ProfileInformation';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import LineDivisor from 'components/LineDivisor/LineDivisor';
import ChangePassword from './ChangePassword/ChangePassword';

const YourAccount = () => {
  const { user } = useUser();

  return (
    <>
      <div className={`${styles.Subcontainer}`}>
        <div className="row">
          <PersonalInformation user={user.user} />
        </div>

        {user?.user?.affiliations && user?.user?.affiliations?.length > 0 && (
          <>
            <LineDivisor />
            <div className="row">
              <ProfileInformation user={user.user} />
            </div>
          </>
        )}

        <LineDivisor />
        <div className="row">
          <ChangePassword />
        </div>
      </div>
    </>
  );
};

export default YourAccount;
