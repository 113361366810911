export const TITLE_PAGE_NOT_FOUND = {
  en_US: 'Oops!',
  pt_BR: 'Ops!',
  es_ES: '¡Ups!',
};

export const DESCRIPTION_PAGE_NOT_FOUND = {
  en_US: 'We cannot find the page that you are looking for.',
  pt_BR: 'Não conseguimos encontrar a página que você está procurando.',
  es_ES: 'No podemos encontrar la página que busca.',
};
