import { useRef, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseISO } from 'date-fns';
import styles from './index.module.scss';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import { useTheme } from 'hooks/theme';
import { useModal } from 'hooks/Modal';
import ProfileEnum from 'utils/profile-enum';
import { useUser } from 'hooks/User';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { formatDateByIdiom } from 'utils/date';
import PartnerEnum from 'utils/partner-enum';
import CheckBox from 'components/Checkbox';
import { Formik, FormikProps } from 'formik';
import RadioButton from 'components/RadioButton/RadioButton';
import EditIcon from 'assets/icons/EditIcon';
import ButtonWithIcon from 'components/ButtonWithIcon/ButtonWithIcon';
import InfoLabelValue from 'components/InfoLabelValue';
import Typography from 'components/Typography';
import FooterButtons from 'components/FooterButtons/FooterButtons';

interface IUserItemProps {
  user: User;
  profiles: string[];
  updateProfileToPerson: (user_id: string, data: affiliate[]) => any;
  partners: Partner[];
  reload: VoidFunction;
}

interface IValue {
  affiliations: {
    partner_name: string;
    checked: boolean;
    role: string;
  }[];
}

const UserItem = ({
  user,
  profiles,
  updateProfileToPerson,
  partners,
  reload,
}: IUserItemProps) => {
  const { user: loggedUser } = useUser();

  const { affiliations, person_id } = user;
  const [moreInformation, setMoreInformation] = useState(false);
  const ref = useRef<FormikProps<IValue>>(null);

  const { translate, idiom } = useIntl();
  const { actions } = useModal();
  const { theme } = useTheme();

  const dateObject = parseISO(user?.created_at);
  const dateFormatted = formatDateByIdiom(dateObject, idiom);

  const intl = translate(translations);

  const getTranslation = (name: string) => {
    if (name === ProfileEnum.ADMINISTRATOR) return intl.ADMINISTRATOR_LABEL;
    if (name === ProfileEnum.NURSE) return intl.NURSE_LABEL;
    if (name === PartnerEnum.AMEX) return intl.TEXT_AMEX;
    if (name === PartnerEnum.VISA) return intl.TEXT_VISA;

    return '-';
  };

  const initialValues = useMemo(() => {
    const affiliations = loggedUser.user?.affiliations.map(affilition => {
      const userItemRole = user.affiliations.find(
        affilition => affilition.partner_name === affilition.partner_name
      );

      return {
        partner_name: affilition.partner_name,
        checked: userItemRole ? true : false,
        role: userItemRole?.role || '',
      };
    });

    return {
      affiliations,
    } as IValue;
  }, []);

  const submit = async (value: IValue) => {
    const data = value.affiliations
      .filter(affiliate => affiliate.checked)
      .map(
        affiliate =>
          ({
            partner_name: affiliate.partner_name,
            role: affiliate.role,
          } as affiliate)
      );

    actions.closeModal();
    await updateProfileToPerson(person_id, data);
    reload();
  };

  const showModalToUpdate = async () => {
    actions.showModal(
      affiliations && affiliations.length > 0
        ? intl.BTN_TEXT_EDIT_PROFILE
        : intl.BTN_APPROVE_ACCESS,
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={submit}
        enableReinitialize
      >
        {props => {
          const { values, handleChange, handleSubmit } = props;

          return (
            <form className={styles.formModal} onSubmit={handleSubmit}>
              {values.affiliations.map((affiliation, index) => (
                <div className="row" key={affiliation.partner_name}>
                  <div className={`col-12 col-md-6 ${styles.label}`}>
                    <Typography
                      color="primary"
                      type="label"
                      className={styles.label}
                    >
                      {intl.TEXT_PARTNER}
                    </Typography>

                    <CheckBox
                      name={`affiliations.${index}.checked`}
                      value={affiliation.checked}
                      formikProps={props}
                    >
                      {affiliation.partner_name}
                    </CheckBox>
                  </div>

                  <div className={`col-12 col-md-6 ${styles.label}`}>
                    <Typography
                      color="primary"
                      type="label"
                      className={styles.label}
                    >
                      {intl.TEXT_PROFILE}
                    </Typography>

                    {profiles.map(profile => {
                      return (
                        <>
                          <RadioButton
                            key={profile}
                            label={getTranslation(profile)}
                            name={`affiliations.${index}.role`}
                            value={profile}
                            onChange={handleChange}
                            defaultChecked={affiliation.role === profile}
                            disabled={!affiliation.checked}
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
              ))}

              <div className="row">
                <FooterButtons
                  onClickButton={handleSubmit}
                  textButton={intl.TEXT_YES_I_DO}
                  textDenyButton={intl.TEXT_NO}
                />
              </div>
            </form>
          );
        }}
      </Formik>,
      true
    );
  };

  const _onClick = () => {
    if (moreInformation) {
      setMoreInformation(false);
    } else {
      setMoreInformation(true);
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.accordeonHeader} ${styles[theme]}`}>
        <div className={styles.boxNames}>
          <span
            className={`${styles.text} ${styles[theme]} ${styles.boldText}`}
          >
            {user.first_name} {user.last_name}
          </span>
          <span className={`${styles[theme]} ${styles.text}`}>
            {user.email}
          </span>
        </div>

        <div className={`${styles.boxNames2} ${styles[theme]}`}>
          <span className={`${styles[theme]} ${styles.text}`}>
            {intl.TEXT_DATE_CREATION}: {dateFormatted}
          </span>
        </div>

        <div
          className={`${styles[theme]} ${styles.moreInformation}`}
          onClick={_onClick}
          data-testid="toggle-more-info"
        >
          {moreInformation ? (
            <FontAwesomeIcon
              icon={faChevronUp}
              className={`${styles.icon} ${styles[theme]}`}
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${styles.icon} ${styles[theme]}`}
            />
          )}
        </div>
      </div>

      {moreInformation && (
        <>
          <div className={`${styles.contentMore}`}>
            {affiliations && affiliations.length > 0 && (
              <div className="row">
                {affiliations.map(affiliation => (
                  <div key={affiliation.partner_id}>
                    <div className="col-12 col-sm-6 col-md-3 col-xl-3">
                      <InfoLabelValue
                        value={getTranslation(affiliation.role)}
                        label={intl.TEXT_PROFILE}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-3 col-xl-3">
                      <InfoLabelValue
                        value={getTranslation(affiliation.partner_name)}
                        label={intl.TEXT_PARTNER}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className={`${styles[theme]} ${styles.footer}`}>
              <ButtonWithIcon
                Icon={EditIcon}
                label={
                  affiliations && affiliations.length > 0
                    ? intl.BTN_TEXT_EDIT
                    : intl.BTN_APPROVE_ACCESS
                }
                onClick={showModalToUpdate}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserItem;
