export const ADMINISTRATOR_LABEL = {
  en_US: 'Administrator',
  pt_BR: 'Administrador',
  es_ES: 'Administrador',
};

export const NURSE_LABEL = {
  en_US: 'Nurse',
  pt_BR: 'Enfermeira',
  es_ES: 'Enfermera',
};

export const MODAL_TITLE_CONFIRMATION_UPDATE_PROFILE = {
  en_US: 'Associate  Profile',
  pt_BR: 'Associar perfil',
  es_ES: 'Asociar perfil',
};

export const MODAL_TEXT_CONFIRMATION_UPDATE_PROFILE = {
  en_US: 'Do you want to update the selected profile of the User?',
  pt_BR: 'Você quer atualizar o perfil selecionado do Usuario?',
  es_ES: '¿Quieres actualizar el perfil seleccionado del Usuario?',
};

export const MODAL_TITLE_CONFIRMATION_ADD_PROFILE = {
  en_US: 'Add Profile',
  pt_BR: 'Adicionar Perfil',
  es_ES: 'Añadir Perfil',
};

export const MODAL_TEXT_CONFIRMATION_ADD_PROFILE = {
  en_US: 'Do you want to add the selected profile to the user?',
  pt_BR: 'Você quer adiconar o perfil selecionado ao usuario?',
  es_ES: '¿Quieres añadir el perfil seleccionado al usuario?',
};

export const TEXT_YES_I_DO = {
  en_US: 'Save',
  pt_BR: 'Salvar',
  es_ES: 'Salvar',
};

export const TEXT_NO = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const TEXT_EMAIL = {
  en_US: 'Email',
  pt_BR: 'Email',
  es_ES: 'Email',
};

export const TEXT_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const TEXT_DATE_CREATION = {
  en_US: 'Creation date',
  pt_BR: 'Data de criação',
  es_ES: 'Fecha de creacíon',
};

export const TEXT_PROFILE = {
  en_US: 'Profile',
  pt_BR: 'Perfil',
  es_ES: 'Perfil',
};

export const TEXT_PARTNER = {
  en_US: 'Partner',
  pt_BR: 'Parceiro',
  es_ES: 'Socio',
};

export const TEXT_VISA = {
  en_US: 'Visa',
  pt_BR: 'Visa',
  es_ES: 'Visa',
};

export const TEXT_AMEX = {
  en_US: 'Amex',
  pt_BR: 'Amex',
  es_ES: 'Amex',
};

export const BTN_TEXT_EDIT = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};

export const BTN_TEXT_EDIT_PROFILE = {
  en_US: 'Edit profile',
  pt_BR: 'Editar perfil',
  es_ES: 'Editar perfil',
};

export const BTN_APPROVE_ACCESS = {
  en_US: 'Access approval',
  pt_BR: 'Aprovar acesso',
  es_ES: 'Aprobar el acceso',
};

export const TEXT_PARTNER_PROFILE = {
  en_US: 'Associated Partners',
  pt_BR: 'Perfis Associados',
  es_ES: 'Perfiles Asociados',
};
