import Typography from 'components/Typography';

interface InputLabelProps {
  error?: string;
  showError?: boolean;
}

const InputErrorLabel = ({ error, showError = false }: InputLabelProps) => {
  return showError ? (
    <Typography type="small" color="alert" weight="light">
      {error}
    </Typography>
  ) : null;
};

export default InputErrorLabel;
