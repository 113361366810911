import { Translations } from 'hooks/Intl';
import * as Yup from 'yup';

export default (intl: Translations<string>) => {
  return Yup.object().shape({
    weekends_and_nights: Yup.string().required(intl.FIELD_REQUIRED),
    weekdays_7_00: Yup.string().required(intl.FIELD_REQUIRED),
    weekdays_7_15: Yup.string().required(intl.FIELD_REQUIRED),
  });
};
