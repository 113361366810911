import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './StyledModal.module.scss';

import IconSuccess from '../../../assets/icons/modal/SuccessIcon';
import IconQuestion from '../../../assets/icons/modal/QuestionIcon';
import IconErrorAttention from 'assets/icons/modal/ErrorAttentionIcon';
import * as translations from './intl';
import { useModal } from '../../../hooks/Modal/index';
import Button, { ButtonTypes } from 'components/Button';
import Typography from 'components/Typography';
import { useIntl } from 'hooks/Intl';

export enum ModalType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  CONFIRMATION = 'CONFIRMATION',
}

interface Props extends PropsWithChildren {
  title: string;
  subtitle?: string;
  subtitleTwo?: string;
  type: ModalType;
  textConfirm?: string;
  onClickConfirm?: () => void;
  textDeny?: string;
  onClickDeny?: () => void;
  children?: ReactNode;
  hasFooter?: boolean;
  buttonConfirmType?: ButtonTypes;
}

const StyledModal = ({
  title,
  type,
  subtitle,
  textConfirm,
  onClickConfirm,
  textDeny,
  onClickDeny,
  children,
  subtitleTwo,
  hasFooter = true,
  buttonConfirmType,
}: Props) => {
  const { actions } = useModal();
  const { translate } = useIntl();

  const intl = translate(translations);
  const ICON_IMAGE = {
    [ModalType.SUCCESS]: <IconSuccess modal_success />,
    [ModalType.ERROR]: <IconErrorAttention modal_error />,
    [ModalType.CONFIRMATION]: <IconQuestion />,
  };
  const Icon = () => ICON_IMAGE[type];

  return (
    <div className={styles.container}>
      <div className={`${styles.header}`}>
        <div className={styles.icon}>
          <Icon />
        </div>
      </div>
      <div className={styles.leftBox}>
        <div className={styles.textContent}>
          <Typography type="h1" color="primary" weight="light">
            {title}
          </Typography>
          <Typography
            type="label"
            color="primary"
            weight="light"
            className={styles.topPadding}
          >
            {subtitle}
          </Typography>
          {subtitleTwo && (
            <Typography type="p" color="primary" className={styles.topPadding}>
              {subtitleTwo}
            </Typography>
          )}
        </div>

        {children !== undefined && children}

        {hasFooter && (
          <div className={styles.buttonsContainer}>
            {(onClickDeny || textDeny) && (
              <Button
                type="PrimaryWithoutBorder"
                className={styles.button}
                onClick={onClickDeny || actions.closeModal}
              >
                {textDeny || intl.BTN_CANCEL}
              </Button>
            )}
            <Button
              type={buttonConfirmType || 'Secondary'}
              className={styles.button}
              onClick={onClickConfirm || actions.closeModal}
            >
              {textConfirm || intl.TEXT_OK}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default StyledModal;
