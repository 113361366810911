export interface schedulesAllowedForm {
  advance_days_cooldown: number | undefined;
  schedule_period_length: number | undefined;
}

export default (currentValues?: SchedulesRules): schedulesAllowedForm => {
  const initialValues: schedulesAllowedForm = {
    advance_days_cooldown: undefined,
    schedule_period_length: undefined,
  };

  if (currentValues) {
    const { advance_days_cooldown, schedule_period_length } = currentValues;
    initialValues.advance_days_cooldown = advance_days_cooldown;
    initialValues.schedule_period_length = schedule_period_length;
  }

  return initialValues;
};
