import React, { Dispatch, SetStateAction } from 'react';
import HamburguerMenu from '../../assets/images/hamburguer_menu.svg';
import logoNAL from '../../assets/images/logoNAL-1.svg';
import styles from './MenuHamburguer.module.scss';
import { useTheme } from 'hooks/theme';
import { Link, NavLink } from 'react-router-dom';
import CloseIcon from 'assets/icons/CloseIcon';
import ChevronGeneric from 'assets/icons/ChevronGeneric';
import * as translations from './intl';
import { useIntl } from 'hooks/Intl';

interface MenuHamburguerProps {
  showMenuMobile: boolean;
  setShowMenuMobile: Dispatch<SetStateAction<boolean>>;
}

const MenuHamburguer = ({
  showMenuMobile,
  setShowMenuMobile,
}: MenuHamburguerProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);
  const { theme } = useTheme();
  return (
    <>
      {!showMenuMobile ? (
        <div
          onClick={() => setShowMenuMobile(true)}
          className={`${styles.menuHamburguer} ${styles.showInMobile}`}
        >
          <img
            src={HamburguerMenu}
            className={`${styles[theme]} ${styles.iconMenu}`}
            alt="open menu mobile"
            aria-label="open menu mobile"
          />
        </div>
      ) : (
        <div className={`${styles[theme]} ${styles.menuMobileList}`}>
          <div className={`${styles[theme]} ${styles.headerMenuMobile}`}>
            <Link
              to="/"
              onClick={() => setShowMenuMobile(false)}
              className={styles.linkLogo}
            >
              <img className={styles.logoNAL} src={logoNAL} />
            </Link>
            <div>
              <span className={styles.iconMenu}>
                <CloseIcon
                  width={30}
                  height={30}
                  aria-label="close menu mobile"
                  onClick={() => setShowMenuMobile(false)}
                />
              </span>
            </div>
          </div>
          <ul className={`${styles[theme]} ${styles.listOptions}`}>
            <li className={`${styles[theme]}`}>
              <NavLink
                to="/consult-users"
                onClick={() => setShowMenuMobile(false)}
                className={`${styles[theme]}`}
              >
                {intl.USER_ACCOUNT}
              </NavLink>

              <span className={styles.iconChevron}>
                <ChevronGeneric rotate={270} width={20} height={20} />
              </span>
            </li>
            <li className={`${styles[theme]}`}>
              <NavLink
                to="/consult-appointments"
                onClick={() => setShowMenuMobile(false)}
                className={`${styles[theme]}`}
              >
                {intl.APPOINTMENTS}
              </NavLink>

              <span className={styles.iconChevron}>
                <ChevronGeneric rotate={270} width={20} height={20} />
              </span>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
export default MenuHamburguer;
