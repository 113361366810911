import { NATIONALITY_OPTIONS } from 'intls/nationalities';
import { Idiom } from 'utils/intl';

export const getNationalityIntl = (
  nationality: string,
  language: Idiom
): string => {
  const intls = NATIONALITY_OPTIONS[language];

  const valueIndex = intls.values.findIndex(item => item === nationality);
  if (valueIndex === -1) return '';

  return intls.labels[valueIndex];
};
