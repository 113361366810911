import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import getInitivalValues, { ValuesExceptionForm } from './initialValues';
import validation from './validationSchema';
import { useIntl } from 'hooks/Intl';
import { formsTranslations } from 'intls';
import Input from 'components/Input';
import Calendar from 'components/Calendar';
import { settingsService } from 'services';
import { useModal } from 'hooks/Modal';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import { formatExceptionToAPI } from './formatExceptionAPI';
import * as translations from '../ExceptionItem/intl';
import FooterButtons from 'components/FooterButtons/FooterButtons';

export enum TypeForm {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

interface Props {
  type: TypeForm;
  reloadPage: VoidFunction;
  exception?: ExceptionsConfig;
  partner_id: string;
}

const ExceptionForm = ({ type, reloadPage, exception, partner_id }: Props) => {
  const { translate, idiom } = useIntl();
  const { actions } = useModal();

  const intlForm = translate(formsTranslations);
  const intl = translate(translations);

  const initialValues = useMemo(() => getInitivalValues(exception, idiom), []);
  const validationSchema = useMemo(() => validation(intlForm), []);

  const onSubmit = async (values: ValuesExceptionForm) => {
    try {
      actions.showLoading();
      const formatedValues = formatExceptionToAPI(values);

      if (type === TypeForm.ADD) {
        await settingsService.createException(partner_id, formatedValues);
        actions.hideLoading();
        actions.showModal(
          '',
          <StyledModal type={ModalType.SUCCESS} title={intl.CREATED_SUCCESS} />,
          false
        );
      } else if (type === TypeForm.UPDATE && exception) {
        await settingsService.updateException(
          partner_id,
          exception?.exception_id,
          formatedValues
        );
        actions.hideLoading();
        actions.showModal(
          '',
          <StyledModal type={ModalType.SUCCESS} title={intl.UPDATE_SUCCESS} />,
          false
        );
      }

      reloadPage();
    } catch (error) {
      actions.hideLoading();
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="row">
      <Calendar
        label={intl.LABEL_DATE}
        name="affected_date"
        formikProps={formik}
        className="col-12 col-md-6"
      />
      <Input
        label={intl.LABEL_NORMAL_PERIOD}
        formikProps={formik}
        name="nurses_available.weekdays_7_00"
        maxLength={2}
        className="col-12 col-md-6"
      />
      <Input
        label={intl.LABEL_NURSE_AVAILABILYT_MON_FRI_07_15}
        formikProps={formik}
        name="nurses_available.weekdays_7_15"
        maxLength={2}
        className="col-12 col-md-6"
      />
      <Input
        label={intl.LABEL_AFTER_PERIOD}
        formikProps={formik}
        name="nurses_available.weekends_and_nights"
        maxLength={2}
        className="col-12 col-md-6"
      />
      <div className="col-12">
        <FooterButtons onClickButton={formik.handleSubmit} />
      </div>
    </div>
  );
};

export default ExceptionForm;
