import { AxiosInstance } from 'axios';
import { errorHandler } from './errorHandler/errorHandler';

// ! All endpoint needs to be precedded by /api/ when Auth0 is configured

export interface PaginatorUsers {
  records: User[];
  paginator: {
    current_page: number;
    amount_items: number;
    amount_pages: number;
    hasPrev: boolean;
    hasNext: boolean;
  };
}

interface queryAdminGetUsers {
  status?: UserStatus;
  role?: UserRole;
  created_at?: string;
  page_index?: number;
  amount_items?: number;
  email?: string;
}

export default (axios: AxiosInstance) => ({
  get: async () => {
    const url = '/api/v1/user';
    return errorHandler<User>(axios.get<User>(url), 'get - user', {
      url,
    });
  },
  create: async (user: UserCreateBody) => {
    const url = '/api/v1/user';
    return errorHandler<User>(axios.post(url, user), 'create - user', {
      user,
      url,
    });
  },
  update: async (userId: string, user: UserUpdateBody) => {
    const url = `/api/v1/user/${userId}`;
    return errorHandler(axios.patch(url, user), 'update - user', {
      user,
      url,
    });
  },
  getBy: async (partner_id: string, query?: queryAdminGetUsers) => {
    const url = `/api/v1/admin/${partner_id}/users`;
    let queryString = '';

    if (query) {
      const emptyKeys = Object.keys(query).filter(
        key =>
          // @ts-ignore
          query[key] === undefined || query[key] === null || query[key] === ''
      );
      if (emptyKeys) {
        // @ts-ignore
        emptyKeys.forEach(emptyKey => delete query[emptyKey]);
      }
      // @ts-ignore
      const queryArray = Object.keys(query).map(key => `${key}=${query[key]}`);
      queryString = `?${queryArray.join('&')}`;
    }

    return errorHandler<PaginatorUsers>(
      axios.get(`${url}${queryString}`),
      'getBy - user',
      {
        url,
      }
    );
  },
});
