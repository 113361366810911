import React from 'react';
import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from './config-icon';

interface CheckboxIconProps {
  isChecked: boolean;
  className?: string;
}

const CheckboxIcon = ({
  isChecked = false,
  className = '',
}: CheckboxIconProps) => {
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];

  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="0.5"
        y="0.5"
        width="14"
        height="14"
        fill={isChecked ? config_icon.secondary : config_icon.background}
        stroke={isChecked ? config_icon.secondary : config_icon.text}
      />
    </svg>
  );
};

export default CheckboxIcon;
