import { SVGProps } from 'react';

const DotIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={props.className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 9"
      {...props}
    >
      <path d="M4.5 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z" />
    </svg>
  );
};

export default DotIcon;
