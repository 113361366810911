import { NurseAvailabilityForm } from './initialValues';

export const formatNurseAvailabilityToAPI = (
  values: NurseAvailabilityForm
): { nurses_available: NurseAvailability } => {
  return {
    nurses_available: {
      weekends_and_nights: Number(values.weekends_and_nights),
      weekdays_7_00: Number(values.weekdays_7_00),
      weekdays_7_15: Number(values.weekdays_7_15),
    },
  };
};
