import { stringToDateWithoutTimezone } from 'utils/date';
import { Idiom } from 'utils/intl';

export interface ValuesExceptionForm {
  affected_date: Date | undefined;
  nurses_available: {
    weekdays_7_00: number | undefined;
    weekdays_7_15: number | undefined;
    weekends_and_nights: number | undefined;
  };
}

export default (
  exception?: ExceptionsConfig,
  idiom?: Idiom
): ValuesExceptionForm => {
  return {
    affected_date:
      exception && idiom
        ? stringToDateWithoutTimezone(exception?.affected_date)
        : new Date(),
    nurses_available: {
      weekdays_7_00: exception?.nurses_available?.weekdays_7_00,
      weekdays_7_15: exception?.nurses_available?.weekdays_7_15,
      weekends_and_nights: exception?.nurses_available?.weekends_and_nights,
    },
  };
};
