/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ErrorInfo, ReactNode } from 'react';
import { trackEventUserAction } from 'AppInsightsTracks';
import LayoutHalfBlue from 'layouts/HalfBlue/HalfBlue';
import ErrorPage from './ErrorPage';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Registra o erro em um serviço de relatórios de erro
    trackEventUserAction('#### ERROR BOUNDARY ####', {
      error,
      message: error.message,
      stack: JSON.stringify(error.stack),
      errorInfo,
    });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <LayoutHalfBlue>
          <ErrorPage />
        </LayoutHalfBlue>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
