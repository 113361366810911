import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { useTheme } from 'hooks/theme';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { useAuth } from 'hooks/Auth';
import {
  getHeaderConfigByTheme,
  HeaderTypes,
  HeaderTypeStyle,
} from './headerConfig';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import SelectIdiom from 'components/SelectIdiom/SelectIdiom';
import { useUser } from 'hooks/User';
import ProfileEnum from 'utils/profile-enum';
import ButtonProfile from 'components/ButtonProfile/ButtonProfile';
import logoNAL from '../../assets/images/logoNAL-1.svg';
import MenuHamburguer from 'components/MenuHamburguer';

interface HeaderProps {
  showNavigation?: boolean;
  logoutOnly?: boolean;
  headerType: HeaderTypeStyle;
  empty?: boolean;
}

const Header = ({
  showNavigation = true,
  headerType,
  logoutOnly = false,
  empty = false,
}: HeaderProps) => {
  const [scroll, setScroll] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [styleHeader, setStyleHeader] = useState(headerType);
  const navigate = useNavigate();
  const { translate } = useIntl();
  const { userAuth } = useAuth();
  const { theme } = useTheme();
  const { user } = useUser();

  const intl = translate(translations);

  const listenerScroll = () => {
    setScroll(window.pageYOffset > 40);
  };

  useEffect(() => {
    setStyleHeader(headerType);
    window.addEventListener('scroll', listenerScroll);

    return () => {
      window.removeEventListener('scroll', listenerScroll);
    };
  }, []);

  useEffect(() => {
    setStyleHeader(headerType);
  }, [headerType]);

  useEffect(() => {
    if (scroll) {
      const config = getHeaderConfigByTheme(theme);
      setStyleHeader(config[HeaderTypes.HEADER_PRIVATE]);
    } else {
      setStyleHeader(headerType);
    }
  }, [scroll, styleHeader]);

  const profiles = user?.user?.affiliations?.map(
    affiliation => affiliation.role
  );

  return (
    <header
      className={`${styles.mainHeader} ${styles[theme]} ${
        styleHeader?.backgroundColor ? styles.backgroundColor : ''
      }`}
    >
      <Link
        to={userAuth.logged ? '/dashboard' : '/'}
        className={styles.linkLogo}
      >
        <img className={styles.logoNAL} src={logoNAL} />
      </Link>

      {!empty && (
        <div className={styles.linkContent}>
          <div className={styles.inlineDiv}>
            {showNavigation && userAuth.logged && (
              <>
                {(profiles?.includes(ProfileEnum.ADMINISTRATOR) ||
                  profiles?.includes(ProfileEnum.NURSE)) && (
                  <NavLink
                    style={{ color: styleHeader?.colorText }}
                    to="/dashboard"
                    className={({ isActive }) =>
                      `${isActive ? styles.activeNavLink : styles.navLink} ${
                        styles[theme]
                      } ${styles.hideInMobile}`
                    }
                  >
                    {intl.HOME}
                  </NavLink>
                )}
                {profiles?.includes(ProfileEnum.ADMINISTRATOR) && (
                  <NavLink
                    style={{ color: styleHeader?.colorText }}
                    to="/consult-users"
                    className={({ isActive }) =>
                      `${isActive ? styles.activeNavLink : styles.navLink} ${
                        styles[theme]
                      } ${styles.hideInMobile}`
                    }
                  >
                    {intl.USER_ACCOUNT}
                  </NavLink>
                )}
                {(profiles?.includes(ProfileEnum.ADMINISTRATOR) ||
                  profiles?.includes(ProfileEnum.NURSE)) && (
                  <NavLink
                    style={{ color: styleHeader?.colorText }}
                    to="/consult-appointments"
                    className={({ isActive }) =>
                      `${isActive ? styles.activeNavLink : styles.navLink} ${
                        styles[theme]
                      } ${styles.hideInMobile}`
                    }
                  >
                    {intl.APPOINTMENTS}
                  </NavLink>
                )}

                {!logoutOnly && <SelectIdiom color={styleHeader?.colorText} />}
                <MenuHamburguer
                  showMenuMobile={showMenuMobile}
                  setShowMenuMobile={setShowMenuMobile}
                />

                {userAuth.logged && !logoutOnly && !showMenuMobile && (
                  <div className={styles.profileIconContainer}>
                    <ButtonProfile />
                  </div>
                )}
              </>
            )}
            {logoutOnly && (
              <div className={styles.profileIconContainer}>
                <SelectIdiom color={styleHeader?.colorText} />
                <ButtonProfile />
              </div>
            )}

            {showNavigation && !userAuth.logged && (
              <>
                <SelectIdiom color={styleHeader?.colorText} />
                <div className={styles.wrapperButton}>
                  <Button
                    type="BorderSecondary"
                    width="120px"
                    height="40px"
                    onClick={() => navigate('/login')}
                  >
                    {intl.LOG_IN}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
