import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import Form from './Form';
import FormStepper from 'components/FormStepper';
import { useState } from 'react';
import TermsAndConditions from './TermsAndConditions';
import { useFormik } from 'formik';
import { getInitialValues } from './initialValues';
import { getValidationSchema } from './validationSchema';
import { format } from './submit';
import { useModal } from 'hooks/Modal';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import { userService } from 'services';
import { useUser } from 'hooks/User';
import { formsTranslations, modalsTranslations } from 'intls';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/Auth';
import styles from './index.module.scss';
import { useTheme } from 'hooks/theme';

const CompleteRegistration = () => {
  const { actions } = useModal();
  const { loadUser } = useUser();
  const { userAuth } = useAuth();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const { translate, idiom } = useIntl();
  const intl = translate(translations);
  const formsIntl = translate(formsTranslations);
  const modalsIntl = translate(modalsTranslations);

  const [step, setStep] = useState(0);

  const formik = useFormik({
    initialValues: getInitialValues(idiom, userAuth),
    validationSchema: getValidationSchema(formsIntl),
    onSubmit: async values => {
      const body = format(values, idiom);

      try {
        actions.showLoading(intl.SAVING_DATA);
        await userService.create(body);
        await loadUser();
        navigate('/dashboard');
        actions.showModal(
          '',
          <StyledModal
            title={intl.CONGRATULATIONS}
            subtitle={intl.REGISTRATION_COMPLETE}
            type={ModalType.SUCCESS}
            onClickConfirm={() => {
              actions.closeModal();
            }}
          />,
          true
        );
      } catch (error) {
        actions.showModal(
          '',
          <StyledModal
            title={modalsIntl.UNEXPECTED_ERROR}
            type={ModalType.ERROR}
          />,
          false
        );
      }
    },
  });

  return (
    <div className={`${styles.Container} ${styles[theme]}`}>
      <FormStepper steps={2} currentStep={step} />
      <div className={`${styles[theme]}`}>
        {step === 0 && (
          <TermsAndConditions
            stepState={[step, setStep]}
            formikProps={formik}
          />
        )}
        {step === 1 && (
          <Form stepState={[step, setStep]} formikProps={formik} />
        )}
      </div>
    </div>
  );
};

export default CompleteRegistration;
