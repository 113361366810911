import FormStepWrapper from 'components/FormStepWrapper';
import { FormikProps } from 'formik';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import PhoneArea from './PhoneArea';
import PersonalArea from './PersonalArea';
import { formsTranslations } from 'intls';
import { RegistrationFormValues } from '../initialValues';

interface FormProps {
  stepState: State<number>;
  formikProps: FormikProps<RegistrationFormValues>;
}

const Form = ({ stepState, formikProps }: FormProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);
  const formsIntl = translate(formsTranslations);

  const [step, setStep] = stepState;

  return (
    <FormStepWrapper
      title={intl.TITLE_YOUR_INFORMATION}
      onNext={formikProps.submitForm}
      nextButtonLabel={formsIntl.CONCLUDE}
      onPrevious={() => setStep(step - 1)}
    >
      <PersonalArea formikProps={formikProps} />
      <PhoneArea formikProps={formikProps} />
    </FormStepWrapper>
  );
};

export default Form;
