import { useEffect } from 'react';
import styles from './Modal.module.scss';
import { useModal } from '../../hooks/Modal/index';
import ModalLoading from './ModalLoading';
import Typography from 'components/Typography';
import * as translations from '../../intls/forms';
import { useIntl } from 'hooks/Intl';
import CloseIcon from 'assets/icons/CloseIcon';

const Modal = () => {
  const { state, actions } = useModal();
  const { translate } = useIntl();
  const { show, loading, duo, innerComponent, title, close, isForm } = state;
  const modalDuo = duo ? styles.modalDuo : '';
  const intl = translate(translations);

  useEffect(() => {
    if (show) {
      document.body.classList.add(styles.modalOpen);
    } else {
      document.body.classList.remove(styles.modalOpen);
    }
  }, [show]);

  if (!show) {
    return <></>;
  }

  return (
    <div
      data-testid="modal"
      role="dialog"
      className={`modal-backdrop ${styles.modal}`}
    >
      <div className={`modal-content ${modalDuo} ${styles.modalContent}`}>
        {loading && <ModalLoading />}
        {innerComponent && (
          <>
            {(title || close) && (
              <nav className={styles.modalHeader}>
                <>
                  <Typography color="primary" weight="light" type="h2">
                    {title || ''}
                  </Typography>

                  {close && (
                    <div className={styles.close}>
                      <CloseIcon
                        width={25}
                        height={25}
                        onClick={actions.closeModal}
                      />
                    </div>
                  )}
                </>
              </nav>
            )}
            {isForm && (
              <Typography weight="light" type="small" color="subtitle">
                {intl.MANDATORY_FIELDS}
              </Typography>
            )}
            <div className={styles.boxContent}>{innerComponent}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
