export const TITLE = {
  en_US: 'Nurse Advice Line',
  pt_BR: 'Nurse Advice Line',
  es_ES: 'Nurse Advice Line',
};

export const DESCRIPTION = {
  en_US:
    'If you have questions regarding symptoms or want general information, we are here to assist you.',
  pt_BR:
    'Se você tiver dúvidas sobre sintomas ou deseja informações gerais, estamos aqui para ajudá-lo.',
  es_ES:
    'Si tienes preguntas sobre síntomas o deseas información general, estamos aquí para ayudarte.',
};

export const LOG_IN = {
  en_US: 'Log in',
  es_ES: 'Iniciar sesión',
  pt_BR: 'Entrar',
};
