import React, { useState } from 'react';
import { useIntl } from 'hooks/Intl';
import { Idiom } from 'utils/intl';
import styles from './SelectIdiom.module.scss';
import * as translations from './intl';
import ChevronGeneric from 'assets/icons/ChevronGeneric';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SelectIdiom = ({ color }: Props) => {
  const { idiom, setIdiom, translate } = useIntl();
  const intl = translate(translations);
  const [isOpen, setIsOpen] = useState(false);

  const translateIntlCode = (code: string) => {
    switch (code) {
      case Idiom.pt_BR:
        return intl.OPTION_PT;
      case Idiom.en_US:
        return intl.OPTION_EN;
      case Idiom.es_ES:
        return intl.OPTION_ES;
      default:
        return null;
    }
  };

  const selectIdiom = (idiom: Idiom) => {
    setIsOpen(false);
    setIdiom(idiom);
  };

  return (
    <div className={styles.dropdown} onMouseLeave={() => setIsOpen(false)}>
      <button onClick={() => setIsOpen(!isOpen)}>
        {translateIntlCode(idiom)}
        <span className={styles.icon}>
          <ChevronGeneric rotate={isOpen ? 180 : 0} />
        </span>
      </button>
      <div className={`${styles.dropdownOptions} ${isOpen && styles.isOpen}`}>
        <span onClick={() => selectIdiom(Idiom.en_US)}>{intl.OPTION_EN}</span>
        <span onClick={() => selectIdiom(Idiom.pt_BR)}>{intl.OPTION_PT}</span>
        <span onClick={() => selectIdiom(Idiom.es_ES)}>{intl.OPTION_ES}</span>
      </div>
    </div>
  );
};

export default SelectIdiom;
