// Based on: https://gist.github.com/svdamani/e01269239232f89b5d3c5237584e5475
const safeGetByArray = <T>(
  obj: Record<string, any>,
  propsArray: string[],
  defaultValue: T
): T | object => {
  if (obj === undefined || obj === null) return defaultValue;
  if (propsArray.length === 0) return obj;
  return safeGetByArray(
    obj[propsArray[0]] as Record<string, unknown>,
    propsArray.slice(1),
    defaultValue
  );
};

// Based on: https://gist.github.com/svdamani/e01269239232f89b5d3c5237584e5475
export const safeGet = <T>(
  obj: Record<string, any>,
  props: string | string[],
  defaultValue: T
): T => {
  if (typeof props === 'string') props = props.split('.');
  return safeGetByArray(obj, props, defaultValue) as T;
};
