import React from 'react';
import styles from './ChangePassword.module.scss';
import * as translations from './intl';
import { useIntl } from 'hooks/Intl';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { useModal } from 'hooks/Modal';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import { userInfoService } from 'services';

const ChangePassword = () => {
  const { actions } = useModal();
  const { translate, idiomForApi } = useIntl();

  const intl = translate(translations);

  const openModalChangePassword = () => {
    actions.showModal(
      '',
      <StyledModal
        title={intl.TITLE_TEXT}
        type={ModalType.CONFIRMATION}
        textConfirm={intl.CONFIRM_TEXT}
        textDeny={intl.DENY_TEXT}
        onClickConfirm={handleChangePassword}
        onClickDeny={actions.closeModal}
      />,
      true
    );
  };

  const handleChangePassword = async () => {
    actions.showLoading();
    try {
      await userInfoService.changePassword(idiomForApi());
      actions.showModal(
        '',
        <StyledModal
          title={intl.SUCCESS_CHANGE_PASSWORD_TEXT}
          type={ModalType.SUCCESS}
        />,
        true
      );
    } catch (error) {
      actions.showModal(
        '',
        <StyledModal
          title={intl.FAILURE_CHANGE_PASSWORD_TEXT}
          type={ModalType.ERROR}
        />,
        true
      );
    }
  };

  return (
    <>
      <Typography color="primary" type="h3" weight="light">
        {intl.CHANGE_PASSWORD}
      </Typography>

      <Typography color="subtitle" type="p" weight="light">
        {intl.BODY_TEXT}
      </Typography>

      <div className={styles.wrappButton}>
        <Button type="BorderPrimary" onClick={openModalChangePassword}>
          {intl.CHANGE_PASSWORD}
        </Button>
      </div>
    </>
  );
};

export default ChangePassword;
