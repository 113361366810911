import { differenceInDays, format } from 'date-fns';
import { enUS, ptBR, es } from 'date-fns/locale';
import { Idiom } from './intl';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const EN_FORMAT = 'MM/dd/yyyy';
export const PT_FORMAT = 'dd/MM/yyyy';
export const ES_FORMAT = 'dd/MM/yyyy';

export const datefromFormattedDate = (date: string, idiom: Idiom) => {
  const newDate =
    idiom === Idiom.en_US
      ? date.replace(/\\/g, '-')
      : date.split('/').reverse().join('-');
  return new Date(newDate);
};

export const stringToDateWithoutTimezone = (date: string) => {
  const year = Number(date.substring(0, 4));
  const month = Number(date.substring(5, 7)) - 1;
  const day = Number(date.substring(8, 10));

  const newDate = new Date();
  newDate.setFullYear(year, month, day);
  return newDate;
};

export const isSameOrAfterDay = (date1: Date, date2: Date) =>
  differenceInDays(date1, date2) >= 0;

export const isBeforeDay = (date1: Date, date2: Date) =>
  differenceInDays(date1, date2) < 0;

export const isSameOrBeforeDay = (date1: Date, date2: Date) =>
  differenceInDays(date1, date2) <= 0;

export const getLocale = (idiom: Idiom) => {
  if (idiom === Idiom.pt_BR) return ptBR;
  if (idiom === Idiom.es_ES) return es;
  return enUS;
};

export const formatDateByIdiom = (date: Date, idiom: Idiom): string => {
  let formatForm = EN_FORMAT;
  if (idiom === Idiom.pt_BR) {
    formatForm = PT_FORMAT;
  } else if (idiom === Idiom.es_ES) {
    formatForm = ES_FORMAT;
  }

  return format(date, formatForm);
};
