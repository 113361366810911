export interface NurseAvailabilityForm {
  weekends_and_nights: number | undefined;
  weekdays_7_00: number | undefined;
  weekdays_7_15: number | undefined;
}

export default (currentValues?: NurseAvailability): NurseAvailabilityForm => {
  const initialValues: NurseAvailabilityForm = {
    weekends_and_nights: undefined,
    weekdays_7_00: undefined,
    weekdays_7_15: undefined,
  };

  if (currentValues) {
    const { weekdays_7_00, weekdays_7_15, weekends_and_nights } = currentValues;

    initialValues.weekends_and_nights = weekends_and_nights;
    initialValues.weekdays_7_00 = weekdays_7_00;
    initialValues.weekdays_7_15 = weekdays_7_15;
  }

  return initialValues;
};
