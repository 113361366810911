import React from 'react';
import styles from './LineDivisor.module.scss';
import { useTheme } from 'hooks/theme';

const LineDivisor = () => {
  const { theme } = useTheme();

  return <hr role="divisor" className={`${styles.line} ${styles[theme]}`}></hr>;
};

export default LineDivisor;
