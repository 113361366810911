export const ADD_BUTTON = {
  en_US: 'Add exception',
  pt_BR: 'Adicionar exceção',
  es_ES: 'Añadir excepción',
};

export const LABEL_EXCEPTION = {
  en_US: 'Exceptions',
  pt_BR: 'Exceções',
  es_ES: 'Excepciones',
};

export const TEXT_NO_EXCEPTIONS = {
  en_US: 'There are no exceptions.',
  pt_BR: 'Não há exceções.',
  es_ES: 'No hay excepciones.',
};
