import React, { RefObject } from 'react';
import * as translations from './intl';
import { useIntl } from 'hooks/Intl';
import styles from './ComponentToPrint.module.scss';
import { formatDateByIdiom, stringToDateWithoutTimezone } from 'utils/date';
import { getStatus } from 'utils/status';
import { listCountries } from 'utils/intl/countries';
import { listIdioms } from 'utils/intl/idioms';
import { listPhones } from 'utils/intl/phones';

interface Props {
  refTable: RefObject<HTMLTableElement>;
  appointmentsDetail: AppointmentDetail[];
}

const ComponentToPrint = ({ refTable, appointmentsDetail }: Props) => {
  const { translate, idiom } = useIntl();
  const intl = translate(translations);

  const header = (
    <tr className={`row ${styles.header}`}>
      <th className={`col-1 ${styles.cell}`}>{intl.TEXT_DATE}</th>
      <th className={`col-1 ${styles.cell}`}>{intl.TEXT_TIME} </th>
      <th className={`col-3 ${styles.cell}`}>{intl.TEXT_NAME}</th>
      <th className={`col-2 ${styles.cell}`}>{intl.TEXT_STATUS}</th>
      <th className={`col-2 ${styles.cell}`}>{intl.TEXT_PHONE}</th>
      <th className={`col-2 ${styles.cell}`}>{intl.TEXT_LANGUAGE}</th>
    </tr>
  );

  const content = appointmentsDetail.map((aptment, index) => {
    const { patient_info, appointment_details } = aptment;

    const formatDate = formatDateByIdiom(
      stringToDateWithoutTimezone(appointment_details.date),
      idiom
    );
    const formatTime = appointment_details.date.substring(11, 16);

    const status = getStatus(appointment_details.status);

    let country = listCountries.find(
      country => country.initials === appointment_details?.location?.country
    );

    let aptmentIdiom = listIdioms.find(
      idiom => idiom.initials === patient_info?.preferred_language
    );
    const phoneInformations = `${patient_info?.phone?.international_prefix} ${patient_info?.phone?.number}`;

    let textPhone = listPhones.find(
      phone =>
        phone.initialEN === patient_info?.phone?.phone_type ||
        phone.initialES === patient_info?.phone?.phone_type ||
        phone.initialPT === patient_info?.phone?.phone_type
    );

    if (country === undefined) {
      country = {
        pt_BR: '',
        en_US: '',
        es_ES: '',
        prioritary: '',
        initials: '',
        idiom: '',
        currency: '',
        continent: '',
        order: 0,
      };
    }
    if (aptmentIdiom === undefined) {
      aptmentIdiom = { pt_BR: '', en_US: '', es_ES: '', initials: '' };
    }
    if (textPhone === undefined) {
      textPhone = {
        pt_BR: '',
        en_US: '',
        es_ES: '',
        initialPT: '',
        initialES: '',
        initialEN: '',
      };
    }

    return (
      <tr className={`row ${styles.item}`} key={index}>
        <td className={`col-1 ${styles.cell}`}>{formatDate}</td>
        <td className={`col-1 ${styles.cell}`}>{formatTime}</td>
        <td
          className={`col-3 ${styles.cell}`}
        >{`${patient_info.first_name} ${patient_info.last_name}`}</td>
        <td className={`col-2 ${styles.cell}`}>{intl[`TEXT_${status}`]}</td>
        <td className={`col-2 ${styles.cell}`}>
          {textPhone && `${textPhone[idiom]} ${phoneInformations}`}
        </td>
        <td className={`col-2 ${styles.cell}`}>
          {aptmentIdiom && aptmentIdiom[idiom]}
        </td>

        <td className={`col-11 ${styles.cell}`}>{patient_info.email}</td>
        <td className={`col-11 ${styles.cell}`}>
          {`${country && country[idiom]} (
            ${appointment_details?.location?.state || ''} -
            ${appointment_details?.location?.subdivision || ''}
            )`}
        </td>
        <td className={`col-11 ${styles.cell}`}>
          {appointment_details?.initial_questions.join(' ')}
        </td>
        <td className={`col-11 ${styles.cell}`}>
          {appointment_details?.additional_comment || ''}
        </td>
      </tr>
    );
  });

  return (
    <div style={{ display: 'none' }}>
      <table className={styles.table} ref={refTable}>
        <thead>{header}</thead>
        <tbody className={styles.body}>{content}</tbody>
      </table>
    </div>
  );
};

export default ComponentToPrint;
