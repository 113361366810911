export const en_US = 'en_US';
export const pt_BR = 'pt_BR';
export const es_ES = 'es_ES';

export const listIdioms = [
  {
    initials: 'en_US',
    en_US: 'English',
    pt_BR: 'Inglês',
    es_ES: 'Inglés',
  },
  {
    initials: 'pt_BR',
    en_US: 'Portuguese',
    pt_BR: 'Português',
    es_ES: 'Portugués',
  },
  {
    initials: 'es_ES',
    en_US: 'Spanish',
    pt_BR: 'Espanhol',
    es_ES: 'Español',
  },
];
