import { createContext, ReactNode, useContext, useState } from 'react';
import { useEffect } from 'react';
import { userService } from '../../services';
import { useAuth } from 'hooks/Auth';

export type UserState = {
  status: LoadingStatus;
  user: User | null;
  error?: ApiError['error'];
};

export interface UserContextData {
  user: UserState;
}

type UserContext = {
  loadUser: () => Promise<void>;
} & UserContextData;

interface UserProviderProps {
  children: ReactNode;
  userState?: UserContextData;
}

export const UserContext = createContext({} as UserContext);

const UserProvider = ({ children, userState }: UserProviderProps) => {
  const [user, setUser] = useState<UserState>(
    userState?.user || {
      status: 'LOADING',
      user: null,
    }
  );

  const { userAuth } = useAuth();

  const loadUser = async () => {
    try {
      if (userAuth.logged) {
        setUser({
          user: null,
          status: 'LOADING',
        });

        const { data: user } = await userService.get();

        setUser({
          user,
          status: 'LOADED',
        });
      } else {
        setUser({
          status: 'ERROR',
          user: null,
        });
      }
    } catch (error: any) {
      const data = error?.response?.data as ApiError;
      setUser({
        status: 'ERROR',
        user: null,
        error: data?.error || 'unknown',
      });
    }
  };

  useEffect(() => {
    if (!userState) {
      loadUser();
    }
  }, [userAuth.logged]);

  return (
    <UserContext.Provider
      value={{
        user,
        loadUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUser() {
  const context = useContext(UserContext);
  return context;
}

export { UserProvider, useUser };
