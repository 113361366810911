export const SAVING_DATA = {
  en_US: 'Saving your data',
  pt_BR: 'Salvando seus dados',
  es_ES: 'Guardando tus datos',
};

export const CONGRATULATIONS = {
  en_US: 'Congratulations!',
  pt_BR: 'Parabéns!',
  es_ES: '¡Felicidades!',
};

export const REGISTRATION_COMPLETE = {
  en_US:
    'Your registration is now complete. Please contact an administrator to have your access properly authorized.',
  pt_BR:
    'Seu cadastro agora está completo. Por favor, entre em contato com um administrador para que seus acessos sejam devidamente autorizados.',
  es_ES:
    'Tu registro ya está completo. Póngase en contacto con un administrador para que autorice correctamente tu acceso.',
};
