import { useTheme } from 'hooks/theme';
import styles from './index.module.scss';
import Step from './Step';

interface FormStepperProps {
  steps: number;
  currentStep: number;
}

const FormStepper = ({ steps, currentStep }: FormStepperProps) => {
  const { theme } = useTheme();

  return (
    <div className={styles.container}>
      <div className={`${styles[theme]} ${styles.background}`}></div>
      <div className={styles.steps}>
        {Array(steps)
          .fill(0)
          .map((_, index) => (
            <Step key={index} stepNumber={index} currentStep={currentStep} />
          ))}
      </div>
    </div>
  );
};

export default FormStepper;
