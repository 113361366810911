export const APPOINTMENTS = {
  en_US: 'Appointments',
  es_ES: 'Citas',
  pt_BR: 'Agendamentos',
};

export const TEXT_COPYRIGHT = {
  en_US: '© Copyright 2023. All rights reserved.',
  es_ES: '© Copyright 2023. Todos los derechos reservados.',
  pt_BR: '© Copyright 2023. Todos os direitos reservados.',
};

export const USERS = {
  en_US: 'User accounts',
  es_ES: 'Cuentas de usuario',
  pt_BR: 'Contas de usuário',
};
