import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { createBrowserHistory } from 'history';
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_INSIGHTS_INSTRUMENTATIONKEY ||
      '38757dbe-d840-4e3e-aa9f-34b688fca824',
    disableExceptionTracking: true,
    extensions: [reactPlugin],
    disableFetchTracking: false,
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: createBrowserHistory(),
      },
    },
  },
});

if (process.env.NODE_ENV !== 'test') ai.loadAppInsights();

export const appInsights = ai.appInsights;
export { reactPlugin };
