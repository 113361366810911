import React, { PropsWithChildren, useState } from 'react';
import { useTheme } from 'hooks/theme';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';

interface Props extends PropsWithChildren {
  title: string;
}

const Accordion = ({ children, title }: Props) => {
  const [open, setOpen] = useState(false);
  const { translate } = useIntl();
  const { theme } = useTheme();

  const intl = translate(translations);

  return (
    <div className={styles.container}>
      <div className={`${styles.accordeonHeader} ${styles[theme]}`}>
        <div className={styles.boxNames}>
          <span
            className={`${styles.text} ${styles[theme]} ${styles.boldText}`}
          >
            {title}
          </span>
        </div>

        <div className={`${styles.boxNames2} ${styles[theme]}`}>
          <span
            className={`${styles[theme]} ${styles.boldText} ${styles.text}`}
          >
            {intl.MORE_INFO}
          </span>
        </div>

        <div
          className={`${styles[theme]} ${styles.moreInformation}`}
          onClick={() => setOpen(!open)}
          data-testid="toggle-btn"
        >
          {open ? (
            <FontAwesomeIcon
              icon={faChevronUp}
              className={`${styles.icon} ${styles[theme]}`}
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${styles.icon} ${styles[theme]}`}
            />
          )}
        </div>
      </div>

      {open && <div className={styles.contentMore}>{children}</div>}
    </div>
  );
};

export default Accordion;
