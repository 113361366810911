export enum UserStatus {
  AFFILIATED = 'AFFILIATED',
  UNAFFILIATED = 'UNAFFILIATED',
}

export enum AppointmentStatus {
  ALL = '',
  PENDING = 'PENDING',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  NOT_DONE = 'NOT_DONE',
}
