export const listPhones = [
  {
    initialEN: 'MOBILE',
    initialPT: 'CELULAR',
    initialES: 'MÓVIL',
    en_US: 'Mobile',
    pt_BR: 'Celular',
    es_ES: 'Móvil',
  },
  {
    initialEN: 'LANDLINE',
    initialPT: 'FIXO',
    initialES: 'FIJO',
    en_US: 'Landline',
    pt_BR: 'Fixo',
    es_ES: 'Fijo',
  },
];
