import React, { SVGProps } from 'react';
import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from '../config-icon';

const QuestionIcon = (props: SVGProps<SVGSVGElement>) => {
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: config_icon.secondary,
        }}
        d="M13 15.1h-2c0-2 .6-2.6 1.5-3.3.1-.1.3-.2.4-.3l.4-.4c.8-.7 1.3-1.1 1.3-2.2 0-.5-.2-1-.6-1.5-.5-.5-1.3-.8-2-.7-1.8.1-2.5 1.4-2.5 2.7h-2c0-2.6 1.8-4.5 4.4-4.6 1.3 0 2.6.4 3.5 1.3.8.8 1.3 1.8 1.3 2.9 0 2-1.1 2.9-2 3.7-.1.1-.3.2-.4.3-.2.2-.4.3-.6.5-.6.3-.7.5-.7 1.6zM11 17.2h2v2.1h-2z"
      />
      <path
        d="M12 23.8C5.5 23.8.2 18.5.2 12S5.5.2 12 .2 23.8 5.5 23.8 12 18.5 23.8 12 23.8zm0-21.6c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8z"
        style={{
          fill: config_icon.secondary,
        }}
      />
    </svg>
  );
};

export default QuestionIcon;
