export const MODAL_TITLE_CONFIRMATION_CANCEL_APPOINTMENT = {
  en_US: 'Are you sure you want to cancel this appointment?',
  pt_BR: 'Você está certo de que deseja cancelar este agendamento?',
  es_ES: '¿Estás seguro de que desea cancelar la cita seleccionada?',
};

export const MODAL_TEXT_CONFIRMATION_CANCEL_APPOINTMENT = {
  en_US: 'This action cannot be reversed.',
  pt_BR: 'Está ação não poderá ser revertida.',
  es_ES: 'No se podrá revertir esta acción.',
};

export const TEXT_SUCCESS_CANCEL = {
  en_US: 'Appointment canceled successfully.',
  pt_BR: 'Agendamento cancelado com sucesso.',
  es_ES: 'Cita cancelada con éxito.',
};

export const TEXT_ERROR_CANCEL = {
  en_US: 'Appointment cannot be canceled.',
  pt_BR: 'O agendamento não pode ser cancelado.',
  es_ES: 'La cita no puede ser cancelada.',
};

export const TEXT_YES_I_DO = {
  en_US: `Yes, I'm sure`,
  pt_BR: 'Sim, quero cancelar',
  es_ES: 'Si, estoy seguro',
};

export const TEXT_YES_CONFIRMATION = {
  en_US: `Yes, I confirm`,
  pt_BR: 'Sim, confirmar',
  es_ES: 'Si, confirmar',
};

export const TEXT_NO = {
  en_US: 'No, I want to keep it',
  pt_BR: 'Não, desejo manter o agendamento',
  es_ES: 'No, deseo mantener la cita',
};

export const TEXT_NO_CONFIRMATION = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const MODAL_TITLE_CONFIRMATION_CONFIRM_APPOINTMENT = {
  en_US: 'Do you want to confirm the completion of this appointment?',
  pt_BR: 'Você deseja confirmar a realização deste agendamento?',
  es_ES: '¿Deseas confirmar la realización de la cita seleccionada?',
};

export const TEXT_SUCCESS_CONFIRM = {
  en_US: 'Appointment confirmed successfully.',
  pt_BR: 'Agendamento confirmado com sucesso.',
  es_ES: 'Cita confirmada con éxito.',
};

export const TEXT_ERROR_CONFIRM = {
  en_US: 'Appointment cannot be confirmed.',
  pt_BR: 'O agendamento não pode ser confirmada.',
  es_ES: 'La cita no puede ser confirmada.',
};

export const TEXT_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo electrónico',
};

export const TEXT_TIMEZONE = {
  en_US: 'Timezone',
  pt_BR: 'Timezone',
  es_ES: 'Zona horaria',
};

export const TEXT_LANGUAGE = {
  en_US: 'Language',
  pt_BR: 'Idioma',
  es_ES: 'Idioma',
};

export const TEXT_QUESTIONS_COVID = {
  en_US: 'Information needed',
  pt_BR: 'Informações necessárias',
  es_ES: 'Informaciones necesarias',
};

export const TEXT_INFORMATION_NOTES = {
  en_US: 'Additional comment',
  pt_BR: 'Comentário adicional',
  es_ES: 'Comentario adicional',
};

export const TEXT_CONFIRM = {
  en_US: 'Confirm',
  pt_BR: 'Confirmar',
  es_ES: 'Confirmar',
};

export const TEXT_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const LABEL_COMMENTS = {
  en_US: 'Type a comment',
  pt_BR: 'Digite um comentário',
  es_ES: 'Escriba un comentario',
};

export const FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TEXT_QUESTIONS_1 = {
  en_US: 'Information about self-care techniques for common symptoms.',
  pt_BR: 'Informações sobre técnicas de autocuidado para sintomas comuns.',
  es_ES: 'Información sobre técnicas de autocuidado para síntomas comunes.',
};

export const TEXT_QUESTIONS_2 = {
  en_US: 'Health information and research about chronic conditions.',
  pt_BR: 'Informações de saúde e pesquisas sobre condições crônicas.',
  es_ES: 'Información de salud e investigación sobre condiciones crónicas.',
};

export const TEXT_QUESTIONS_3 = {
  en_US: 'Recently diagnosed medical condition.',
  pt_BR: 'Condição médica recentemente diagnosticada.',
  es_ES: 'Condición médica recientemente diagnosticada.',
};

export const TEXT_QUESTIONS_4 = {
  en_US: 'Information about prescription and over the counter medication.',
  pt_BR: 'Informações sobre medicamentos prescritos e de venda livre.',
  es_ES: 'Información sobre medicamentos recetados y de venta libre.',
};

export const TEXT_QUESTIONS_COVID_1 = {
  en_US: 'Which are the COVID-19 most common symptoms?',
  pt_BR: 'Quais são os sintomas mais comuns do COVID-19?',
  es_ES: '¿Cuáles son los síntomas más comunes de COVID-19?',
};

export const TEXT_QUESTIONS_COVID_2 = {
  en_US:
    'How long is the incubation of COVID 19,  and which are the complications related?',
  pt_BR:
    'Quanto tempo dura a incubação do COVID-19 e quais são as complicações relacionadas?',
  es_ES:
    '¿Cuánto dura la incubación de COVID-19 y cuáles son las complicaciones relacionadas?',
};

export const TEXT_QUESTIONS_COVID_3 = {
  en_US: 'Which are the protective measures and best practices?',
  pt_BR: 'Quais são as medidas protetivas e as melhores práticas?',
  es_ES: '¿Cuáles son las medidas de protección y las mejores prácticas?',
};

export const TEXT_QUESTIONS_COVID_OTHER = {
  en_US: 'Other questions',
  pt_BR: 'Outras perguntas',
  es_ES: 'Otras preguntas',
};
