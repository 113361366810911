export const UPDATE_SUCCESS = {
  en_US: 'Parameters successfully updated!',
  pt_BR: 'Parâmetros atualizados com sucesso!',
  es_ES: '¡Los parámetros se actualizaron correctamente!',
};

export const TEXT_ERROR = {
  en_US: 'The operation could not be completed.',
  pt_BR: 'Não foi possível concluir a operação.',
  es_ES: 'No se pudo completar la operación.',
};

export const LABEL_MINIMUN_DAYS_IN_ADVANCE = {
  en_US: 'Minimum',
  pt_BR: 'Mínimo',
  es_ES: 'Mínimo',
};

export const LABEL_MAX_DAYS_IN_ADVANCE = {
  en_US: 'Maximum',
  pt_BR: 'Máximo',
  es_ES: 'Máximo',
};

export const LABEL_LIMIT_DAY = {
  en_US: 'Schedule Limit Date',
  pt_BR: 'Data limite para agendamento',
  es_ES: 'Fecha límite para programar',
};
