import React from 'react';
import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from './config-icon';

const TooltipIcon = props => {
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 15C4.13388 15 1 11.8661 1 8C1 4.13388 4.13388 1 8 1C11.8661 1 15 4.13388 15 8C15 11.8661 11.8661 15 8 15Z"
        stroke={config_icon.primary}
        strokeWidth={2}
      />
      <path d="M8 6L8 4" stroke={config_icon.primary} strokeWidth={2} />
      <path d="M8 12L8 7" stroke={config_icon.primary} strokeWidth={2} />
    </svg>
  );
};

export default TooltipIcon;
