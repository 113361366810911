/* eslint-disable no-unused-vars */

import LogoVisa from 'components/LogoVisa/LogoVisa';
import { Theme } from 'hooks/theme';
import { ElementType } from 'react';

// types
export enum HeaderTypes {
  HEADER_TRANSPARENT_COLOR_WHITE = 'HEADER_TRANSPARENT_COLOR_WHITE',
  HEADER_TRANSPARENT_COLOR_DARK = 'HEADER_TRANSPARENT_COLOR_DARK',
  HEADER_PRIVATE = 'HEADER_PRIVATE',
}

export interface HeaderTypeStyle {
  Logo: null | ElementType;
  colorLogo: string;
  logoSize: string;
  colorText: string;
  backgroundColor: boolean;
  searchIcon: string;
}

// themes config
const THEME_DEFAULT_CONFIG = {
  HEADER_TRANSPARENT_COLOR_WHITE: {
    Logo: null,
    colorLogo: '#FFFFFF',
    logoSize: 'BIGGER',
    colorText: '#FFFFFF',
    backgroundColor: false,
    searchIcon: '#FFFFFF',
  } as HeaderTypeStyle,
  HEADER_TRANSPARENT_COLOR_DARK: {
    Logo: null,
    colorLogo: '#021E4C',
    logoSize: 'BIGGER',
    colorText: '#021E4C',
    backgroundColor: false,
    searchIcon: '#C2A161',
  } as HeaderTypeStyle,
  HEADER_PRIVATE: {
    Logo: null,
    colorLogo: '#021E4C',
    logoSize: 'MEDIUM',
    colorText: '#021E4C',
    backgroundColor: true,
    searchIcon: '#C2A161',
  } as HeaderTypeStyle,
};

const THEME_VISA_CONFIG = {
  HEADER_TRANSPARENT_COLOR_WHITE: {
    Logo: LogoVisa,
    colorLogo: '#FFFFFF',
    logoSize: 'BIGGER',
    colorText: '#FFFFFF',
    backgroundColor: false,
    searchIcon: '#FFFFFF',
  } as HeaderTypeStyle,
  HEADER_TRANSPARENT_COLOR_DARK: {
    Logo: LogoVisa,
    colorLogo: '#021E4C',
    logoSize: 'BIGGER',
    colorText: '#021E4C',
    backgroundColor: false,
    searchIcon: '#C2A161',
  } as HeaderTypeStyle,
  HEADER_PRIVATE: {
    Logo: LogoVisa,
    colorLogo: '#021E4C',
    logoSize: 'MEDIUM',
    colorText: '#021E4C',
    backgroundColor: true,
    searchIcon: '#C2A161',
  } as HeaderTypeStyle,
};

export const getHeaderConfigByTheme = (theme: Theme) => {
  switch (theme) {
    case Theme.VISA:
      return THEME_VISA_CONFIG;
    default:
      return THEME_DEFAULT_CONFIG;
  }
};
