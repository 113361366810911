import Router from './routes/Router';
import './styles/style.scss';
import './assets/bootstrap/bootstrap.min.css';
import IntlProvider from './hooks/Intl/index';
import ModalProvider from './hooks/Modal';
import ThemeProvider from './hooks/theme';
import { AuthProvider } from 'hooks/Auth';
import { UserProvider } from 'hooks/User';
import ErrorBoundary from 'components/ErrorBoundary';
import IdleTime from 'components/IdleTime/IdleTime';

function App() {
  return (
    <AuthProvider>
      <IntlProvider>
        <UserProvider>
          <ThemeProvider>
            <ErrorBoundary>
              <ModalProvider>
                <IdleTime />
                <Router />
              </ModalProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </UserProvider>
      </IntlProvider>
    </AuthProvider>
  );
}

export default App;
