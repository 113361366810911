export const OPTION_EN = {
  en_US: 'English',
  pt_BR: 'Inglês',
  es_ES: 'Inglés',
};

export const OPTION_PT = {
  en_US: 'Portuguese',
  pt_BR: 'Português',
  es_ES: 'Portugués',
};

export const OPTION_ES = {
  en_US: 'Spanish',
  pt_BR: 'Espanhol',
  es_ES: 'Español',
};
