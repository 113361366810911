import { AxiosInstance } from 'axios';
import { IdiomForAPI } from 'utils/intl';
import { errorHandler } from './errorHandler/errorHandler';

export default (axios: AxiosInstance) => ({
  getUserInfo: async () => {
    const url = `/userinfo`;
    return await axios.get(url);
  },
  changePassword: async (idiom: IdiomForAPI = IdiomForAPI.en_US) => {
    const url = '/auth/change_password';
    return errorHandler(axios.post(url, { idiom }), 'changePassword', {
      url,
      data: [],
      idiom,
    });
  },
});
