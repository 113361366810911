import React, { useEffect } from 'react';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import { useAuth } from 'hooks/Auth';
import { useModal } from 'hooks/Modal';
import { useUser } from 'hooks/User';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import { useNavigate } from 'react-router-dom';

const UserVerifiedBlankPage = () => {
  const { userAuth } = useAuth();
  const { user } = useUser();
  const { actions } = useModal();
  const { translate } = useIntl();
  const navigate = useNavigate();

  const intl = translate(translations);

  useEffect(() => {
    if (
      user.user === null &&
      userAuth.logged === true &&
      userAuth.userInfo?.email_verified === false
    ) {
      actions.showModal(
        '',
        <StyledModal
          type={ModalType.SUCCESS}
          title={intl.TITLE_PAGE_EMAIL_NOT_VERIFIED}
          subtitle={intl.DESCRIPTION_EMAIL_NOT_VERIFIED_PART1}
          subtitleTwo={intl.DESCRIPTION_EMAIL_NOT_VERIFIED_PART2}
          textConfirm={intl.BTN_CONFIRM}
          onClickConfirm={() => {
            navigate('/logout');
          }}
        />,
        false
      );
    }
  }, []);

  return <></>;
};

export default UserVerifiedBlankPage;
