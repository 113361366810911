export const CONNECTION_ERROR = {
  en_US: `It seems that internet connection isn't working properly.`,
  pt_BR: `Parece que a conexão com a Internet não está funcionando corretamente.`,
  es_ES: `Parece que la conexión a Internet no funciona correctamente.`,
};

export const PORTAL_OFFLINE = {
  en_US: `The Visa Benefits Portal is temporarily offline due to maintanance. Pleae try again in a few hours. If you need help or medical assistance, pelase contact us throw the chat. Thank you for using Visa Benefits Portal!`,
  pt_BR: `O Portal de Benefícios Visa está temporariamente off-line devido a manutenção. Por favor, tente novamente em algumas horas. Se precisar de ajuda ou assistência médica, entre em contato conosco pelo chat. Obrigado por usar o Portal de Benefícios Visa!`,
  es_ES: `El Portal de Beneficios de Visa está temporalmente fuera de línea debido a mantenimiento. Vuelve a intentarlo en unas horas. Si necesita ayuda o asistencia médica, por favor contáctenos a través del chat. ¡Gracias por usar el Portal de Beneficios de Visa!`,
};
