import { Translations } from 'hooks/Intl';
import * as Yup from 'yup';

export default (intl: Translations<string>) => {
  return Yup.object().shape({
    affected_date: Yup.string().required(intl.FIELD_REQUIRED),
    nurses_available: Yup.object().shape({
      weekdays_7_00: Yup.number().required(intl.FIELD_REQUIRED),
      weekdays_7_15: Yup.number().required(intl.FIELD_REQUIRED),
      weekends_and_nights: Yup.number().required(intl.FIELD_REQUIRED),
    }),
  });
};
