import styles from './InfoLabelValue.module.scss';
import { useTheme } from '../../../hooks/theme';

const InfoLabelValue = ({ label, value }) => {
  const { theme } = useTheme();

  return (
    <div className={`${styles.container}  ${styles[theme]}`}>
      <label className={`${styles[theme]}`}>{label}</label>
      <p>{value}</p>
    </div>
  );
};

export default InfoLabelValue;
