import { DOMAttributes, ReactNode } from 'react';
import TypographyRoot from './TypographyRoot';
import styles from './index.module.scss';
import { useTheme } from '../../hooks/theme';

export type HtmlTag =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'small'
  | 'em'
  | 'strong'
  | 'label';

export type FontWeight = 'bold' | 'semibold' | 'medium' | 'regular' | 'light';

export interface TypographyProps {
  type?: HtmlTag;
  weight?: FontWeight;
  color?: Colors;
  children?: ReactNode;
  className?: string;
  dangerouslySetInnerHTML?: DOMAttributes<HTMLElement>['dangerouslySetInnerHTML'];
}

const Typography = ({
  type = 'p',
  weight = 'regular',
  color,
  children,
  className,
  dangerouslySetInnerHTML,
}: TypographyProps) => {
  const { theme } = useTheme();

  const weightClassName = `font_${weight}`;
  const colorClassName = color ? `color_${color}` : '';

  let typographyClassName = `${styles[theme]} ${styles[type]} ${styles[weightClassName]}`;
  if (colorClassName) typographyClassName += ` ${styles[colorClassName]}`;
  if (className) typographyClassName += ` ${className}`;

  return (
    <TypographyRoot
      tag={type}
      className={typographyClassName}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </TypographyRoot>
  );
};

export default Typography;
