import React from 'react';
import styles from './FooterButtons.module.scss';
import Button from 'components/Button';
import WrapperButton from 'components/WrapperButton/WrapperButton';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import { useModal } from 'hooks/Modal';

interface Props {
  textButton?: string;
  textDenyButton?: string;
  onClickButton: VoidFunction;
  onClickDenyButton?: VoidFunction;
}

const FooterButtons = (props: Props) => {
  const { textButton, textDenyButton, onClickButton, onClickDenyButton } =
    props;

  const { translate } = useIntl();
  const { actions } = useModal();

  const intl = translate(translations);

  return (
    <div className={styles.container}>
      <WrapperButton>
        <Button type="BorderPrimary" onClick={onClickButton} typeHtml="submit">
          {textButton || intl.BTN_SAVE}
        </Button>
      </WrapperButton>
      <WrapperButton>
        <Button
          type="PrimaryWithoutBorder"
          onClick={onClickDenyButton || actions.closeModal}
          typeHtml="button"
        >
          {textDenyButton || intl.BTN_CANCEL}
        </Button>
      </WrapperButton>
    </div>
  );
};

export default FooterButtons;
