import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { HtmlTag } from '.';

interface TypographyRootProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLElement & HTMLHeadingElement & HTMLLabelElement>,
    HTMLElement & HTMLHeadingElement & HTMLLabelElement
  > {
  children: ReactNode;
  tag: HtmlTag;
}

const TypographyRoot = ({ tag, children, ...props }: TypographyRootProps) => {
  if (tag === 'h1') return <h1 {...props}>{children}</h1>;
  if (tag === 'h2') return <h2 {...props}>{children}</h2>;
  if (tag === 'h3') return <h3 {...props}>{children}</h3>;
  if (tag === 'h4') return <h4 {...props}>{children}</h4>;
  if (tag === 'h5') return <h5 {...props}>{children}</h5>;
  if (tag === 'h6') return <h6 {...props}>{children}</h6>;
  if (tag === 'em') return <em {...props}>{children}</em>;
  if (tag === 'small') return <small {...props}>{children}</small>;
  if (tag === 'span') return <span {...props}>{children}</span>;
  if (tag === 'strong') return <strong {...props}>{children}</strong>;
  if (tag === 'label') return <label {...props}>{children}</label>;
  return <p {...props}>{children}</p>;
};

export default TypographyRoot;
