import axios from 'axios';
import appointments from './appointments';
import user from './user';
import settings from './settings';
import userInfo from './userInfo';
import affiliate from './affiliate';

export const controller = new AbortController();

const nalInstance = axios.create({
  signal: controller.signal,
});

//@ts-ignore
nalInstance.CancelToken = axios.CancelToken;

//Add header to mapi calls ['X-Requested-With'] = 'XMLHttpRequest'
nalInstance.interceptors.request.use(
  function (config: any) {
    if (config.url.startsWith('/api') || config.url.startsWith('/auth'))
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
    return config;
  },
  function (err: any) {
    return Promise.reject(err);
  }
);

const userService = user(nalInstance);
const appointmentsService = appointments(nalInstance);
const settingsService = settings(nalInstance);
const userInfoService = userInfo(nalInstance);
const affiliateService = affiliate(nalInstance);

export {
  userService,
  appointmentsService,
  settingsService,
  userInfoService,
  affiliateService,
};
