import { useTheme } from 'hooks/theme';
import styles from './LoadingSpinner.module.scss';

interface LoadingSpinnerProps {
  fill?: boolean;
}

const LoadingSpinner = ({ fill = false }: LoadingSpinnerProps) => {
  const { theme } = useTheme();

  return (
    <div className={fill ? styles.fill : ''}>
      <div className={styles.loadingSpinner} role="loading">
        {Array.from(Array(12).keys()).map(index => (
          <div key={index} className={styles[theme]} />
        ))}
      </div>
    </div>
  );
};

export default LoadingSpinner;
