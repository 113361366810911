import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from './config-icon';

const SettingsIcon = props => {
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M15 23.9H9v-1.7c0-.5-.3-.9-.7-1.1-.5-.2-1-.1-1.3.2l-1.2 1.2-4.3-4.3L2.7 17c.3-.3.4-.8.2-1.2-.2-.5-.6-.8-1-.8H.1V9h1.7c.5 0 .9-.3 1.1-.7.2-.5.1-1-.2-1.3L1.5 5.8l4.3-4.3L7 2.7c.3.3.8.4 1.2.2.5-.2.8-.6.8-1V.1h6v1.7c0 .5.3.9.7 1.1.5.2 1 .1 1.3-.2l1.2-1.2 4.3 4.3L21.3 7c-.3.3-.4.8-.2 1.2.2.5.6.8 1 .8h1.7v6h-1.7c-.5 0-.9.3-1.1.7-.2.5-.1 1 .2 1.3l1.2 1.2-4.3 4.3-1.1-1.2c-.3-.3-.8-.4-1.2-.2-.5.2-.7.6-.7 1.1v1.7zm-4.1-2H13c.1-1.2.8-2.2 1.9-2.6 1.1-.5 2.4-.3 3.3.5l1.5-1.5c-.8-.9-1-2.1-.5-3.2s1.5-1.9 2.6-2V11c-1.2-.1-2.2-.8-2.6-1.9-.5-1.1-.3-2.4.5-3.3l-1.5-1.5c-.9.8-2.1 1-3.2.5s-1.9-1.5-2-2.6h-2.1c0 1.1-.8 2.1-1.9 2.6-1.1.4-2.3.2-3.2-.5L4.3 5.8c.7.9.9 2.1.5 3.2-.5 1.1-1.5 1.9-2.6 2v2.1c1.2.1 2.2.8 2.6 1.9.5 1.1.3 2.4-.5 3.3l1.5 1.5c.9-.8 2.1-1 3.2-.5 1.1.4 1.9 1.4 1.9 2.6z"
        style={{
          fill: config_icon.secondary,
        }}
      />
      <path
        d="M11.9 15.9C9.7 15.9 8 14.1 8 12s1.8-4 3.9-4c2.2 0 3.9 1.8 3.9 3.9s-1.7 4-3.9 4zm0-5.8c-1 0-1.9.8-1.9 1.9s.8 1.9 1.9 1.9 1.9-.8 1.9-1.9-.8-1.9-1.9-1.9z"
        style={{
          fill: config_icon.primary,
        }}
      />
    </svg>
  );
};

export default SettingsIcon;
