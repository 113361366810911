export const TITLE_USER_ACTIVE = {
  en_US: 'Active',
  pt_BR: 'Ativos',
  es_ES: 'Activo',
};

export const TITLE_USER_PENDING = {
  en_US: 'Awaiting approval',
  pt_BR: 'Aguardando aprovação',
  es_ES: 'En espera de aprobación',
};

export const ALL_APPOINTMENTS = {
  en_US: 'All',
  pt_BR: 'Todos',
  es_ES: 'Todos',
};

export const PENDING_APPOINTMENTS = {
  en_US: 'Pending',
  pt_BR: 'Pendente',
  es_ES: 'Pendiente',
};

export const DONE_APPOINTMENTS = {
  en_US: 'Done',
  pt_BR: 'Realizado',
  es_ES: 'Hecho',
};

export const NOT_DONE_APPOINTMENTS = {
  en_US: 'Not done',
  pt_BR: 'Não realizado',
  es_ES: 'No hecho',
};

export const CANCELED_APPOINTMENTS = {
  en_US: 'Canceled',
  pt_BR: 'Cancelado',
  es_ES: 'Cancelado',
};
