import React, { useEffect } from 'react';
import ErrorPage from 'components/ErrorBoundary/ErrorPage';
import { useIntl } from '../../hooks/Intl/index';
import * as translations from './intl';
import { controller } from 'services';

const SessionExpired = () => {
  const { translate } = useIntl();
  const intl = translate(translations);

  useEffect(() => {
    controller.abort();
  }, []);

  return (
    <ErrorPage
      title={intl.TITLE_SESSION_EXPIRED}
      description={intl.DESCRIPTION_SESSION_EXPIRED}
      reload={false}
      redirect="/logout"
    />
  );
};

export default SessionExpired;
