import { ChangeEvent, ReactNode } from 'react';
import InputLabel from 'components/InputLabel';
import { FormikProps } from 'formik';
import { safeGet } from 'utils/objects';
import inputStyles from 'styles/input.module.scss';
import InputErrorLabel from 'components/InputErrorLabel';
import styles from './index.module.scss';
import { useTheme } from 'hooks/theme';

interface SelectProps {
  label: string;
  name: string;
  value?: string | number;
  touched?: boolean;
  error?: string;
  formikProps?: FormikProps<any>;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  children?: ReactNode;
  className?: string;
}

const Select = ({
  label,
  name,
  value,
  touched,
  error,
  formikProps,
  onChange,
  children,
  className,
}: SelectProps) => {
  const { theme } = useTheme();

  if (formikProps) {
    value = safeGet<string | number>(formikProps, 'values.' + name, '');
    touched = safeGet(formikProps, 'touched.' + name, false);
    error = safeGet(formikProps, 'errors.' + name, '');
    onChange = onChange || formikProps.handleChange;
  }

  const showError = !!error && touched;

  let handledClassName = `${inputStyles.input} ${inputStyles[theme]} ${styles.select} ${styles[theme]}`;
  if (showError) handledClassName += ` ${inputStyles.error}`;
  if (!value) handledClassName += ` ${styles.noValue}`;

  let containerClassName = `${inputStyles.container}`;
  if (className) containerClassName += ` ${className}`;

  return (
    <div className={containerClassName}>
      <InputLabel label={label} showLabel={!!value} />
      <select
        className={handledClassName}
        value={value}
        placeholder={label || ''}
        onChange={onChange}
        name={name}
      >
        {children}
      </select>
      <InputErrorLabel error={error} showError={showError} />
    </div>
  );
};

export default Select;
