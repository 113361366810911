import React, { useState, useEffect } from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { settingsService } from 'services';
import ExceptionsArea from './ExceptionsArea/ExceptionsArea';
import NurseAvailabilityArea from './NurseAvailabilityArea/NurseAvailabilityArea';
import SchedulesArea from './SchedulesArea/SchedulesArea';
import LineDivisor from 'components/LineDivisor/LineDivisor';
import { useUser } from 'hooks/User';

const Admin = () => {
  const [availability, setAvailability] = useState<
    AppointmentsRules | undefined
  >();
  const [load, setLoad] = useState(true);
  const { user } = useUser();

  const partner_id =
    (user?.user?.affiliations &&
      user?.user?.affiliations[0] &&
      user?.user?.affiliations[0]?.partner_id) ||
    '';

  const loadNurseAvailability = async () => {
    setLoad(true);
    try {
      const response = await settingsService.getRules(partner_id);
      setAvailability(response.data);
    } catch (error) {
      setAvailability(undefined);
    }
    setLoad(false);
  };

  useEffect(() => {
    loadNurseAvailability();
  }, []);

  return (
    <div>
      {load ? (
        <LoadingSpinner />
      ) : (
        <>
          <NurseAvailabilityArea
            nurse_availability={availability?.nurses_available}
            schedules_allowed={availability?.schedule_rules}
            reloadPage={loadNurseAvailability}
            partner_id={partner_id}
          />

          <LineDivisor />

          <SchedulesArea
            schedules_allowed={availability?.schedule_rules}
            nurse_availability={availability?.nurses_available}
            reloadPage={loadNurseAvailability}
            partner_id={partner_id}
          />

          <LineDivisor />

          <ExceptionsArea
            exceptions_config={availability?.exceptions}
            reloadPage={loadNurseAvailability}
            partner_id={partner_id}
          />
        </>
      )}
    </div>
  );
};

export default Admin;
