export const STATUS_CANCELED = 'CANCELED';
export const STATUS_CANCELLED = 'CANCELLED';
export const STATUS_DONE = 'DONE';
export const STATUS_NOT_DONE = 'NOT_DONE';
export const STATUS_PENDING = 'PENDING';

export const getStatus = (status: string) => {
  let text = '';
  switch (status) {
    case STATUS_CANCELED:
      text = 'CANCELLED';
      break;
    case STATUS_CANCELLED:
      text = 'CANCELLED';
      break;
    case STATUS_DONE:
      text = 'DONE';
      break;
    case STATUS_NOT_DONE:
      text = 'NOT_DONE';
      break;
    case STATUS_PENDING:
      text = 'PENDING';
      break;
  }

  return text;
};
