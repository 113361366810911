import { ReactNode } from 'react';
import styles from './index.module.scss';
import { useTheme } from 'hooks/theme';
import { FormikProps } from 'formik';
import { safeGet } from 'utils/objects';
import InputErrorLabel from 'components/InputErrorLabel';
import CheckboxIcon from '../../assets/icons/CheckboxIcon';

interface ICheckboxProps {
  onClick?: (value: boolean) => void;
  name: string;
  formikProps?: FormikProps<any>;
  value?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  error?: any;
  touched?: any;
  dataTestid?: string;
  className?: string;
  children?: ReactNode;
}

const CheckBox = ({
  onClick,
  value,
  children,
  error,
  touched,
  name,
  dataTestid,
  className,
  disabled,
  isLoading,
  formikProps,
}: ICheckboxProps) => {
  if (formikProps) {
    value = safeGet<boolean>(formikProps, 'values.' + name, false);
    touched = safeGet(formikProps, 'touched.' + name, false);
    error = safeGet(formikProps, 'errors.' + name, '');
    onClick = onClick || (() => formikProps.setFieldValue(name, !value));
  }

  const _onClick = () => (!disabled && onClick ? onClick(!value) : undefined);
  const { theme } = useTheme();

  return (
    <div className={`${styles.container} ${className}`}>
      <div
        className={styles.input}
        id={name}
        data-testid={dataTestid ? dataTestid : `checkbox-${name}`}
        onClick={_onClick}
      >
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <CheckboxIcon
            isChecked={value || false}
            className={styles.checkboxIcon}
          />
        )}
        <label
          data-testid="label"
          htmlFor={name}
          className={`${value === true ? styles.checked : styles.unchecked} ${
            styles[theme]
          }`}
        >
          {children}
        </label>
      </div>

      <InputErrorLabel error={error} showError={error && touched} />
    </div>
  );
};

export default CheckBox;
