import React, { PropsWithChildren, useEffect, useState } from 'react';
import Admin from 'pages/Admin';
import CompleteRegistration from 'pages/CompleteRegistration';
import ConsultAppointments from 'pages/ConsultAppointments';
import { Route, useNavigate } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout/MainLayout';
import Dashboard from 'pages/Dashboard/Dashboard';
import LayoutHalfBlue from 'layouts/HalfBlue/HalfBlue';
import NotFound from 'pages/NotFound';
import Logout from 'components/Logout/Logout';
import { HeaderTypes } from 'components/Header/headerConfig';
import { useAuth } from 'hooks/Auth';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import ConsultUsers from 'pages/ConsultUsers';
import UnfitProfileNotice from 'pages/UnfitProfileNotice';
import Profile from 'pages/Profile/Profile';
import UserVerifiedBlankPage from 'pages/BlankPage/UserVerifiedBlankPage';
import SessionExpired from 'pages/SessionExpired';

export const IsLogged = (props: PropsWithChildren) => {
  const [redirectRoute, setRedirectRoute] = useState<string | null>(null);
  const { userAuth } = useAuth();
  const navigate = useNavigate();

  const getUserInfoLogged = async () => {
    sessionStorage.setItem('redirectAfterLogin', window.location.pathname);
    setTimeout(() => {
      navigate('/login');
    }, 100);
  };

  useEffect(() => {
    if (!userAuth.logged) {
      getUserInfoLogged();
    } else {
      const redirectUrl = sessionStorage.getItem('redirectAfterLogin');
      if (redirectUrl) {
        setRedirectRoute(redirectUrl);
      }
    }
  }, [userAuth.logged]);

  if (userAuth.logged && redirectRoute) {
    sessionStorage.removeItem('redirectAfterLogin');
    navigate(redirectRoute, { replace: true });
    setRedirectRoute(null);
  }

  return userAuth.logged ? <>{props.children}</> : <LoadingSpinner />;
};

export const registerRoute = [
  <Route
    key="/logout"
    path="/logout"
    element={
      <MainLayout>
        <Logout />
      </MainLayout>
    }
  />,
  <Route
    key="/session-expired"
    path="/session-expired"
    element={
      <LayoutHalfBlue>
        <SessionExpired />
      </LayoutHalfBlue>
    }
  />,
  <Route
    key="*"
    path="*"
    element={
      <IsLogged>
        <MainLayout
          showNavigation={false}
          logoutOnly
          hasBlueBackground
          titleBlueBackground="completeRegistration"
        >
          <CompleteRegistration />
        </MainLayout>
      </IsLogged>
    }
  />,
];

const RedirectRoute = [
  <Route
    key="*"
    path="*"
    element={
      <LayoutHalfBlue>
        <NotFound redirect="/dashboard" />
      </LayoutHalfBlue>
    }
  />,
];

const GeneralRoutes = [
  <Route
    key="/logout"
    path="/logout"
    element={
      <MainLayout>
        <Logout />
      </MainLayout>
    }
  />,
  <Route
    key="/session-expired"
    path="/session-expired"
    element={
      <LayoutHalfBlue>
        <SessionExpired />
      </LayoutHalfBlue>
    }
  />,
];

const NurseRoutes = [
  <Route
    key="/"
    path="/"
    element={
      <IsLogged>
        <MainLayout headerType={HeaderTypes.HEADER_PRIVATE}>
          <Dashboard />
        </MainLayout>
      </IsLogged>
    }
  />,
  <Route
    key="/dashboard"
    path="/dashboard"
    element={
      <IsLogged>
        <MainLayout>
          <Dashboard />
        </MainLayout>
      </IsLogged>
    }
  />,
  <Route
    key="/consult-appointments"
    path="/consult-appointments"
    element={
      <IsLogged>
        <MainLayout
          hasBlueBackground
          titleBlueBackground={'consultAppoinments'}
        >
          <ConsultAppointments />
        </MainLayout>
      </IsLogged>
    }
  />,
  <Route
    key="/profile"
    path="/profile"
    element={
      <IsLogged>
        <MainLayout hasBlueBackground titleBlueBackground={'profile'}>
          <Profile />
        </MainLayout>
      </IsLogged>
    }
  />,
];

export const AdminRouters = [
  <Route
    key="/consult-users"
    path="/consult-users"
    element={
      <IsLogged>
        <MainLayout hasBlueBackground titleBlueBackground={'consultUsers'}>
          <ConsultUsers />
        </MainLayout>
      </IsLogged>
    }
  />,
  <Route
    key="/admin"
    path="/admin"
    element={
      <IsLogged>
        <MainLayout hasBlueBackground titleBlueBackground={'admin'}>
          <Admin />
        </MainLayout>
      </IsLogged>
    }
  />,
  <Route
    key="/logout"
    path="/logout"
    element={
      <MainLayout>
        <Logout />
      </MainLayout>
    }
  />,
  <Route
    key="*"
    path="*"
    element={
      <LayoutHalfBlue>
        <NotFound redirect="/dashboard" />
      </LayoutHalfBlue>
    }
  />,
];
export const AdminProfileRoutes = [
  ...NurseRoutes,
  ...AdminRouters,
  ...GeneralRoutes,
  ...RedirectRoute,
];

export const NurseProfileRoutes = [
  ...NurseRoutes,
  ...GeneralRoutes,
  ...RedirectRoute,
];

const PrivatesRoutes = [
  ...NurseRoutes,
  ...AdminRouters,
  ...GeneralRoutes,
  ...RedirectRoute,
];

export const USerEmailNotVerified = [
  <Route
    key="/logout"
    path="/logout"
    element={
      <MainLayout empty>
        <Logout />
      </MainLayout>
    }
  />,
  <Route
    key="/session-expired"
    path="/session-expired"
    element={
      <LayoutHalfBlue>
        <SessionExpired />
      </LayoutHalfBlue>
    }
  />,
  <Route
    key="*"
    path="*"
    element={
      <IsLogged>
        <MainLayout empty>
          <UserVerifiedBlankPage />
        </MainLayout>
      </IsLogged>
    }
  />,
];

export const UserWithoutProfile = [
  <Route
    key="/logout"
    path="/logout"
    element={
      <MainLayout empty>
        <Logout />
      </MainLayout>
    }
  />,
  <Route
    key="/session-expired"
    path="/session-expired"
    element={
      <LayoutHalfBlue>
        <SessionExpired />
      </LayoutHalfBlue>
    }
  />,
  <Route
    key="/profile"
    path="/profile"
    element={
      <IsLogged>
        <MainLayout hasBlueBackground titleBlueBackground={'profile'}>
          <Profile />
        </MainLayout>
      </IsLogged>
    }
  />,
  <Route
    key="*"
    path="*"
    element={
      <MainLayout>
        <UnfitProfileNotice />
      </MainLayout>
    }
  />,
];

export default PrivatesRoutes;
