import { Route } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout/MainLayout';
import Home from 'pages/Home/Home';
import LayoutHalfBlue from 'layouts/HalfBlue/HalfBlue';
import SessionExpired from 'pages/SessionExpired';
import NotFound from 'pages/NotFound';
import Login from 'components/Login/Login';
import Logout from 'components/Logout/Logout';
import { HeaderTypes } from 'components/Header/headerConfig';

const PublicRoutes = [
  <Route
    key="/"
    path="/"
    element={
      <MainLayout headerType={HeaderTypes.HEADER_TRANSPARENT_COLOR_DARK}>
        <Home />
      </MainLayout>
    }
  />,
  <Route
    key="/login"
    path="/login"
    element={
      <MainLayout headerType={HeaderTypes.HEADER_TRANSPARENT_COLOR_DARK}>
        <Login />
      </MainLayout>
    }
  />,
  <Route
    key="/logout"
    path="/logout"
    element={
      <MainLayout headerType={HeaderTypes.HEADER_TRANSPARENT_COLOR_DARK}>
        <Logout />
      </MainLayout>
    }
  />,
  <Route
    key="/session-expired"
    path="/session-expired"
    element={
      <LayoutHalfBlue>
        <SessionExpired />
      </LayoutHalfBlue>
    }
  />,
  <Route
    key="*"
    path="*"
    element={
      <LayoutHalfBlue>
        <NotFound />
      </LayoutHalfBlue>
    }
  />,
];

export default PublicRoutes;
