export const getCookie = (name: string) => {
  function escape(s: string) {
    return s.replace(/\/([.*+?/\\^${}()|/\\[\]/\\/\\])/g, '\\$1');
  }
  const match = document.cookie.match(
    RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
  );
  return match ? match[1] : null;
};

export const setCookie = (name: string, value: string, days?: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const clearListCookies = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const spcook = cookies[i].split('=');
    deleteCookie(spcook[0]);
  }
  function deleteCookie(cookiename: string) {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    const expires = ';expires=' + d;
    const name = cookiename;
    //alert(name);
    const value = '';
    document.cookie = name + '=' + value + expires + '; path=/acc/html';
  }
  //@ts-ignore
  window.location = ''; // TO REFRESH THE PAGE
};
