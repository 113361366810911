import { AxiosInstance } from 'axios';
import { errorHandler } from './errorHandler/errorHandler';

interface PaginatorAppointments {
  records: Appointment[];
  paginator: {
    current_page: number;
    amount_items: number;
    amount_pages: number;
    hasPrev: boolean;
    hasNext: boolean;
  };
}

interface queryAdminGetAppointments {
  status?: AppointmentStatus;
  from_date?: string;
  to_date?: string;
  page_index?: number;
  amount_items?: number;
}

const url = '/api/v1/appointments';

export default (axios: AxiosInstance) => ({
  conclude: async (
    appointment_id: string,
    additional_comment: string,
    status: 'DONE' | 'NOT_DONE'
  ) => {
    const concludeUrl = `${url}/${appointment_id}/conclude`;
    return errorHandler(
      axios.post(concludeUrl, { additional_comment, status }),
      'conclude - appointment',
      {
        concludeUrl,
        appointment_id,
      }
    );
  },
  getBy: async (partner_id: string, query?: queryAdminGetAppointments) => {
    const urlAdmin = `/api/v1/admin/${partner_id}/appointments`;
    let queryString = '';

    if (query) {
      const emptyKeys = Object.keys(query).filter(
        key =>
          // @ts-ignore
          query[key] === undefined || query[key] === null || query[key] === ''
      );
      if (emptyKeys) {
        // @ts-ignore
        emptyKeys.forEach(emptyKey => delete query[emptyKey]);
      }
      // @ts-ignore
      const queryArray = Object.keys(query).map(key => `${key}=${query[key]}`);
      queryString = `?${queryArray.join('&')}`;
    }

    return errorHandler<PaginatorAppointments>(
      axios.get(`${urlAdmin}${queryString}`),
      'getBy - appointment',
      {
        url,
      }
    );
  },
  getDetail: async (appointmentId: string) => {
    const detailUrl = `${url}/${appointmentId}`;
    return errorHandler<AppointmentDetail>(
      axios.get(detailUrl),
      'getDetail - appointment',
      { url, appointmentId }
    );
  },
});
