import { format } from 'date-fns';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Formik, FormikProps } from 'formik';
import { useEffect, useState, useRef } from 'react';
import { userService, affiliateService } from 'services';
import { safeGet } from 'utils/objects';
import { useIntl } from '../../hooks/Intl';
import UserTable from './UserTable';
import styles from './index.module.scss';
import * as translations from './intl';
import { UserStatus } from '../../utils/category';
import Input from 'components/Input';
import Select from 'components/Select';
import { useModal } from 'hooks/Modal';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import ProfileEnum from 'utils/profile-enum';
import { useUser } from 'hooks/User';
import Calendar from 'components/Calendar';
import UserCategories from 'components/Categories/UserCategories';
import SearchIcon from 'assets/icons/SearchIcon';

const ConsultUsers = ({ initialCategory = UserStatus.UNAFFILIATED }) => {
  const [category, setCategory] = useState(initialCategory);
  const [loading, setLoading] = useState(false);
  const [usersFiltered, setUsersFiltered] = useState<User[]>([]);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [showButtons, setShowButtons] = useState(false);

  const { translate } = useIntl();
  const intl = translate(translations);
  const ref = useRef<FormikProps<any>>(null);
  const { actions } = useModal();
  const { user } = useUser();

  const profiles: UserRole[] = ['ADMINISTRATOR', 'NURSE'];
  const partners: Partner[] = user?.user?.affiliations
    ? user.user.affiliations.map(
        affiliation =>
          ({
            partner_name: affiliation.partner_name,
            partner_id: affiliation.partner_id,
          } as Partner)
      )
    : [];
  const colorPrimary = '#021e4c';

  const userAffiliations =
    (user.user?.affiliations &&
      user.user?.affiliations.filter(
        (affiliations: Affiliation) => affiliations.partner_name !== 'NAL'
      )) ||
    [];

  interface IValues {
    data: {
      email: string;
      profile: string;
      partner: string;
      startDate: Date | null;
    };
  }

  const initialValues = {
    data: {
      email: '',
      profile: '',
      partner: '',
      startDate: null,
    },
  };

  const submit = async (values: IValues) => {
    setLoading(true);
    try {
      const partnerId =
        values.data.partner || user?.user?.affiliations[0].partner_id;
      const startDateFormated = values.data.startDate
        ? format(values.data.startDate, 'yyyy-MM-dd')
        : undefined;

      const response = await userService.getBy(partnerId as string, {
        page_index: 1,
        status: category,
        role: values.data.profile as UserRole,
        email: values.data.email,
        created_at: startDateFormated
          ? `${startDateFormated}T00:00:00`
          : undefined,
      });
      setUsersFiltered(response.data?.records);
      setShowLoadMore(response.data?.paginator.hasNext);
      setPage(() => 2);
    } catch (error) {
      setUsersFiltered([]);
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
    setLoading(false);
  };

  const filterUsers = async (category: UserStatus) => {
    setLoading(true);
    setCategory(category);
    ref?.current?.resetForm();
    try {
      const response = await userService.getBy(
        user.user?.affiliations[0].partner_id as string,
        { status: category, page_index: 1, amount_items: 10 }
      );
      setUsersFiltered(response.data?.records);
      setShowLoadMore(response.data?.paginator.hasNext);
      setPage(() => 2);
    } catch (error) {
      setUsersFiltered([]);
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
    setLoading(false);
  };

  const addMore = async () => {
    setLoading(true);
    try {
      const partnerId =
        ref.current?.values.data.partner ||
        user?.user?.affiliations[0].partner_id;
      const startDateFormated = ref.current?.values.data.startDate
        ? format(ref.current?.values.data.startDate, 'yyyy-MM-dd')
        : undefined;

      const response = await userService.getBy(partnerId as string, {
        page_index: page,
        status: category,
        role: ref.current?.values.data.profile as UserRole,
        email: ref.current?.values.data.email,
        created_at: startDateFormated
          ? `${startDateFormated}T00:00:00`
          : undefined,
      });
      const newData = response.data?.records || [];
      setUsersFiltered(prev => [...prev, ...newData]);
      setShowLoadMore(response.data?.paginator.hasNext);
      setPage(prev => prev + 1);
    } catch (error) {
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
    setLoading(false);
  };

  const updateProfileToPerson = async (user_id: string, data: affiliate[]) => {
    setLoading(true);
    try {
      await affiliateService.update(user_id, data);

      actions.showModal(
        '',
        <StyledModal type={ModalType.SUCCESS} title={intl.UPDATE_SUCCESS} />,
        false
      );

      filterUsers(category);
    } catch (error) {
      console.log(error);

      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    filterUsers(category);
  }, [showButtons]);

  const getTranslation = (name: string) => {
    if (name === ProfileEnum.ADMINISTRATOR) return intl.ADMINISTRATOR_LABEL;

    return intl.NURSE_LABEL;
  };

  return (
    <div className={styles.Container}>
      <div className={styles.filterAera}>
        <UserCategories filterUsers={filterUsers} selected={category} />
        <Button
          width="auto"
          type="PrimaryWithoutBorder"
          className={styles.icon}
          onClick={() => setShowButtons(prev => !prev)}
          dataTestid="show-buttons-toggle"
        >
          <SearchIcon color={showButtons ? undefined : colorPrimary} />
        </Button>
      </div>

      {showButtons && (
        <Formik innerRef={ref} initialValues={initialValues} onSubmit={submit}>
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <form onSubmit={handleSubmit} role="form">
                <div className={`${styles.items} row`}>
                  {category === UserStatus.AFFILIATED && (
                    <>
                      <div className={`col-12 col-sm-6 col-md-3`}>
                        <Select
                          label={intl.USERS_PROFILE}
                          className={styles.selectInputs}
                          name="data.profile"
                          formikProps={props}
                        >
                          <option value="">{intl.USERS_PROFILE}</option>
                          {profiles.map(profile => (
                            <option value={profile} key={profile}>
                              {getTranslation(profile)}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className={`col-12 col-sm-6 col-md-3`}>
                        <Select
                          label={intl.TEXT_PARTNER}
                          className={styles.selectInputs}
                          name="data.partner"
                          formikProps={props}
                        >
                          <option value="">{intl.TEXT_PARTNER}</option>
                          {userAffiliations.map(affiliation => (
                            <option
                              value={affiliation.partner_id}
                              key={affiliation.partner_name}
                            >
                              {affiliation.partner_name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </>
                  )}
                  <div className={`col-12 col-sm-6 col-md-3`}>
                    <Input
                      className={styles.searchInputs}
                      label="Email"
                      value={values.data.email}
                      name="data.email"
                      onChange={handleChange}
                      error={safeGet(errors, 'data.email', '')}
                    />
                  </div>

                  <div className={`col-12 col-sm-6 col-md-3`}>
                    <Calendar
                      value={values.data.startDate}
                      label={intl.CREATED_DATE}
                      name="data.startDate"
                      onChange={handleChange}
                      touched={safeGet(touched, 'data.startDate', false)}
                      error={safeGet(errors, 'data.startDate', '')}
                      setFieldValue={setFieldValue}
                    />
                  </div>

                  <div className={`col-12 col-sm-6 col-md-3`}>
                    <Button
                      onClick={() => null}
                      type="BorderPrimary"
                      typeHtml="submit"
                    >
                      {intl.BTN_SEARCH}
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      )}

      {loading ? (
        <LoadingSpinner />
      ) : (
        <UserTable
          users={usersFiltered}
          showLoadMore={showLoadMore}
          addMore={addMore}
          profiles={profiles}
          updateProfileToPerson={updateProfileToPerson}
          partners={partners}
          reload={() => filterUsers(category)}
        />
      )}
    </div>
  );
};

export default ConsultUsers;
