export const CONFIG_ICON = {
  'theme-default': {
    primary: '#C2A161', //021e4c
    secondary: '#C2A161',
    tertiary: '#FFFFFF',
    text: '#354B70',
    link: '#021E4C',
    background: '#fff',
    lightBackground: '#C2A161',
    input: '#54606c',
    subtitle: '#54606c',
    const_orange: '#fa9b00',
    base_gray: '#cad1d6',
    light_gray: '#ececec',
    const_green: '#30d167',
    inactive: '#808EA5',
    disabled: '#cad1d6',
    alert: '#D65168',
    success: '#40996B',
    error: '#D65168',
    secondary_darkbackground: '#C2A161',
    primary_darkbackground: '#C2A161',
  },
  'theme-visa': {
    primary: '#C2A161', //021e4c
    secondary: '#C2A161',
    tertiary: '#FFFFFF',
    text: '#354B70',
    link: '#021E4C',
    background: '#fff',
    lightBackground: '#C2A161',
    input: '#54606c',
    subtitle: '#54606c',
    const_orange: '#fa9b00',
    base_gray: '#cad1d6',
    light_gray: '#ececec',
    const_green: '#30d167',
    inactive: '#808EA5',
    disabled: '#cad1d6',
    alert: '#D65168',
    success: '#40996B',
    error: '#D65168',
    secondary_darkbackground: '#C2A161',
    primary_darkbackground: '#C2A161',
  },
};
