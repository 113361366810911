/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useState,
  useEffect,
} from 'react';
import { setModalObserver } from 'services/errorHandler/errorHandler';
import Modal from '../../components/Modal';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import * as translations from './intl';
import { useIntl } from 'hooks/Intl';

interface State {
  show: boolean;
  title: string;
  close: (() => void) | boolean;
  innerComponent: ReactElement | null;
  duo: boolean;
  loading: boolean | null;
  loadingMessage: string | null;
  icon: string | null;
  isForm: boolean;
}

export interface Actions {
  closeModal: () => void;
  showModal: (
    title: string,
    innerComponent: ReactElement,
    close: (() => void) | boolean,
    duo?: boolean,
    icon?: string,
    isForm?: boolean
  ) => void;
  showLoading: (loadMessage?: string) => void;
  hideLoading: () => void;
  showError: (errorType: ErrorType) => void;
}

export enum ErrorType {
  PORTAL_OFFLINE = 'PORTAL_OFFLINE',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
}

const ModalContext = createContext({
  state: {} as State,
  actions: {} as Actions,
});

const ModalProvider = (props: PropsWithChildren) => {
  const [state, setState] = useState<State>({
    show: false,
    title: '',
    close: false,
    duo: false,
    icon: null,
    isForm: false,
    innerComponent: null,
    loading: null,
    loadingMessage: null,
  });

  const { translate } = useIntl();
  const intl = translate(translations);

  function showModal(
    title: string,
    innerComponent: ReactElement,
    close: (() => void) | boolean,
    duo?: boolean,
    icon?: string,
    isForm?: boolean
  ) {
    const modal = {
      ...state,
      title,
      innerComponent,
      close,
      duo,
      icon,
      isForm,
      show: true,
    } as State;
    setState(modal);
  }

  function closeModal() {
    const modal = {
      ...state,
      show: false,
      loadingMessage: '',
    };
    setState(modal);
  }

  function showLoading(message?: string) {
    const modal = {
      ...state,
      loadingMessage: message || '',
      loading: true,
      show: true,
    };
    setState(modal);
  }

  function hideLoading() {
    const modal = {
      ...state,
      loadingMessage: '',
      loading: false,
    };
    setState(modal);
  }

  function showError(errorType: ErrorType) {
    showModal(
      '',
      <StyledModal title={intl[errorType]} type={ModalType.ERROR} />,
      false
    );
  }

  const actions = {
    showModal,
    closeModal,
    showLoading,
    hideLoading,
    showError,
  } as Actions;

  const value = {
    state,
    actions,
  };

  useEffect(() => {
    setModalObserver(actions);
  }, []);

  return (
    <ModalContext.Provider value={value}>
      <Modal />
      {props.children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
export default ModalProvider;
