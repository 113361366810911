import { appInsights } from 'AppInsights';
import { userInfoService } from 'services';

interface User {
  email: string;
  customer_id: string;
  family_name: string;
  given_name: string;
}

let user = {} as User;

const getEmailAndCnUserLogged = async () => {
  try {
    const { data } = await userInfoService.getUserInfo();
    user = {
      email: data && data.email,
      customer_id: data && data.customer_id,
      family_name: data && data.family_name,
      given_name: data && data.given_name,
    };
  } catch (error) {}
};

export const trackExceptionMonitor = async (
  name: string,
  error: any,
  bodyRequest: any
) => {
  if (!appInsights) return;
  await getEmailAndCnUserLogged();

  const response = {
    status: error && error.response ? error.response.status : null,
    error_description:
      error && error.response && error.response.data
        ? error.response.data.error_description
        : null,
  };
  return appInsights.trackException({
    exception: new Error(`${name}`),
    properties: {
      user,
      response: response,
      body: bodyRequest,
    },
  });
};

export const trackEventUserAction = async (action: string, body: any) => {
  if (!appInsights) return;
  await getEmailAndCnUserLogged();
  return appInsights.trackEvent({
    name: `${action}`,
    properties: { email: user.email, body: body },
  });
};
