import * as Yup from 'yup';

const validationDataSchema = (intl: any) => {
  return Yup.object().shape({
    consultation: Yup.object().shape({
      startDate: Yup.date()
        .required(intl.TEXT_FIELD_REQUIRED)
        .nullable(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      endDate: Yup.date()
        .required(intl.TEXT_FIELD_REQUIRED)
        .nullable(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .min(Yup.ref('startDate'), intl.VALIDATE_DATE_VALID),
    }),
  });
};
export default validationDataSchema;
