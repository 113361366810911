import React, { useEffect } from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { userInfoService } from 'services';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'hooks/Intl';

const Login = () => {
  const navigate = useNavigate();

  const { twoDigitsIdiom } = useIntl();

  const idiomByTwoDigits = () => {
    const twoDigits = twoDigitsIdiom();
    return twoDigits !== 'pt' ? twoDigits : `${twoDigits}-BR`;
  };

  const getUserInfoLogged = async () => {
    try {
      await userInfoService.getUserInfo();
      navigate('/dashboard');
    } catch (error) {
      window.location.href = `/login?ui_locales=${idiomByTwoDigits()}`;
    }
  };

  useEffect(() => {
    getUserInfoLogged();
  }, []);

  return (
    <div>
      <LoadingSpinner />
    </div>
  );
};

export default Login;
