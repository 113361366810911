import React from 'react';
import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from './config-icon';

const ChevronUpIcon = props => {
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];

  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        d="m1 11 5.586-5.586a2 2 0 0 1 2.828 0L15 11"
        stroke={config_icon.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ChevronUpIcon;
