export const APPOINTMENTS_SUBTITLE = {
  en_US: 'Appointments',
  pt_BR: 'Agendamentos',
  es_ES: 'Citas',
};

export const START_DATE = {
  en_US: 'Start date',
  pt_BR: 'Data de início',
  es_ES: 'Fecha de inicio',
};

export const END_DATE = {
  en_US: 'End date',
  pt_BR: 'Data de fim',
  es_ES: 'Fecha final',
};

export const BTN_SEARCH = {
  en_US: 'Search',
  pt_BR: 'Procurar',
  es_ES: 'Buscar',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const TEXT_ERROR = {
  en_US: 'The operation could not be completed.',
  pt_BR: 'Não foi possível concluir a operação.',
  es_ES: 'No se pudo completar la operación.',
};
