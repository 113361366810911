import React from 'react';
import styles from './ExceptionsArea.module.scss';
import ExceptionItem from './ExceptionItem/ExceptionItem';
import { useModal } from 'hooks/Modal';
import ExceptionForm, { TypeForm } from './ExceptionForm/ExceptionForm';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import ButtonWithIcon from 'components/ButtonWithIcon/ButtonWithIcon';
import AddIcon from '../../../assets/icons/AddIcon';
import Typography from 'components/Typography';

interface Props {
  exceptions_config: ExceptionsConfig[] | undefined;
  reloadPage: VoidFunction;
  partner_id: string;
}

const ExceptionsArea = ({
  exceptions_config,
  reloadPage,
  partner_id,
}: Props) => {
  const { actions } = useModal();
  const { translate } = useIntl();

  const intl = translate(translations);

  const newException = () => {
    actions.showModal(
      intl.ADD_BUTTON,
      <ExceptionForm
        type={TypeForm.ADD}
        reloadPage={reloadPage}
        partner_id={partner_id}
      />,
      true
    );
  };

  if (!exceptions_config) {
    return <></>;
  }

  return (
    <div className={styles.subContainer}>
      <nav className={styles.header}>
        <Typography color="primary" type="h3" weight="light">
          {intl.LABEL_EXCEPTION}
        </Typography>

        <ButtonWithIcon
          Icon={AddIcon}
          label={intl.ADD_BUTTON}
          onClick={newException}
        />
      </nav>
      <div>
        {exceptions_config && exceptions_config?.length > 0 ? (
          <>
            {exceptions_config.map(exception => (
              <ExceptionItem
                key={exception.exception_id}
                exception={exception}
                reloadPage={reloadPage}
                partner_id={partner_id}
              />
            ))}
          </>
        ) : (
          <div className={styles.msgArea}>
            <Typography color="subtitle" type="label">
              {intl.TEXT_NO_EXCEPTIONS}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExceptionsArea;
