import styles from './index.module.scss';
import { useTheme } from 'hooks/theme';

interface InfoLabelValueProps {
  label: string;
  value: string;
  customStyle?: any;
}

const InfoLabelValue = ({ label, value, customStyle }: InfoLabelValueProps) => {
  const { theme } = useTheme();
  return (
    <div className={`${styles.container}  ${styles[theme]} ${customStyle}`}>
      <label className={`${styles[theme]}`}>{label}</label>
      <p dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  );
};

export default InfoLabelValue;
