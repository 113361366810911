import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { useTheme } from 'hooks/theme';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';

const GenerateBanner = (props: any) => {
  const { icon, url, title, blue, border, children, big } = props;
  const { theme } = useTheme();

  return (
    <div
      className={` ${styles.generate} ${blue ? styles.blue : styles.white} ${
        big ? styles.big : ''
      }`}
    >
      <Link
        to={url}
        className={`${styles[theme]} ${blue ? styles.blue : styles.white}`}
        data-testid="link"
      >
        <span className={styles.icon}>
          {icon}
          <span>{title}</span>
        </span>
        <span className={styles.iconArrow}>
          <ArrowRightIcon width={20} height={20} />
        </span>
      </Link>
      <div
        className={`${styles[theme]} ${styles.separator} ${
          border ? styles.border : styles.none
        }`}
        data-testid="separator"
      ></div>
      {children}
    </div>
  );
};

export default GenerateBanner;
