import Typography from 'components/Typography';
import { useIntl } from '../../hooks/Intl';
import * as translations from './intl';
import styles from './Home.module.scss';
import banner from 'assets/images/banner.png';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';

const Home = () => {
  const { translate } = useIntl();
  const intl = translate(translations);
  const navigate = useNavigate();

  return (
    <div className={styles.banner}>
      <img src={banner} className={styles.background} />
      <div className={styles.content}>
        <Typography type="h1" color="background" weight="medium">
          {intl.TITLE}
        </Typography>
        <Typography type="h3" color="background" className={styles.subtitle}>
          {intl.DESCRIPTION}
        </Typography>
        <Button
          type="Secondary"
          width="150px"
          onClick={() => navigate('/login')}
        >
          {intl.LOG_IN}
        </Button>
      </div>
    </div>
  );
};

export default Home;
