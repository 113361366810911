export const TEXT_PROFILE = {
  en_US: 'Account information',
  pt_BR: 'Informações da conta',
  es_ES: 'Información de la cuenta',
};

export const TEXT_ADDITIONAL_ACCESSES = {
  en_US: 'Additional accesses',
  pt_BR: 'Acessos adicionais',
  es_ES: 'Accesos adicionales',
};

export const TEXT_LOG_OUT = {
  en_US: 'Log out',
  pt_BR: 'Sair',
  es_ES: 'Cerrar sesión',
};

export const APPOINTMENTS = {
  en_US: 'Appointments',
  es_ES: 'Citas',
  pt_BR: 'Agendamentos',
};

export const SETTINGS = {
  en_US: 'Settings',
  es_ES: 'Configuración',
  pt_BR: 'Configurações',
};

export const USERS = {
  en_US: 'Users',
  pt_BR: 'Usuários',
  es_ES: 'Usuarios',
};
