export const USER_ACCOUNT = {
  en_US: 'User accounts',
  es_ES: 'Cuentas de usuario',
  pt_BR: 'Contas de usuário',
};

export const APPOINTMENTS = {
  en_US: 'Appointments',
  es_ES: 'Citas',
  pt_BR: 'Agendamentos',
};
