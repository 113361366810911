import React, { SVGProps } from 'react';
import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from '../config-icon';

interface Props extends SVGProps<SVGSVGElement> {
  modal_error?: boolean;
}

const ErrorAttentionIcon = (props: Props) => {
  const { modal_error = false } = props;
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];

  const secondaryColor = modal_error
    ? config_icon.error
    : config_icon.secondary;
  const primaryColor = modal_error ? config_icon.error : config_icon.primary;

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <path
        id="Path-43_00000029028235646786070270000005832992646141298343_"
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 2,
        }}
        d="M17.7 22.4H1.1v-1.1L11.5 1.6h1l10.4 19.7v1.1h-5.2"
      />
      <path
        style={{
          fill: 'none',
          stroke: secondaryColor,
          strokeWidth: 2,
        }}
        d="M12 9.5v5.7M12 17.2v2"
      />
    </svg>
  );
};

export default ErrorAttentionIcon;
