import { CSSProperties, ReactNode } from 'react';
import styles from './index.module.scss';
import { useTheme } from '../../hooks/theme';

type Size = `${number}px` | `${number}%` | 'auto';

export type ButtonTypes =
  | 'BorderSecondary'
  | 'Secondary'
  | 'SecondaryWithoutBorder'
  | 'BorderPrimary'
  | 'Primary'
  | 'PrimaryWithoutBorder'
  | 'BorderAlert'
  | 'BorderPrimaryDashed'
  | 'BorderSecondaryDashed'
  | 'LightGray'
  | 'Background';

interface IButtonProps {
  children?: ReactNode;
  label?: string;
  onClick: () => void;
  width?: Size;
  height?: Size;
  type?: ButtonTypes;
  disabled?: boolean;
  border?: boolean;
  typeHtml?: 'button' | 'reset' | 'submit' | undefined;
  borderRadius?: number;
  style?: CSSProperties;
  dataTestid?: string;
  className?: string;
  id?: any;
}

const Button = (props: IButtonProps) => {
  const {
    type,
    width,
    height,
    onClick,
    border = true,
    disabled,
    style: styleInline,
    typeHtml = undefined,
    className,
    dataTestid,
    id,
  } = props;
  const { theme } = useTheme();
  let buttonTypeClassName = '';
  let idType = '';
  const noBorder = !border ? styles.noBorder : '';
  const styleDisabled = disabled ? styles.disabled : '';

  switch (type) {
    case 'BorderSecondary': {
      buttonTypeClassName = styles.borderSecondary;
      idType = 'borderSecondary';
      break;
    }
    case 'Secondary': {
      buttonTypeClassName = styles.secondary;
      idType = 'secondary';
      break;
    }
    case 'SecondaryWithoutBorder': {
      buttonTypeClassName = styles.secondaryWithoutBorder;
      idType = 'secondaryWithoutBorder';
      break;
    }
    case 'PrimaryWithoutBorder': {
      buttonTypeClassName = styles.primaryWithoutBorder;
      idType = 'primaryWithoutBorder';
      break;
    }
    case 'Primary': {
      buttonTypeClassName = styles.primary;
      idType = 'primary';
      break;
    }
    case 'BorderPrimary': {
      buttonTypeClassName = styles.borderPrimary;
      idType = 'borderPrimary';
      break;
    }
    case 'BorderAlert': {
      buttonTypeClassName = styles.borderAlert;
      idType = 'borderAlert';
      break;
    }
    case 'BorderPrimaryDashed': {
      buttonTypeClassName = styles.borderPrimaryDashed;
      idType = 'borderPrimaryDashed';
      break;
    }
    case 'BorderSecondaryDashed': {
      buttonTypeClassName = styles.borderSecondaryDashed;
      idType = 'borderSecondaryDashed';
      break;
    }
    case 'LightGray': {
      buttonTypeClassName = styles.lightGray;
      idType = 'lightGray';
      break;
    }
    case 'Background': {
      buttonTypeClassName = styles.background;
      idType = 'background';
      break;
    }
    default: {
      buttonTypeClassName = styles.primary;
      idType = 'primary';
    }
  }

  const style = {
    width,
    height,
    ...styleInline,
  };

  return (
    <button
      data-testid={dataTestid}
      style={style}
      className={`${styles[theme]} ${styles.button} ${buttonTypeClassName} ${noBorder} ${className} ${styleDisabled}`}
      onClick={onClick}
      disabled={disabled}
      type={typeHtml}
      id={id || idType}
    >
      {props.children}
    </button>
  );
};

export default Button;
