import { Translations } from 'hooks/Intl';

const question1 = [
  'Information about self-care techniques for common symptoms.',
  'Informações sobre técnicas de autocuidado para sintomas comuns.',
  'Información sobre técnicas de autocuidado para síntomas comunes.',
];

const question2 = [
  'Health information and research about chronic conditions.',
  'Informações de saúde e pesquisas sobre condições crônicas.',
  'Información de salud e investigación sobre condiciones crónicas.',
];

const question3 = [
  'Recently diagnosed medical condition.',
  'Condição médica recentemente diagnosticada.',
  'Condición médica recientemente diagnosticada.',
];

const question4 = [
  'Information about prescription and over the counter medication.',
  'Informações sobre medicamentos prescritos e de venda livre.',
  'Información sobre medicamentos recetados y de venta libre.',
];

export const question1COVID = [
  'Which are the COVID-19 most common symptoms?',
  'Quais são os sintomas mais comuns do COVID-19?',
  '¿Cuáles son los síntomas más comunes de COVID-19?',
];

export const question2COVID = [
  'How long is the incubation of COVID 19,  and which are the complications related?',
  'Quanto tempo dura a incu]ação do COVID-19 e quais são as complicações relacionadas?',
  '¿Cuánto dura la incubación de COVID-19 y cuáles son las complicaciones relacionadas?',
];

export const question3COVID = [
  'Which are the protective measures and best practices?',
  'Quais são as medidas protetivas e as melhores práticas?',
  '¿Cuáles son las medidas de protección y las mejores prácticas?',
];

export const question4COVID = [
  'Other questions',
  'Outras perguntas',
  'Otras preguntas',
];

export const getTranslateCovidQuestion = (
  questions: string[] | undefined,
  intl: Translations<string>
) => {
  if (!questions) {
    return [];
  }
  return questions.map(question => {
    if (question1.includes(question)) {
      return `${intl.TEXT_QUESTIONS_1} <br/>`;
    } else if (question2.includes(question)) {
      return `${intl.TEXT_QUESTIONS_2} <br/>`;
    } else if (question3.includes(question)) {
      return `${intl.TEXT_QUESTIONS_3} <br/>`;
    } else if (question4.includes(question)) {
      return `${intl.TEXT_QUESTIONS_4} <br/>`;
    } else if (question1COVID.includes(question)) {
      return `${intl.TEXT_QUESTIONS_COVID_1} <br/>`;
    } else if (question2COVID.includes(question)) {
      return `${intl.TEXT_QUESTIONS_COVID_2} <br/>`;
    } else if (question3COVID.includes(question)) {
      return `${intl.TEXT_QUESTIONS_COVID_3} <br/>`;
    } else if (question4COVID.includes(question)) {
      return `${intl.TEXT_QUESTIONS_COVID_OTHER} <br/>`;
    } else {
      return '-';
    }
  });
};
