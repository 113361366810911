export const UPDATE_SUCCESS = {
  en_US: 'Parameters successfully updated!',
  pt_BR: 'Parâmetros atualizados com sucesso!',
  es_ES: '¡Los parámetros se actualizaron correctamente!',
};

export const TEXT_ERROR = {
  en_US: 'The operation could not be completed.',
  pt_BR: 'Não foi possível concluir a operação.',
  es_ES: 'No se pudo completar la operación.',
};

export const LABEL_NURSE_AVAILABILYT_MON_FRI = {
  en_US: 'Starting at 7am',
  pt_BR: 'A partir das 7am',
  es_ES: 'A partir de las 7 a.m.',
};

export const LABEL_NURSE_AVAILABILYT_MON_FRI_07_15 = {
  en_US: 'Starting at 7:15am',
  pt_BR: 'A partir das 7:15am',
  es_ES: 'A partir de las 7:15 a.m.',
};

export const TEXT_WORKING_DAYS = {
  en_US: 'Working days (from Monday to Friday until 7pm)',
  pt_BR: 'Dias úteis (de segunda a sexta até 7pm)',
  es_ES: 'Días hábiles (de lunes a Viernes hasta las 7 p.m.)',
};
