export const TEXT_EMPTY = {
  en_US: 'You currently have no appointments.',
  pt_BR: 'No momento, você não tem nenhum agendamento.',
  es_ES: 'Actualmente no tienes citas.',
};

export const TEXT_VIEW_MORE = {
  en_US: 'View more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};

export const TEXT_DOWNLOAD = {
  en_US: 'Download',
  pt_BR: 'Baixar',
  es_ES: 'Descargar',
};

export const TEXT_PRINT = {
  en_US: 'Print',
  pt_BR: 'Imprimir',
  es_ES: 'Imprimir',
};

export const TEXT_DATE = {
  en_US: 'Date',
  pt_BR: 'Data',
  es_ES: 'Fecha',
};

export const TEXT_TIME = {
  en_US: 'Time',
  pt_BR: 'Horário',
  es_ES: 'Hora',
};

export const TEXT_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const TEXT_STATUS = {
  en_US: 'Status',
  pt_BR: 'Status',
  es_ES: 'Situación',
};

export const TEXT_PHONE = {
  en_US: 'Phone',
  pt_BR: 'Telefone',
  es_ES: 'Teléfono',
};

export const TEXT_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo electrónico',
};

export const TEXT_COUNTRY = {
  en_US: 'Country (State - City)',
  pt_BR: 'País (Estado - Cidade)',
  es_ES: 'País (Estado - Ciudad)',
};

export const TEXT_LANGUAGE = {
  en_US: 'Language',
  pt_BR: 'Idioma',
  es_ES: 'Idioma',
};

export const TEXT_QUESTIONS_COVID = {
  en_US: 'Questions',
  pt_BR: 'Perguntas',
  es_ES: 'Preguntas',
};

export const TEXT_INFORMATION_NOTES = {
  en_US: 'Additional comment',
  pt_BR: 'Comentário adicional',
  es_ES: 'Comentario adicional',
};

export const TEXT_PENDING = {
  en_US: 'Pending',
  pt_BR: 'Pendente',
  es_ES: 'Pendiente',
};

export const TEXT_CANCELED = {
  en_US: 'Canceled',
  pt_BR: 'Cancelado',
  es_ES: 'Cancelado',
};

export const TEXT_DONE = {
  en_US: 'Done',
  pt_BR: 'Realizado',
  es_ES: 'Hecho',
};

export const TEXT_NOT_DONE = {
  en_US: 'Not done',
  pt_BR: 'Não realizado',
  es_ES: 'No hecho',
};

export const TEXT_CONFIRM = {
  en_US: 'Confirm',
  pt_BR: 'Confirmar',
  es_ES: 'Confirmar',
};

export const TEXT_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};
