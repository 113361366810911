export const removeNonNumeric = (value: string) =>
  value ? value.replace(/\D/g, '') : '';

export const applyDateMask = (value: string) => {
  const numbers = removeNonNumeric(value);
  const a = numbers.replace(/(\d{2})(\d{1})/, '$1/$2');
  const b = a.replace(/(\d{2})\/(\d{2})(\d{1})/, '$1/$2/$3');
  return b;
};

export const removeRequiredCharLabel = (value: string) => {
  return value && value.slice(-1) === '*'
    ? value.substring(0, value.length - 1)
    : value;
};

export const capitalizeFirstChar = (word: string) =>
  word[0].toUpperCase() + word.substring(1);

export const capitalize = (string: string) => {
  const words = string.split(' ');
  return words.map(capitalizeFirstChar).join(' ');
};
