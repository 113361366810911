import React, { PropsWithChildren } from 'react';
import InfoLabelValue from 'components/InfoLabelValue';
import styles from '../index.module.scss';
import { useModal } from 'hooks/Modal';
import NurseAvailabilityForm from './NurseAvailabilityForm/NurseAvailabilityForm';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import ButtonWithIcon from 'components/ButtonWithIcon/ButtonWithIcon';
import EditIcon from 'assets/icons/EditIcon';
import Typography from 'components/Typography';

interface Props extends PropsWithChildren {
  nurse_availability: NurseAvailability | undefined;
  schedules_allowed: SchedulesRules | undefined;
  reloadPage: VoidFunction;
  partner_id: string;
}

const NurseAvailabilityArea = ({
  nurse_availability,
  reloadPage,
  partner_id,
  schedules_allowed,
}: Props) => {
  const { actions } = useModal();
  const { translate } = useIntl();

  const intl = translate(translations);

  const editNurseAvailability = async () => {
    actions.showModal(
      intl.TEXT_EDIT_NURSE_AVAILABLE,
      <NurseAvailabilityForm
        currentNurseAvailability={nurse_availability}
        current_schedules_allowed={schedules_allowed}
        reloadPage={reloadPage}
        partner_id={partner_id}
      />,
      true
    );
  };

  if (!nurse_availability) {
    return <></>;
  }

  return (
    <div className={styles.subContainer}>
      <Typography color="primary" type="h3" weight="light">
        {intl.TITLE_NURSE_AVAILABILITY}
      </Typography>

      <div className="row">
        <div className="row">
          <InfoLabelValue
            label={intl.LABEL_NURSE_AVAILABILYT_MON_FRI}
            value={String(nurse_availability?.weekdays_7_00)}
            customStyle="col-12 col-md-6"
          />
          <InfoLabelValue
            label={intl.LABEL_NURSE_AVAILABILYT_MON_FRI_07_15}
            value={String(nurse_availability?.weekdays_7_15)}
            customStyle="col-12 col-md-6"
          />
          <InfoLabelValue
            label={intl.TEXT_WORKING_DAYS}
            value={String(nurse_availability?.weekends_and_nights)}
            customStyle="col-12 col-md-6"
          />
        </div>
        <div className="row">
          <ButtonWithIcon
            Icon={EditIcon}
            label={intl.BTN_EDIT}
            onClick={editNurseAvailability}
          />
        </div>
      </div>
    </div>
  );
};

export default NurseAvailabilityArea;
