import React from 'react';
import Accordion from 'components/Accordion';
import styles from './ExceptionItem.module.scss';
import InfoLabelValue from 'components/InfoLabelValue';
import { settingsService } from 'services';
import { useModal } from 'hooks/Modal';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import ExceptionForm, { TypeForm } from '../ExceptionForm/ExceptionForm';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import { formatDateByIdiom, stringToDateWithoutTimezone } from 'utils/date';
import { useTheme } from 'hooks/theme';
import ButtonWithIcon from 'components/ButtonWithIcon/ButtonWithIcon';
import EditIcon from '../../../../assets/icons/EditIcon';
import TrashIcon from 'assets/icons/TrashIcon';

interface Props {
  exception: ExceptionsConfig;
  reloadPage: VoidFunction;
  partner_id: string;
}

const ExceptionItem = ({ exception, reloadPage, partner_id }: Props) => {
  const { actions } = useModal();
  const { translate, idiom } = useIntl();
  const { theme } = useTheme();

  const intl = translate(translations);

  const dateFormated = formatDateByIdiom(
    stringToDateWithoutTimezone(exception?.affected_date),
    idiom
  );

  const editException = async () => {
    actions.showModal(
      intl.EDIT_HOLIDAYS_EXCEPTIONS,
      <ExceptionForm
        type={TypeForm.UPDATE}
        reloadPage={reloadPage}
        exception={exception}
        partner_id={partner_id}
      />,
      true
    );
  };

  const confirmDelete = () => {
    actions.showModal(
      '',
      <StyledModal
        type={ModalType.ERROR}
        title={intl.DELETE_EXCEPTION_TITLE}
        subtitle={intl.DELETE_EXCEPTION_SUBTITLE}
        onClickConfirm={deleteException}
        buttonConfirmType="BorderAlert"
        textConfirm={intl.TEXT_YES_I_DO}
        textDeny={intl.TEXT_NO}
      />,
      true
    );
  };

  const deleteException = async () => {
    actions.showLoading();
    try {
      await settingsService.deleteException(partner_id, exception.exception_id);
      actions.showModal(
        '',
        <StyledModal type={ModalType.SUCCESS} title={intl.DELETED_SUCCESS} />,
        false
      );
      reloadPage();
    } catch (error) {
      actions.hideLoading();
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
  };

  return (
    <div className={styles.itemContainer}>
      <Accordion title={dateFormated}>
        <div className="row">
          <InfoLabelValue
            customStyle="col-12 col-md-6 col-lg-3"
            label={intl.LABEL_NORMAL_PERIOD}
            value={String(exception.nurses_available.weekdays_7_00).toString()}
          />
          <InfoLabelValue
            customStyle="col-12 col-md-6 col-lg-3"
            label={intl.LABEL_NURSE_AVAILABILYT_MON_FRI_07_15}
            value={String(exception.nurses_available.weekdays_7_15).toString()}
          />
          <InfoLabelValue
            customStyle="col-12 col-md-6 col-lg-3"
            label={intl.LABEL_AFTER_PERIOD}
            value={String(
              exception.nurses_available.weekends_and_nights
            ).toString()}
          />
        </div>

        <div className={`${styles.footer} ${styles[theme]}`}>
          <ButtonWithIcon
            label={intl.BTN_EDIT}
            onClick={editException}
            Icon={EditIcon}
          />
          <ButtonWithIcon
            label={intl.BTN_REMOVE}
            onClick={confirmDelete}
            Icon={TrashIcon}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default ExceptionItem;
