import React, { PropsWithChildren } from 'react';
import styles from './HalfBlue.module.scss';
import { useTheme } from 'hooks/theme';
import logo from '../../assets/images/logoNAL-1.svg';

const LayoutHalfBlue = (props: PropsWithChildren) => {
  const { theme } = useTheme();

  return (
    <div className={styles.container}>
      <nav className={styles.header}>
        <img src={logo} className={styles.logo} />
      </nav>

      <div className={styles.body}>
        <div className={styles.left}>{props.children}</div>
        <div className={`${styles.rightBlue} ${styles[theme]}`}></div>
      </div>
    </div>
  );
};

export default LayoutHalfBlue;
