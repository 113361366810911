export const TEXT_DATE = {
  en_US: 'Date',
  pt_BR: 'Data',
  es_ES: 'Fecha',
};

export const TEXT_TIME = {
  en_US: 'Time',
  pt_BR: 'Horário',
  es_ES: 'Hora',
};

export const TEXT_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const TEXT_STATUS = {
  en_US: 'Status',
  pt_BR: 'Status',
  es_ES: 'Situación',
};

export const TEXT_PHONE = {
  en_US: 'Phone',
  pt_BR: 'Telefone',
  es_ES: 'Teléfono',
};

export const TEXT_LANGUAGE = {
  en_US: 'Language',
  pt_BR: 'Idioma',
  es_ES: 'Idioma',
};

export const TEXT_PENDING = {
  en_US: 'Pending',
  pt_BR: 'Pendente',
  es_ES: 'Pendiente',
};

export const TEXT_CANCELED = {
  en_US: 'Canceled',
  pt_BR: 'Cancelado',
  es_ES: 'Cancelado',
};

export const TEXT_DONE = {
  en_US: 'Done',
  pt_BR: 'Realizado',
  es_ES: 'Hecho',
};

export const TEXT_NOT_DONE = {
  en_US: 'Not done',
  pt_BR: 'Não realizado',
  es_ES: 'No hecho',
};

export const TEXT_CONFIRM = {
  en_US: 'Confirm',
  pt_BR: 'Confirmar',
  es_ES: 'Confirmar',
};

export const TEXT_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};
