import { ReactNode } from 'react';
import styles from './index.module.scss';
import Typography from '../Typography';
import * as translations from 'intls/forms';
import { useIntl } from 'hooks/Intl';
import Button, { ButtonTypes } from 'components/Button';
import { useTheme } from 'hooks/theme';

interface FormStepWrapperProps {
  title: string;
  children?: ReactNode;
  onNext: () => void;
  nextButtonType?: ButtonTypes;
  nextButtonLabel?: string;
  nextButtonDisabled?: boolean;
  onPrevious?: () => void;
  showRequiredDisclaimer?: boolean;
}

const FormStepWrapper = ({
  title,
  children,
  onNext,
  nextButtonType = 'Primary',
  nextButtonLabel,
  nextButtonDisabled = false,
  onPrevious,
  showRequiredDisclaimer = true,
}: FormStepWrapperProps) => {
  const { theme } = useTheme();

  const { translate } = useIntl();
  const intl = translate(translations);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Typography type="h5" weight="regular" color="primary">
          {title}
        </Typography>
      </div>
      <div className={`${styles.containerForm} ${styles[theme]}`}>
        {showRequiredDisclaimer && (
          <Typography
            type="p"
            weight="light"
            className={styles.mandatoryDisclaimer}
          >
            {intl.MANDATORY_FIELDS}
          </Typography>
        )}
        <div className="row">{children}</div>
        <div className={`${styles.footer} ${styles[theme]}`}>
          <div
            data-testid="btnBack"
            className={`${styles.divButton} ${
              !onPrevious && styles.disabledButtonContainer
            }`}
          >
            <Button
              type="BorderSecondary"
              onClick={onPrevious || (() => null)}
              disabled={!onPrevious}
            >
              {intl.BACK}
            </Button>
          </div>
          <div data-testid="btnNext" className={styles.divButton}>
            <Button
              type={nextButtonType}
              onClick={onNext}
              disabled={nextButtonDisabled}
            >
              {nextButtonLabel || intl.NEXT}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStepWrapper;
