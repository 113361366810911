import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './ButtonProfile.module.scss';
import * as translations from './intl';
import { useTheme } from '../../hooks/theme';
import { useIntl } from '../../hooks/Intl';
import { useUser } from '../../hooks/User';
import ProfileEnum from 'utils/profile-enum';
import ProfileIcon from 'assets/icons/ProfileIcon';
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import SettingsIcons from 'assets/icons/SettingsIcons';
import { useAuth } from 'hooks/Auth';

const ButtonProfile = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useUser();
  const { userAuth } = useAuth();
  const { translate } = useIntl();
  const intl = translate(translations);
  const { theme } = useTheme();

  const menuRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const profiles = user?.user?.affiliations?.map(
    affiliation => affiliation.role
  );

  const openMenu = () => {
    setShowMenu(true);
  };

  const closeMenu = (event: any) => {
    const containMenu =
      menuRef.current && menuRef.current.contains(event.target);
    const containButton =
      buttonRef.current && buttonRef.current.contains(event.target);

    if (!containMenu) {
      if (containButton) {
        event.stopPropagation();
      } else {
        setShowMenu(false);
      }
    }
  };

  useEffect(() => {
    if (showMenu) document.body.addEventListener('click', closeMenu);
    else document.body.removeEventListener('click', closeMenu);

    return () => {
      document.body.removeEventListener('click', closeMenu);
    };
  }, [showMenu]);

  return (
    <div id="buttonProfile-headerOption" className={styles.boxProfile}>
      <div
        className={`${styles.buttonProfile} ${styles[theme]}`}
        onClick={openMenu}
        ref={buttonRef}
      >
        <h3 className={`${styles[theme]} ${styles.buttonProfile}`}>
          {user?.user && user?.user?.first_name
            ? user?.user?.first_name?.charAt(0)
            : userAuth?.userInfo?.email
            ? userAuth?.userInfo?.email.charAt(0)
            : 'U'}
        </h3>
      </div>

      {showMenu && (
        <ul
          className={`${styles[theme]} ${styles.subMenuEditProfile}`}
          data-testid="menu"
          ref={menuRef}
        >
          {!user.error && (
            <li className={styles[theme]} data-testid="profile">
              <span className={styles.icon}>
                <ProfileIcon className={`${styles[theme]}`} />
              </span>
              <Link
                className={`${styles[theme]} ${styles.textOption}`}
                onClick={() => setShowMenu(false)}
                to="/profile"
              >
                {intl.TEXT_PROFILE}
              </Link>
            </li>
          )}

          {profiles?.includes(ProfileEnum.ADMINISTRATOR) && (
            <li className={styles[theme]} data-testid="settings">
              <span className={styles.icon}>
                <SettingsIcons className={`${styles[theme]}`} />
              </span>
              <Link
                className={`${styles[theme]} ${styles.textOption}`}
                onClick={() => setShowMenu(false)}
                to="/admin"
              >
                {intl.SETTINGS}
              </Link>
            </li>
          )}

          <li className={styles[theme]} data-testid="signout">
            <span className={styles.icon}>
              <ArrowLeftIcon width={25} height={25} aria-label="Signout" />
            </span>
            <Link
              className={`${styles[theme]} ${styles.textOption}`}
              onClick={() => setShowMenu(false)}
              to="/logout"
            >
              {intl.TEXT_LOG_OUT}
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ButtonProfile;
