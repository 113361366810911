import React from 'react';
import PersonalArea from 'pages/CompleteRegistration/Form/PersonalArea';
import PhoneArea from 'pages/CompleteRegistration/Form/PhoneArea';
import { useFormik } from 'formik';
import { useModal } from '../../../../hooks/Modal/index';
import { getInitialValuesToUpdate } from 'pages/CompleteRegistration/initialValues';
import { getValidationSchema } from 'pages/CompleteRegistration/validationSchema';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import { useIntl } from 'hooks/Intl';
import { formsTranslations } from 'intls';
import { useUser } from 'hooks/User';
import { formatUpdate } from '../../../CompleteRegistration/submit';
import { userService } from 'services';
import * as translations from './intl';
import FooterButtons from 'components/FooterButtons/FooterButtons';

const EditPersonalInformation = () => {
  const { actions } = useModal();
  const { translate, idiom } = useIntl();
  const { user, loadUser } = useUser();

  const formsIntl = translate(formsTranslations);
  const intl = translate(translations);

  const formik = useFormik({
    initialValues: getInitialValuesToUpdate(idiom, user.user),
    validationSchema: getValidationSchema(formsIntl),
    onSubmit: async values => {
      try {
        const body = formatUpdate(values, idiom);
        actions.showLoading(intl.UPDATING_DATA);

        await userService.update(user?.user?.person_id as string, body);
        loadUser();

        actions.showModal(
          '',
          <StyledModal
            title={intl.CONGRATULATIONS}
            subtitle={intl.UPDATE_COMPLETE}
            type={ModalType.SUCCESS}
            onClickConfirm={() => {
              actions.closeModal();
            }}
          />,
          false
        );
      } catch (error) {
        console.log({ error });
        actions.showModal(
          '',
          <StyledModal title={intl.UNEXPECTED_ERROR} type={ModalType.ERROR} />,
          false
        );
      }
    },
  });

  return (
    <div>
      <div className="row">
        <PersonalArea formikProps={formik} />
        <PhoneArea formikProps={formik} />
      </div>
      <div className="row">
        <FooterButtons onClickButton={formik.handleSubmit} />
      </div>
    </div>
  );
};

export default EditPersonalInformation;
