import { useEffect, useState, useRef } from 'react';
import Button from 'components/Button';
import { useIntl } from 'hooks/Intl';
import { useReactToPrint } from 'react-to-print';
import AppointmentItem from './AppointmentItem';
import styles from './index.module.scss';
import * as translations from './intl';
import ComponentToPrint from './ComponentToPrint/ComponentToPrint';
import { appointmentsService } from 'services';
import { buildCSV } from './buildCSV';
import Typography from 'components/Typography';

interface IAppointmentsTableProps {
  appointments: Appointment[];
  showLoadMore: boolean;
  addMore: () => Promise<void>;
}
const AppointmentsTable = ({
  appointments,
  showLoadMore,
  addMore,
}: IAppointmentsTableProps) => {
  const { translate, idiom } = useIntl();
  const [appointmentsDetail, setAppointmentsDetail] = useState<
    AppointmentDetail[]
  >([]);
  const intl = translate(translations);

  const refTable = useRef(null);
  const hiddenPrintButton = useRef<HTMLButtonElement>(null);

  const listAppointments = appointments.map((appointment, index) => {
    return <AppointmentItem appointment={appointment} key={index} />;
  });

  const loadAppointmentsDetail = async () => {
    try {
      const promises =
        appointments?.map(appointment =>
          appointmentsService.getDetail(
            appointment?.appointment_details?.appointment_id
          )
        ) || [];

      const appointmentsDetails = await Promise.all(promises).then(
        axiosResponses => axiosResponses.map(response => response.data)
      );

      setAppointmentsDetail(appointmentsDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => refTable.current,
    documentTitle: `appointments-${new Date().getTime()}`,
  });

  useEffect(() => {
    if (appointmentsDetail.length > 0) {
      hiddenPrintButton.current?.click();
    }
  }, [appointmentsDetail]);

  if (!appointments) return null;

  return (
    <div className={styles.container} data-testid="appointment-table">
      {appointments && appointments?.length === 0 ? (
        <div className={styles.msgArea}>
          {
            <Typography color="subtitle" type="label">
              {intl.TEXT_EMPTY}
            </Typography>
          }
        </div>
      ) : (
        <>
          <div>{listAppointments}</div>
          {appointments?.length > 0 && showLoadMore && (
            <div className={styles.buttonViewMoreArea}>
              <Button type="PrimaryWithoutBorder" onClick={addMore}>
                {intl.TEXT_VIEW_MORE}
              </Button>
            </div>
          )}

          <ComponentToPrint
            refTable={refTable}
            appointmentsDetail={appointmentsDetail}
          />

          <div className={styles.btnArea}>
            <Button type="BorderPrimary" onClick={loadAppointmentsDetail}>
              {intl.TEXT_PRINT}
            </Button>
            <button
              style={{ display: 'none' }}
              onClick={handlePrint}
              ref={hiddenPrintButton}
            ></button>

            <Button
              type="BorderPrimary"
              onClick={() => buildCSV(appointments, idiom, intl)}
            >
              {intl.TEXT_DOWNLOAD}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AppointmentsTable;
