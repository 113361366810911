import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import { useTheme } from 'hooks/theme';
import Button from 'components/Button';
import {
  getStatus,
  STATUS_DONE,
  STATUS_NOT_DONE,
  STATUS_PENDING,
} from 'utils/status';
import { listIdioms } from 'utils/intl/idioms';
import { listPhones } from 'utils/intl/phones';
import InfoLabelValue from 'components/InfoLabelValue';
import StatusLabel from 'components/Info';
import { useModal } from 'hooks/Modal';
import { formatDateByIdiom, stringToDateWithoutTimezone } from 'utils/date';
import { appointmentsService } from 'services';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Input from 'components/Input';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getTranslateCovidQuestion } from './utils';
import { listTimeZones } from 'intls/timezones';
import { AppointmentStatus } from 'utils/category';

interface IAppointmentItemProps {
  appointment: Appointment;
}

interface IForm {
  comments: string;
}

const AppointmentItem = ({ appointment }: IAppointmentItemProps) => {
  const { patient_info } = appointment;

  const [appointmentDetail, setAppointmentDetail] =
    useState<AppointmentDetail>();
  const [moreInformation, setMoreInformation] = useState(false);
  const [loading, setLoading] = useState(true);

  const { idiom, translate } = useIntl();
  const { actions } = useModal();
  const { theme } = useTheme();
  const intl = translate(translations);
  const formatDate = formatDateByIdiom(
    stringToDateWithoutTimezone(
      appointment?.appointment_details?.reference_date
    ),
    idiom
  );
  const formatTime = `${appointment?.appointment_details?.reference_date?.substring(
    11,
    16
  )}`;
  const statusFormat = getStatus(appointment?.appointment_details?.status);

  const showModalToCancel = async () => {
    actions.showModal(
      '',
      <StyledModal
        type={ModalType.ERROR}
        title={intl.MODAL_TITLE_CONFIRMATION_CANCEL_APPOINTMENT}
        subtitle={intl.MODAL_TEXT_CONFIRMATION_CANCEL_APPOINTMENT}
        textConfirm={intl.TEXT_YES_I_DO}
        buttonConfirmType="BorderAlert"
        onClickConfirm={async () => {
          try {
            actions.showLoading();
            await appointmentsService.conclude(
              appointment?.appointment_details?.appointment_id,
              '',
              AppointmentStatus.NOT_DONE
            );
            actions.showModal(
              '',
              <StyledModal
                type={ModalType.SUCCESS}
                title={intl.TEXT_SUCCESS_CANCEL}
              />,
              false
            );
            await loadAppointmentDetail();
            appointment.appointment_details.status = STATUS_NOT_DONE;
          } catch (err) {
            actions.showModal(
              '',
              <StyledModal
                type={ModalType.ERROR}
                title={intl.TEXT_ERROR_CANCEL}
              />,
              false
            );
          }
        }}
        textDeny={intl.TEXT_NO}
        onClickDeny={() => {
          actions.closeModal();
        }}
      />,
      true
    );
  };

  const confirm = async (values: IForm) => {
    actions.showLoading();
    try {
      await appointmentsService.conclude(
        appointment?.appointment_details?.appointment_id,
        values.comments,
        AppointmentStatus.DONE
      );
      actions.showModal(
        '',
        <StyledModal
          type={ModalType.SUCCESS}
          title={intl.TEXT_SUCCESS_CONFIRM}
        />,
        false
      );
      await loadAppointmentDetail();
      appointment.appointment_details.status = STATUS_DONE;
    } catch (err) {
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR_CONFIRM} />,
        false
      );
    }
  };

  const showModalToConfirm = async () => {
    actions.showModal(
      intl.MODAL_TITLE_CONFIRMATION_CONFIRM_APPOINTMENT,
      <Formik
        initialValues={{ comments: '' }}
        validationSchema={Yup.object().shape({
          comments: Yup.string().notRequired(),
        })}
        onSubmit={confirm}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Input
              label={intl.LABEL_COMMENTS}
              name="comments"
              className="col-12"
              formikProps={props}
            />
            <div className={styles.modalFooter}>
              <div className={styles.wrapperButton}>
                <Button
                  type="PrimaryWithoutBorder"
                  typeHtml="button"
                  onClick={actions.closeModal}
                >
                  {intl.TEXT_NO_CONFIRMATION}
                </Button>
              </div>
              <div className={styles.wrapperButton}>
                <Button
                  type="Secondary"
                  typeHtml="submit"
                  onClick={props.handleSubmit}
                >
                  {intl.TEXT_YES_CONFIRMATION}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>,
      true,
      false,
      undefined,
      false
    );
  };

  const loadAppointmentDetail = async () => {
    try {
      setLoading(true);
      const response = await appointmentsService.getDetail(
        appointment?.appointment_details?.appointment_id
      );
      setAppointmentDetail(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const _onClick = () => {
    if (moreInformation) {
      setMoreInformation(false);
    } else {
      setMoreInformation(true);
      if (!appointmentDetail) {
        loadAppointmentDetail();
      }
    }
  };

  const aptmentIdiom =
    appointmentDetail &&
    listIdioms.find(
      idiom =>
        idiom.initials === appointmentDetail?.patient_info?.preferred_language
    );
  const phoneInformations = `${patient_info?.phone?.international_prefix} ${patient_info?.phone?.number}`;
  const textPhone = listPhones.find(
    phoneItem =>
      phoneItem.initialEN === patient_info?.phone?.phone_type ||
      phoneItem.initialES === patient_info?.phone?.phone_type ||
      phoneItem.initialPT === patient_info?.phone?.phone_type
  );

  const translateQuestions = getTranslateCovidQuestion(
    appointmentDetail?.appointment_details?.initial_questions,
    intl
  );
  const formattedQuestions = translateQuestions.join('');

  const translateTimezone = listTimeZones.find(
    timezone =>
      timezone.timezone === appointmentDetail?.appointment_details?.timezone
  );

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.accordeonHeader} ${styles[theme]}`}>
        <div className={styles.boxNames}>
          <span
            className={`${styles.text} ${styles[theme]} ${styles.boldText}`}
          >{`${formatDate} - ${formatTime}`}</span>
          <span
            className={`${styles.text} ${styles[theme]}`}
          >{`${patient_info.first_name} ${patient_info.last_name}`}</span>
        </div>

        <div className={styles.boxNames2}>
          <span className={`${styles.text} ${styles[theme]}`}>
            {`${textPhone ? textPhone[idiom] : ''} ${phoneInformations} `}
          </span>
          <span className={`${styles.text} ${styles[theme]}`}>
            <StatusLabel appointments status={statusFormat} />
          </span>
        </div>

        <div
          className={`${styles[theme]} ${styles.moreInformation}`}
          onClick={_onClick}
        >
          {moreInformation ? (
            <FontAwesomeIcon
              icon={faChevronUp}
              className={`${styles.icon} ${styles[theme]}`}
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${styles.icon} ${styles[theme]}`}
            />
          )}
        </div>
      </div>
      {moreInformation && (
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className={`${styles.content}`}>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-xl-4">
                  <InfoLabelValue
                    value={patient_info.email}
                    label={intl.TEXT_EMAIL}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-4">
                  <InfoLabelValue
                    value={translateTimezone ? translateTimezone[idiom] : '-'}
                    label={intl.TEXT_TIMEZONE}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-2">
                  <InfoLabelValue
                    value={aptmentIdiom ? aptmentIdiom[idiom] : ''}
                    label={intl.TEXT_LANGUAGE}
                  />
                </div>
              </div>
              <div className="row">
                {appointmentDetail?.appointment_details?.initial_questions && (
                  <div className="col-12">
                    <InfoLabelValue
                      value={formattedQuestions}
                      label={intl.TEXT_QUESTIONS_COVID}
                    />
                  </div>
                )}
                {appointmentDetail?.appointment_details?.additional_comment && (
                  <div className="col-12">
                    <InfoLabelValue
                      value={
                        appointmentDetail?.appointment_details
                          ?.additional_comment
                      }
                      label={intl.TEXT_INFORMATION_NOTES}
                    />
                  </div>
                )}
              </div>
              {appointmentDetail?.appointment_details?.status ===
                STATUS_PENDING && (
                <div className={`${styles[theme]} ${styles.footer}`}>
                  <Button
                    type="BorderPrimary"
                    onClick={() => {
                      showModalToConfirm();
                    }}
                  >
                    {intl.TEXT_CONFIRM}
                  </Button>

                  <Button
                    type="PrimaryWithoutBorder"
                    onClick={() => {
                      showModalToCancel();
                    }}
                  >
                    {intl.TEXT_CANCEL}
                  </Button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AppointmentItem;
