import { FormikProps } from 'formik';
import styles from './RadioButton.module.scss';
import { useTheme } from 'hooks/theme';
import { safeGet } from 'utils/objects';
import inputStyles from 'styles/input.module.scss';

interface SelectProps {
  label: string;
  name: string;
  value?: string | number;
  touched?: boolean;
  checked?: boolean;
  error?: string;
  formikProps?: FormikProps<any>;
  onChange?: FormikProps<any>['handleChange'];
  className?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
}

const RadioButton = ({
  label,
  name,
  value,
  touched,
  error,
  formikProps,
  onChange,
  checked,
  className,
  defaultChecked = false,
  disabled = false,
}: SelectProps) => {
  const { theme } = useTheme();

  if (formikProps) {
    value = safeGet<string | number>(formikProps, 'values.' + name, '');
    touched = safeGet(formikProps, 'touched.' + name, false);
    error = safeGet(formikProps, 'errors.' + name, '');
    onChange = onChange || formikProps.handleChange;
  }

  const showError = !!error && touched;

  let handledClassName = `${inputStyles.input} ${inputStyles[theme]} ${styles.input} ${styles[theme]}`;
  if (showError) handledClassName += ` ${inputStyles.error}`;
  if (value && !error) handledClassName += ` ${styles.hasValue}`;

  let containerClassName = `${inputStyles.container} ${styles.container}`;
  if (className) containerClassName += ` ${className}`;
  return (
    <label className={`${containerClassName} ${styles[theme]}`}>
      <div className={`${value ? `${styles[theme]}` : ''}`}>{label}</div>
      <input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        defaultChecked={defaultChecked}
        checked={checked}
        className={handledClassName}
        disabled={disabled}
      />
      <span
        className={` ${styles[theme]} ${styles.checkmark} ${
          disabled ? styles.disabled : ''
        }`}
      />
    </label>
  );
};

export default RadioButton;
