import { RegistrationFormValues, UpdateFormValues } from './initialValues';

export const format = (values: RegistrationFormValues, language: Language) => {
  const { first_name, last_name, email, nationality, birth_date, phone } =
    values;
  const { type, international_prefix, number } = phone;

  const body: UserCreateBody = {
    first_name,
    last_name,
    email,
    nationalities: nationality ? [nationality] : undefined,
    birth_date: birth_date || undefined,
    phone: {
      phone_type: type,
      international_prefix,
      number,
    },
    preferred_language: language,
  };

  return body;
};

export const formatUpdate = (values: UpdateFormValues, language: Language) => {
  const { first_name, last_name, nationality, birth_date, phone } = values;
  const { type, international_prefix, number } = phone;

  const body: UserUpdateBody = {
    first_name,
    last_name,
    nationalities: nationality ? [nationality] : undefined,
    birth_date: birth_date || undefined,
    phone: {
      phone_type: type,
      international_prefix,
      number,
    },
    preferred_language: language,
  };

  return body;
};
