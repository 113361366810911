import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'hooks/Intl';
import { useModal } from 'hooks/Modal';
import { formsTranslations } from 'intls';
import getInitialValues, { schedulesAllowedForm } from './initialValues';
import getValidationSchema from './validationSchema';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import Input from 'components/Input';
import { settingsService } from 'services';
import { formatScheduleAllowedToAPI } from './formatToAPI';
import * as translations from './intl';
import FooterButtons from 'components/FooterButtons/FooterButtons';

interface Props {
  schedules_allowed: SchedulesRules | undefined;
  current_nurse_availability: NurseAvailability | undefined;
  reloadPage: VoidFunction;
  partner_id: string;
}

const SchedulesForm = ({
  schedules_allowed,
  reloadPage,
  partner_id,
  current_nurse_availability,
}: Props) => {
  const { translate } = useIntl();
  const { actions } = useModal();

  const intlForm = translate(formsTranslations);
  const intl = translate(translations);

  const initialValues = useMemo(() => getInitialValues(schedules_allowed), []);
  const validationSchema = useMemo(() => getValidationSchema(intlForm), []);

  const editSchedulesAllowed = async (values: schedulesAllowedForm) => {
    try {
      actions.showLoading();

      const formatedValues = formatScheduleAllowedToAPI(values);
      await settingsService.updateRules(partner_id, {
        ...formatedValues,
        nurses_available: current_nurse_availability as NurseAvailability,
      });

      actions.hideLoading();
      actions.showModal(
        '',
        <StyledModal type={ModalType.SUCCESS} title={intl.UPDATE_SUCCESS} />,
        false
      );
      reloadPage();
    } catch (error) {
      actions.hideLoading();
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: editSchedulesAllowed,
  });

  return (
    <>
      <div className="row">
        <Input
          label={intl.LABEL_MINIMUN_DAYS_IN_ADVANCE}
          name="advance_days_cooldown"
          formikProps={formik}
          className="col-12 col-md-6"
        />
        <Input
          label={intl.LABEL_MAX_DAYS_IN_ADVANCE}
          name="schedule_period_length"
          formikProps={formik}
          className="col-12 col-md-6"
        />
      </div>
      <div className="row">
        <FooterButtons onClickButton={formik.handleSubmit} />
      </div>
    </>
  );
};

export default SchedulesForm;
