import React from 'react';
import { useIntl } from 'hooks/Intl';
import * as translations from '../intl';
import ProfileEnum from 'utils/profile-enum';
import InfoLabelValue from 'pages/Profile/InfoLabelValue/InfoLabelValue';
import Typography from 'components/Typography';

interface Props {
  user: User | null;
}

const ProfileInformation = ({ user }: Props) => {
  const { translate } = useIntl();
  const intl = translate(translations);

  const getTranslation = (name: string) => {
    if (name === ProfileEnum.ADMINISTRATOR) return intl.ADMINISTRATOR_LABEL;
    if (name === ProfileEnum.NURSE) return intl.NURSE_LABEL;

    return '-';
  };

  return (
    <>
      <Typography color="primary" type="h3" weight="light">
        {intl.YOUR_PROFILES}
      </Typography>

      <div className={`row`}>
        {user?.affiliations.map(affiliation => (
          <div className="row" key={affiliation.partner_name}>
            <div className="col-12 col-sm-2 col-md-3 col-lg-3">
              <InfoLabelValue
                label={intl.PARTNER_TEXT}
                value={affiliation.partner_name}
              />
            </div>

            <div className="col-12 col-sm-2 col-md-3 col-lg-3">
              <InfoLabelValue
                label={intl.PROFILE_TEXT}
                value={
                  user?.affiliations ? getTranslation(affiliation.role) : '-'
                }
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default ProfileInformation;
