import { useEffect } from 'react';
import Button from 'components/Button';
import Calendar from 'components/Calendar';
import AppointmentCategories from 'components/Categories/AppointmentCategories';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { format } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { useMemo, useRef, useState } from 'react';
import { appointmentsService } from 'services';
import { AppointmentStatus } from 'utils/category';
import { safeGet } from 'utils/objects';
import { useIntl } from '../../hooks/Intl';
import AppointmentsTable from './AppointmentsTable';
import styles from './index.module.scss';
import * as translations from './intl';
import validationDataSchema from './validationSchema';
import { useModal } from '../../hooks/Modal/index';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import ReactDatePicker from 'react-datepicker';
import SearchIcon from 'assets/icons/SearchIcon';
import { useUser } from 'hooks/User';

const ConsultAppointments = () => {
  const [reports, setReports] = useState<Appointment[]>([]);
  const [status, setStatus] = useState(AppointmentStatus.ALL);
  const [loading, setLoading] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const { actions } = useModal();
  const { translate } = useIntl();
  const { user } = useUser();

  const intl = translate(translations);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const ref = useRef<FormikProps<any>>(null);
  const consultation = {
    startDate: useRef<ReactDatePicker>(null),
    endDate: useRef<ReactDatePicker>(null),
  };

  const colorPrimary = '#021e4c';

  interface IValues {
    consultation: {
      startDate: Date;
      endDate: Date;
    };
  }

  const initialValues = {
    consultation: {
      startDate: new Date(),
      endDate: new Date(),
    },
  };

  const submit = async (values: IValues) => {
    setLoading(true);
    try {
      const response = await appointmentsService.getBy(
        user.user?.affiliations[0].partner_id as string,
        {
          page_index: 1,
          from_date: format(values.consultation.startDate, 'yyyy-MM-dd'),
          to_date: format(values.consultation.endDate, 'yyyy-MM-dd'),
          status: status || undefined,
        }
      );
      setReports(response.data?.records);
      setShowLoadMore(response.data?.paginator.hasNext);
      setPage(() => 2);
    } catch (error) {
      setReports([]);
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
    setLoading(false);
  };

  const filterAppointments = async (status: AppointmentStatus) => {
    setLoading(true);
    setStatus(status);

    try {
      const startDate = consultation?.startDate?.current?.props?.selected
        ? new Date(consultation?.startDate?.current?.props?.selected)
        : new Date();

      const endDate = consultation?.endDate?.current?.props?.selected
        ? new Date(consultation?.endDate?.current?.props?.selected)
        : new Date();

      const response = await appointmentsService.getBy(
        user.user?.affiliations[0].partner_id as string,
        {
          page_index: 1,
          from_date: format(startDate, 'yyyy-MM-dd'),
          to_date: format(endDate, 'yyyy-MM-dd'),
          status: status || undefined,
        }
      );
      setReports(response.data?.records);
      setShowLoadMore(response.data?.paginator.hasNext);
      setPage(() => 2);
    } catch (error) {
      setReports([]);
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
    setLoading(false);
  };

  const addMore = async () => {
    setLoading(true);
    try {
      const startDate = consultation?.startDate?.current?.props?.selected
        ? new Date(consultation?.startDate?.current?.props?.selected)
        : new Date();

      const endDate = consultation?.endDate?.current?.props?.selected
        ? new Date(consultation?.endDate?.current?.props?.selected)
        : new Date();

      const response = await appointmentsService.getBy(
        user.user?.affiliations[0].partner_id as string,
        {
          page_index: page,
          from_date: format(startDate, 'yyyy-MM-dd'),
          to_date: format(endDate, 'yyyy-MM-dd'),
          status: status || undefined,
        }
      );
      const newData = response.data?.records || [];
      setReports(prev => [...prev, ...newData]);
      setShowLoadMore(response.data?.paginator.hasNext);
      setPage(prev => prev + 1);
    } catch (error) {
      actions.showModal(
        '',
        <StyledModal type={ModalType.ERROR} title={intl.TEXT_ERROR} />,
        false
      );
    }
    setLoading(false);
  };

  const validationSchema = useMemo(() => validationDataSchema(intl), [intl]);

  useEffect(() => {
    filterAppointments(status);
  }, [showButtons]);

  return (
    <div className={styles.Container}>
      <div className={styles.filterAera}>
        <AppointmentCategories
          filterAppointments={filterAppointments}
          selected={status}
        />
        <Button
          width="auto"
          type="PrimaryWithoutBorder"
          className={styles.icon}
          onClick={() => setShowButtons(prev => !prev)}
          dataTestid="form-toggler"
        >
          <SearchIcon color={showButtons ? undefined : colorPrimary} />
        </Button>
      </div>

      {showButtons && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submit}
          innerRef={ref}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <form onSubmit={handleSubmit} role="form">
                <div className={`${styles.items} row`}>
                  <div className={`col-12 col-sm-6 col-md-4`}>
                    <Calendar
                      value={values.consultation.startDate}
                      label={intl.START_DATE}
                      name="consultation.startDate"
                      onChange={handleChange}
                      touched={safeGet(
                        touched,
                        'consultation.startDate',
                        false
                      )}
                      error={safeGet(errors, 'consultation.startDate', '')}
                      setFieldValue={setFieldValue}
                      calendarRef={consultation.startDate}
                    />
                  </div>
                  <div className={`col-12 col-sm-6 col-md-4`}>
                    <Calendar
                      value={values.consultation.endDate}
                      label={intl.END_DATE}
                      name="consultation.endDate"
                      minDate={values.consultation.startDate}
                      onChange={handleChange}
                      touched={safeGet(touched, 'consultation.endDate', false)}
                      error={safeGet(errors, 'consultation.endDate', '')}
                      setFieldValue={setFieldValue}
                      calendarRef={consultation.endDate}
                    />
                  </div>
                  <div
                    className={`col-12 col-sm-12 col-md-4`}
                    style={{ paddingBottom: '1rem' }}
                  >
                    <Button
                      onClick={() => null}
                      type="BorderPrimary"
                      typeHtml="submit"
                    >
                      {intl.BTN_SEARCH}
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      )}

      {loading ? (
        <LoadingSpinner />
      ) : (
        <AppointmentsTable
          appointments={reports}
          showLoadMore={showLoadMore}
          addMore={addMore}
        />
      )}
    </div>
  );
};

export default ConsultAppointments;
