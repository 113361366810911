import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import styles from './index.module.scss';
import { useTheme } from 'hooks/theme';
import Typography from 'components/Typography';

const statusConfigDocument = (intl: any): Record<string, any> => ({
  REVIEW: {
    text: intl.STATUS_UPLOADED,
    color: 'const_green',
  },
  COMPLETED: {
    text: intl.STATUS_COMPLETED,
    color: 'const_green',
  },
  CLOSED: {
    text: intl.STATUS_CLOSED,
    color: 'text',
  },
  REJECTED: {
    text: intl.STATUS_REJECTED,
    color: 'alert',
  },
  PENDING_DOCUMENTATION: {
    text: intl.STATUS_PENDING_DOCUMENTATION,
    color: 'const_orange',
  },
  PENDING: {
    text: intl.STATUS_PENDING,
    color: 'const_orange',
  },
});

const statusConfig = (intl: any): Record<string, any> => ({
  REVIEW: {
    text: intl.STATUS_REVIEW,
    color: 'gray',
  },
  REVIEW_BY_UNDERWRITER: {
    text: intl.STATUS_REVIEW_UNDERWRITER,
    color: 'gray',
  },
  COMPLETED: {
    text: intl.STATUS_COMPLETED,
    color: 'const_green',
  },
  CLOSED: {
    text: intl.STATUS_CLOSED,
    color: 'text',
  },
  CLOSED_DUPLICATED: {
    text: intl.STATUS_CLOSED_DUPLICATED,
    color: 'text',
  },
  REJECTED: {
    text: intl.STATUS_REJECTED,
    color: 'alert',
  },
  PENDING_DOCUMENTATION: {
    text: intl.STATUS_PENDING_DOCUMENTATION,
    color: 'const_orange',
  },
  PENDING: {
    text: intl.STATUS_PENDING,
    color: 'const_orange',
  },
  CREATED: {
    text: intl.TEXT_CREATED,
    color: 'const_orange',
  },
  ONGOING: {
    text: intl.TEXT_ONGOING,
    color: 'const_green',
  },
  EXPIRED: {
    text: intl.TEXT_EXPIRED,
    color: 'alert',
  },
  CANCELED: {
    text: intl.TEXT_CANCELED,
    color: 'text',
  },
  CANCELLED: {
    text: intl.TEXT_CANCELED,
    color: 'text',
  },
  WAITING_FOR_PAYMENT: {
    text: intl.TEXT_CREATED,
    color: 'const_orange',
  },
  PAYMENT_REFUSED: {
    text: intl.PAYMENT_REFUSED,
    color: 'alert',
  },
  SUSPENDED: {
    text: intl.SUSPENDED,
    color: 'gray',
  },
});

const statusAppointments = (intl: any): Record<string, any> => ({
  CANCELED: {
    text: intl.TEXT_CANCELED,
    className: 'alert',
  },
  CANCELLED: {
    text: intl.TEXT_CANCELED,
    className: 'alert',
  },
  DONE: {
    text: intl.TEXT_DONE,
    className: 'const_green',
  },
  NOT_DONE: {
    text: intl.TEXT_NOT_DONE,
    className: 'subtitle',
  },
  PENDING: {
    text: intl.TEXT_PENDIND,
    className: 'base_gray',
  },
});

const typeConfig = (
  intl: any,
  document: any,
  appointments: any
): Record<string, any> => {
  if (document) {
    return statusConfigDocument(intl);
  } else if (appointments) {
    return statusAppointments(intl);
  } else {
    return statusConfig(intl);
  }
};

interface StatusLabelProps {
  status: string;
  document?: any;
  appointments?: any;
}

const StatusLabel = ({ status, document, appointments }: StatusLabelProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);
  const config = typeConfig(intl, document, appointments);
  const { theme } = useTheme();

  const statusObject = config[status];
  if (statusObject === undefined) {
    return <></>;
  }

  return (
    <div
      className={`${styles.container} ${styles[theme]} ${
        styles[statusObject.className]
      } `}
      style={{ backgroundColor: statusObject.color }}
    >
      <Typography type="span" weight="semibold">
        {statusObject.text}
      </Typography>
    </div>
  );
};
export default StatusLabel;
