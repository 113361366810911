import { Idiom } from '.';

export const idiomByBrowser = (): Idiom => {
  let idiom: Idiom;
  let userLang = navigator.language;

  userLang = userLang.substring(0, 2);
  switch (userLang) {
    case 'pt':
      idiom = Idiom.pt_BR;
      break;
    case 'en':
      idiom = Idiom.en_US;
      break;
    case 'es':
      idiom = Idiom.es_ES;
      break;
    default:
      idiom = Idiom.en_US;
      break;
  }
  return idiom;
};
