import Typography from 'components/Typography';
import { removeRequiredCharLabel } from 'utils/strings';
import styles from './index.module.scss';

interface InputLabelProps {
  showLabel: boolean;
  label: string;
}

const InputLabel = ({ showLabel, label }: InputLabelProps) => {
  return showLabel ? (
    <Typography type="label" color="primary" className={styles.label}>
      {removeRequiredCharLabel(label)}
    </Typography>
  ) : null;
};

export default InputLabel;
