export const TEXT_EMPTY = {
  en_US: 'You do not currently have any user accounts.',
  pt_BR: 'No momento, você não tem nenhuma conta de usuário.',
  es_ES: 'Actualmente no tiene ninguna cuenta de usuario.',
};

export const TEXT_VIEW_MORE = {
  en_US: 'View more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};
