import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import styles from './Categories.module.scss';
import { useTheme } from '../../hooks/theme';
import { UserStatus } from '../../utils/category';

interface Props {
  filterUsers: (status: UserStatus) => void;
  selected: string;
}

const UserCategories = (props: Props) => {
  const { filterUsers, selected } = props;
  const { translate } = useIntl();
  const intl = translate(translations);
  const { theme } = useTheme();

  return (
    <div className={`${styles.usersCategories}`}>
      <ul>
        <li
          onClick={() => filterUsers(UserStatus.UNAFFILIATED)}
          className={
            selected === UserStatus.UNAFFILIATED
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
        >
          {intl.TITLE_USER_PENDING}
        </li>
        <li
          onClick={() => filterUsers(UserStatus.AFFILIATED)}
          className={
            selected === UserStatus.AFFILIATED
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
        >
          {intl.TITLE_USER_ACTIVE}
        </li>
      </ul>
    </div>
  );
};

export default UserCategories;
