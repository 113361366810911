import React from 'react';
import { useIntl } from 'hooks/Intl';
import * as translations from '../intl';
import InfoLabelValue from 'pages/Profile/InfoLabelValue/InfoLabelValue';
import { isNull } from 'pages/Profile/Profile';
import EditIcon from 'assets/icons/EditIcon';
import ButtonWithIcon from 'components/ButtonWithIcon/ButtonWithIcon';
import { useModal } from 'hooks/Modal';
import EditPersonalInformation from './EditPersonalInformation';
import { formatDateByIdiom, stringToDateWithoutTimezone } from 'utils/date';
import Typography from 'components/Typography';
import { getNationalityIntl } from 'utils/intl/nationalities';

interface Props {
  user: User | null;
}

const PersonalInformation = ({ user }: Props) => {
  const { translate, idiom } = useIntl();
  const { actions } = useModal();

  const intl = translate(translations);

  const getPhoneType = (title: any) => {
    switch (title) {
      case 'LANDLINE':
        return intl.VALUE_PHONE_TYPE_LN;
      case 'MOBILE':
        return intl.VALUE_PHONE_TYPE_MB;
      default:
        return '';
    }
  };

  const getNationality = () => {
    if (!(user?.nationalities && user?.nationalities[0])) {
      return undefined;
    }
    const nationality = getNationalityIntl(user?.nationalities[0], idiom);
    return nationality;
  };

  const showModalEditPersonalInformation = () => {
    actions.showModal(
      intl.EDIT_PERSONAL_DETAILS,
      <EditPersonalInformation />,
      true,
      false,
      undefined,
      true
    );
  };

  return (
    <>
      <Typography color="primary" type="h3" weight="light">
        {intl.CONTACT_DETALIS}
      </Typography>

      <div className={`row`}>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <InfoLabelValue
              label={intl.LABEL_FIRST_NAME}
              value={isNull(user?.first_name)}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <InfoLabelValue
              label={intl.LABEL_LAST_NAME}
              value={isNull(user?.last_name)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <InfoLabelValue label={intl.LABEL_EMAIL} value={user?.email} />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <InfoLabelValue
              label={intl.LABEL_NATIONALITY}
              value={isNull(getNationality())}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3"></div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <InfoLabelValue
              label={intl.LABEL_BIRTH_DATE}
              value={formatDateByIdiom(
                stringToDateWithoutTimezone(user?.birth_date as string),
                idiom
              )}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <InfoLabelValue
              label={intl.TELEPHONE_NUMBER}
              value={`
                    ${isNull(getPhoneType(user?.phone?.phone_type))}
                    ${isNull(user?.phone?.international_prefix)}
                    ${isNull(user?.phone?.number)}
                  `}
            />
          </div>
        </div>
        <div className="row">
          <ButtonWithIcon
            Icon={EditIcon}
            label={intl.BTN_TEXT_EDIT}
            onClick={showModalEditPersonalInformation}
          />
        </div>
      </div>
    </>
  );
};
export default PersonalInformation;
