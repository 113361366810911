import Calendar from 'components/Calendar';
import Input from 'components/Input';
import Select from 'components/Select';
import { FormikProps } from 'formik';
import { useIntl } from 'hooks/Intl';
import { formsTranslations, nationalitiesTranslations } from 'intls';
import { removeRequiredCharLabel } from 'utils/strings';
import { RegistrationFormValues } from '../../initialValues';

interface PersonalAreaProps {
  formikProps: FormikProps<RegistrationFormValues>;
}

const PersonalArea = ({ formikProps }: PersonalAreaProps) => {
  const { translate } = useIntl();
  const formsIntl = translate(formsTranslations);
  const nationalitiesIntl = translate(nationalitiesTranslations);

  return (
    <>
      <Input
        label={formsIntl.NAME}
        formikProps={formikProps}
        name="first_name"
        maxLength={255}
        className="col-12 col-sm-12 col-md-6"
      />
      <Input
        label={formsIntl.LAST_NAME}
        formikProps={formikProps}
        name="last_name"
        maxLength={255}
        className="col-12 col-sm-12 col-md-6"
      />
      <Input
        label={formsIntl.EMAIL}
        formikProps={formikProps}
        name="email"
        disabled={true}
        maxLength={255}
        className="col-lg-12 col-sm-12"
        type="email"
      />
      <Select
        label={removeRequiredCharLabel(formsIntl.NATIONALITY)}
        name="nationality"
        formikProps={formikProps}
        className="col-lg-6 col-sm-6"
      >
        <option key="" value="">
          {formsIntl.NATIONALITY}
        </option>
        {nationalitiesIntl.NATIONALITY_OPTIONS.values.map(
          (value: string, index: number) => (
            <option key={value} value={value}>
              {nationalitiesIntl.NATIONALITY_OPTIONS.labels[index]}
            </option>
          )
        )}
      </Select>
      <Calendar
        label={formsIntl.BIRTH_DATE}
        name="birth_date"
        formikProps={formikProps}
        maxDate={new Date()}
        className="col-lg-6 col-sm-6"
      />
    </>
  );
};

export default PersonalArea;
