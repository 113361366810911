import { useTheme } from 'hooks/theme';
import React from 'react';
import styles from './index.module.scss';
import Typography from 'components/Typography';

interface BlueBackground {
  title?: string;
  subtitle?: string;
}

const BlueBackground = ({ title }: BlueBackground) => {
  const { theme } = useTheme();

  return (
    <div className={`${styles.backgroundBlue} ${styles[theme]}`}>
      {title && (
        <Typography type="h2" color="tertiary">{`${title}`}</Typography>
      )}
    </div>
  );
};

export default BlueBackground;
