import Button from 'components/Button';
import { useIntl } from 'hooks/Intl';
import UserItem from './UserItem';
import styles from './index.module.scss';
import * as translations from './intl';
import Typography from 'components/Typography';

interface IUserTableProps {
  users: User[] | null;
  showLoadMore: boolean;
  addMore: () => any;
  profiles: any[];
  updateProfileToPerson: (user_id: string, data: affiliate[]) => any;
  partners: Partner[];
  reload: VoidFunction;
}

const UserTable = ({
  users,
  showLoadMore,
  addMore,
  profiles,
  updateProfileToPerson,
  partners,
  reload,
}: IUserTableProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);

  const personList = users?.map(user => {
    return (
      <UserItem
        user={user}
        key={user.person_id}
        profiles={profiles}
        updateProfileToPerson={updateProfileToPerson}
        partners={partners}
        reload={reload}
      />
    );
  });

  if (!users) return null;

  return (
    <div className={styles.container} data-testid="user-table">
      {users && users?.length === 0 ? (
        <div className={styles.msgArea}>
          {
            <Typography color="subtitle" type="label">
              {intl.TEXT_EMPTY}
            </Typography>
          }
        </div>
      ) : (
        <>
          <div>{personList}</div>
          {users?.length > 0 && showLoadMore && (
            <div className={styles.buttonViewMoreArea}>
              <Button type="PrimaryWithoutBorder" onClick={() => addMore()}>
                {intl.TEXT_VIEW_MORE}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserTable;
