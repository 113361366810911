import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useAuth } from 'hooks/Auth';
import { useUser } from 'hooks/User';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { Routes } from 'react-router-dom';
import ProfileEnum from 'utils/profile-enum';
import PrivatesRoutes, {
  registerRoute,
  AdminProfileRoutes,
  NurseProfileRoutes,
  USerEmailNotVerified,
  UserWithoutProfile,
} from './privateRoutes';
import PublicRoutes from './publicRoutes';

const Router = () => {
  const { userAuth, status } = useAuth();
  const { user } = useUser();

  if (status === 'LOADING' || user.status === 'LOADING') {
    return (
      <MainLayout showNavigation={false}>
        <LoadingSpinner fill />
      </MainLayout>
    );
  }

  if (!userAuth.logged) {
    return (
      <Routes>
        {PublicRoutes}
        {PrivatesRoutes}
      </Routes>
    );
  }

  if (userAuth.logged === true && userAuth.userInfo?.email_verified === false) {
    return <Routes>{USerEmailNotVerified}</Routes>;
  }

  if (userAuth.logged === true && user.error === 'Not Found') {
    return <Routes>{registerRoute}</Routes>;
  }

  const profiles = user?.user?.affiliations?.map(
    affiliation => affiliation.role
  );

  if (user.status === 'LOADED' && userAuth.logged) {
    if (profiles?.includes(ProfileEnum.NURSE)) {
      return <Routes>{NurseProfileRoutes} </Routes>;
    } else if (profiles?.includes(ProfileEnum.ADMINISTRATOR)) {
      return <Routes>{AdminProfileRoutes} </Routes>;
    } else {
      return <Routes>{UserWithoutProfile} </Routes>;
    }
  }

  // TODO: Error handling when requests fails
  return <Routes>{PublicRoutes}</Routes>;
};

export default Router;
