/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react';
import { getCookie, setCookie } from '../../utils/cookies';
import { getIdiomByAuth0Locale, Idiom, IdiomForAPI } from '../../utils/intl';
import { idiomByBrowser } from '../../utils/intl/idiomByBrowser';
import { getQueryVariable } from 'utils/uri';
import { useAuth } from 'hooks/Auth';

export type Translations<T> = { [name: string]: T };
export type Translation<T> = Partial<{
  en_US: T;
  pt_BR: T;
  es_ES: T;
}>;

type PropsIntlContext = {
  idiom: Idiom;
  setIdiom: (idiom: Idiom) => void;
  translate: (
    translations: Translations<Translation<any>>
  ) => Translations<any>;
  idiomForApi: () => IdiomForAPI;
  twoDigitsIdiom: () => string;
};

export const IntlContext = createContext<PropsIntlContext>({
  idiom: Idiom.en_US,
  setIdiom: (idiom: Idiom) => null,
  translate: <T,>(_: Translations<T>) => ({ en_US: '' }),
  idiomForApi: () => IdiomForAPI.en_US,
  twoDigitsIdiom: () => '',
});

export interface IntlContextData {
  idiom: Idiom;
}

type Props = {
  intlState?: IntlContextData;
} & PropsWithChildren;

const IntlProvider = (props: Props) => {
  const [idiom, setIdiom] = useState(props?.intlState?.idiom || Idiom.en_US);
  const { userAuth } = useAuth();

  const translate = <T,>(translations: Translations<T>): Translations<any> => {
    const translated = {};
    const keys = Object.keys(translations);
    keys.forEach(key => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      translated[key] = translations[key][idiom];
    });
    return translated;
  };

  const idiomForApi = () =>
    idiom ? (idiom.replace('_', '-') as IdiomForAPI) : IdiomForAPI.en_US;
  const twoDigitsIdiom = () => idiom.substring(0, 2);

  const _setIdiom = (idiom: Idiom) => {
    if (
      idiom === Idiom.en_US ||
      idiom === Idiom.pt_BR ||
      idiom === Idiom.es_ES
    ) {
      setCookie('homeIdiom', idiom);
      setIdiom(idiom);
    }
  };

  useEffect(() => {
    if (!props?.intlState?.idiom) {
      const idiomFromAuth0 =
        userAuth.logged && userAuth?.userInfo?.locale
          ? getIdiomByAuth0Locale(userAuth?.userInfo?.locale)
          : null;
      const idiomFromURL = getQueryVariable('lang') as Idiom;
      const idiomFromCookie = getCookie('homeIdiom') as Idiom;
      const idiomFromBrowser = idiomByBrowser();

      const idiom =
        idiomFromAuth0 || idiomFromURL || idiomFromCookie || idiomFromBrowser;
      _setIdiom(idiom);
    }
  }, [userAuth.logged]);

  const value = {
    idiom,
    setIdiom: _setIdiom,
    translate,
    idiomForApi,
    twoDigitsIdiom,
  };

  return (
    <IntlContext.Provider value={value}>{props.children}</IntlContext.Provider>
  );
};

export const useIntl = () => {
  return useContext(IntlContext);
};

export default IntlProvider;
