import React from 'react';
import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from './config-icon';

const ChevronLeftIcon = props => {
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];

  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 15 5.414 9.414a2 2 0 0 1 0-2.828L11 1"
        stroke={config_icon.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ChevronLeftIcon;
