export const BTN_SAVE = {
  en_US: 'Save',
  pt_BR: 'Salvar',
  es_ES: 'Salvar',
};

export const BTN_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};
