export const ACCEPTING_TERMS_CONDITIONS = {
  en_US: 'Accepting terms and conditions',
  pt_BR: 'Aceitação dos termos e condições',
  es_ES: 'Aceptación de términos y condiciones',
};

export const READ_ACCEPT_TERMS = {
  en_US: 'I read and accept the terms and conditions.',
  pt_BR: 'Eu li e aceito os termos e condições.',
  es_ES: 'Leí y acepto los términos y condiciones.',
};
