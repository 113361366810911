import { UserAuth } from 'hooks/Auth';
import { stringToDateWithoutTimezone } from 'utils/date';

export type RegistrationFormValues = {
  first_name: string;
  last_name: string;
  email: string;
  birth_date: Date | null;
  nationality: string;
  phone: {
    international_prefix: string;
    number: string;
    type: PhoneType | '';
  };
  preferred_language: Language;
  terms: {
    first: boolean;
  };
};

export type UpdateFormValues = {
  first_name: string;
  last_name: string;
  birth_date: Date | null;
  nationality: string;
  phone: {
    international_prefix: string;
    number: string;
    type: PhoneType | '';
  };
  preferred_language: Language;
  terms: {
    first: boolean;
  };
};

// TODO: Get info from Auth0
export const getInitialValues = (
  language: Language,
  user: UserAuth
): RegistrationFormValues => ({
  birth_date: null,
  email: user.userInfo?.email || '',
  first_name: '',
  last_name: '',
  nationality: '',
  phone: {
    international_prefix: '',
    number: '',
    type: '',
  },
  preferred_language: language,
  terms: {
    first: false,
  },
});

export const getInitialValuesToUpdate = (
  language: Language,
  user: User | null
): RegistrationFormValues => {
  return {
    birth_date: user?.birth_date
      ? stringToDateWithoutTimezone(user?.birth_date)
      : null,
    email: user?.email || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    nationality: user?.nationalities ? user?.nationalities[0] : '',
    phone: {
      international_prefix: user?.phone?.international_prefix || '',
      number: user?.phone?.number || '',
      type: user?.phone?.phone_type || '',
    },
    preferred_language: user?.preferred_language || language,
    terms: {
      first: true,
    },
  };
};
