import { useEffect } from 'react';
import ErrorPage from 'components/ErrorBoundary/ErrorPage';
import { useIntl } from '../../hooks/Intl/index';
import * as translations from './intl';
import { trackEventUserAction } from 'AppInsightsTracks';

interface Props {
  redirect?: string;
}

const NotFound = ({ redirect }: Props) => {
  const { translate } = useIntl();
  const intl = translate(translations);

  useEffect(() => {
    trackEventUserAction('#### NOT FOUND ####', {
      path: location.pathname + location.search + location.hash,
    });
  }, []);

  return (
    <ErrorPage
      title={intl.TITLE_PAGE_NOT_FOUND}
      description={intl.DESCRIPTION_PAGE_NOT_FOUND}
      reload={false}
      typeButton="BorderPrimary"
      redirect={redirect}
    />
  );
};

export default NotFound;
