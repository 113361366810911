export const LABEL_MINIMUN_DAYS_IN_ADVANCE = {
  en_US: 'Minimum',
  pt_BR: 'Mínimo',
  es_ES: 'Mínimo',
};

export const LABEL_MAX_DAYS_IN_ADVANCE = {
  en_US: 'Maximum',
  pt_BR: 'Máximo',
  es_ES: 'Máximo',
};

export const LABEL_LIMIT_DAY = {
  en_US: 'Schedule Limit Date',
  pt_BR: 'Data limite para agendamento',
  es_ES: 'Fecha límite para programar',
};

export const BTN_EDIT = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};

export const EDIT_SCHEDULES_ALLOWED = {
  en_US: 'Edit schedules allowed',
  pt_BR: 'Editar disponibilidade de agenda',
  es_ES: 'Editar la disponibilidad de calendario',
};

export const TITLE_SCHEDULES_ALLOWED = {
  en_US: 'Schedules allowed',
  pt_BR: 'Disponibilidade de agenda',
  es_ES: 'Disponibilidad de calendario',
};
