export const TITLE_PAGE_EMAIL_NOT_VERIFIED = {
  en_US: 'Sign up confirmation',
  pt_BR: 'Confirmação de cadastro',
  es_ES: 'Confirmación de registro',
};

export const DESCRIPTION_EMAIL_NOT_VERIFIED_PART1 = {
  en_US:
    'An email with instructions to confirm your registration has been sent to the informed account.',
  pt_BR:
    'Um e-mail com instruções para confirmar seu cadastro foi enviado para a conta informada.',
  es_ES:
    'Se ha enviado un correo electrónico con instrucciones para confirmar tu registro a la cuenta informada.',
};

export const DESCRIPTION_EMAIL_NOT_VERIFIED_PART2 = {
  en_US:
    'You have 24 hours to confirm your registration and be able to access the portal.',
  pt_BR:
    'Você tem 24 horas para confirmar seu cadastro para ter acesso ao portal.',
  es_ES:
    'Tienes 24 horas para confirmar tu registro y poder acceder al portal.',
};

export const BTN_CONFIRM = {
  en_US: 'Go to Log in page',
  pt_BR: 'Ir para a página de Entrar',
  es_ES: 'Ir a la página de Iniciar sesión',
};
