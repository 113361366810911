import React, { useEffect } from 'react';
import { useAuth } from 'hooks/Auth';
import { useModal } from 'hooks/Modal';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useIntl } from '../../hooks/Intl/index';
import StyledModal, {
  ModalType,
} from 'components/Modal/styledModal/StyledModal';
import * as translations from './intl';

const IdleTime = () => {
  const { userAuth } = useAuth();
  const { actions } = useModal();
  const { translate } = useIntl();
  const navigate = useNavigate();

  const intl = translate(translations);

  const onIdle = () => {
    actions.showModal(
      '',
      <StyledModal type={ModalType.ERROR} title={intl.IDLE_SESSION} />,
      false
    );
    navigate('/logout');
  };
  const timeout = Number(process.env.REACT_APP_IDLEPAGE_TIMEOUT || 900000);

  const { start, pause } = useIdleTimer({
    onIdle,
    timeout,
    startManually: true,
  });

  useEffect(() => {
    if (userAuth.logged) {
      start();
    } else {
      pause();
    }
  }, [userAuth.logged]);

  return <></>;
};

export default IdleTime;
