export const TITLE_NURSE_AVAILABILITY = {
  en_US: 'Nurse availability',
  pt_BR: 'Disponibilidade de enfermeiras',
  es_ES: 'Disponibilidad de enfermeras',
};

export const TEXT_EDIT_NURSE_AVAILABLE = {
  en_US: 'Edit nurse availability',
  pt_BR: 'Editar disponibilidade de enfermeiras',
  es_ES: 'Editar la disponibilidad de enfermeras',
};

export const BTN_EDIT = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};

export const LABEL_NURSE_AVAILABILYT_MON_FRI = {
  en_US: 'Starting at 7am',
  pt_BR: 'A partir das 7am',
  es_ES: 'A partir de las 7 a.m.',
};

export const LABEL_NURSE_AVAILABILYT_MON_FRI_07_15 = {
  en_US: 'Starting at 7:15am',
  pt_BR: 'A partir das 7:15am',
  es_ES: 'A partir de las 7:15 a.m.',
};

export const TEXT_WORKING_DAYS = {
  en_US: 'Starting at 7pm',
  pt_BR: 'A partir das 7pm',
  es_ES: 'A partir de las 7 p.m.',
};
