import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import styles from './Categories.module.scss';
import { useTheme } from '../../hooks/theme';
import { AppointmentStatus } from '../../utils/category';

interface Props {
  filterAppointments: (status: AppointmentStatus) => void;
  selected: string;
}

const AppointmentCategories = (props: Props) => {
  const { filterAppointments, selected } = props;
  const { translate } = useIntl();
  const intl = translate(translations);
  const { theme } = useTheme();

  return (
    <div className={`${styles.usersCategories}`}>
      <ul>
        <li
          onClick={() => filterAppointments(AppointmentStatus.ALL)}
          className={
            selected === AppointmentStatus.ALL
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
        >
          {intl.ALL_APPOINTMENTS}
        </li>
        <li
          onClick={() => filterAppointments(AppointmentStatus.PENDING)}
          className={
            selected === AppointmentStatus.PENDING
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
        >
          {intl.PENDING_APPOINTMENTS}
        </li>
        <li
          onClick={() => filterAppointments(AppointmentStatus.DONE)}
          className={
            selected === AppointmentStatus.DONE
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
        >
          {intl.DONE_APPOINTMENTS}
        </li>
        <li
          onClick={() => filterAppointments(AppointmentStatus.NOT_DONE)}
          className={
            selected === AppointmentStatus.NOT_DONE
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
        >
          {intl.NOT_DONE_APPOINTMENTS}
        </li>
        <li
          onClick={() => filterAppointments(AppointmentStatus.CANCELED)}
          className={
            selected === AppointmentStatus.CANCELED
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
        >
          {intl.CANCELED_APPOINTMENTS}
        </li>
      </ul>
    </div>
  );
};

export default AppointmentCategories;
