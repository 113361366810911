import React from 'react';
import { useTheme } from 'hooks/theme';
import { CONFIG_ICON } from './config-icon';

const ChevronDownIcon = props => {
  const { theme } = useTheme();
  const config_icon = CONFIG_ICON[theme];

  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        d="M15 5L9.41421 10.5858C8.63316 11.3668 7.36684 11.3668 6.58579 10.5858L1 5"
        stroke={config_icon.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ChevronDownIcon;
