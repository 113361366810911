import { Translations } from 'hooks/Intl';
import moment from 'moment';
import * as Yup from 'yup';

export const getValidationSchema = (intl: Translations<string>) =>
  Yup.object().shape({
    first_name: Yup.string()
      .required(intl.FIELD_REQUIRED)
      .typeError(intl.FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255),
    last_name: Yup.string()
      .required(intl.FIELD_REQUIRED)
      .typeError(intl.FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255),
    email: Yup.string()
      .email(intl.VALIDATE_EMAIL_VALID)
      .required(intl.FIELD_REQUIRED)
      .typeError(intl.FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255),
    nationality: Yup.string()
      .required(intl.FIELD_REQUIRED)
      .typeError(intl.FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255),
    birth_date: Yup.date()
      .required(intl.FIELD_REQUIRED)
      .nullable()
      .max(new Date(), intl.ENTER_VALID_DATE)
      .test('match', intl.VALIDATE_DATE_VALID, function (birth_date) {
        const dateForValidation = moment('1900-01-01').format('YYYY-MM-DD');
        const birthDateForValidation = moment(birth_date).format('YYYY-MM-DD');
        if (moment(birthDateForValidation).isSameOrBefore(dateForValidation)) {
          return false;
        }
        const yesterday = moment(new Date())
          .subtract(1, 'days')
          .format('YYYY-MM-DD HH:ss');
        return moment(birth_date).isSameOrBefore(yesterday);
      }),
    phone: Yup.object().shape({
      type: Yup.string()
        .required(intl.FIELD_REQUIRED)
        .oneOf(['MOBILE', 'LANDLINE'], intl.INVALID_FIELD),
      international_prefix: Yup.string()
        .required(intl.FIELD_REQUIRED)
        .typeError(intl.FIELD_REQUIRED),
      number: Yup.string()
        .required(intl.FIELD_REQUIRED)
        .typeError(intl.FIELD_REQUIRED),
    }),
    terms: Yup.object().shape({
      first: Yup.bool().oneOf([true], intl.FIELD_REQUIRED),
    }),
  });
