export const HOME = {
  en_US: 'Home',
  es_ES: 'Home',
  pt_BR: 'Home',
};

export const LOG_OUT = {
  en_US: 'Log out',
  pt_BR: 'Sair',
  es_ES: 'Cerrar sesión',
};

export const LOG_IN = {
  en_US: 'Log in',
  es_ES: 'Iniciar sesión',
  pt_BR: 'Entrar',
};

export const ADMINISTRATOR_LABEL = {
  en_US: 'Administrator',
  pt_BR: 'Administrador',
  es_ES: 'Administrador',
};

export const NURSE_LABEL = {
  en_US: 'Nurse',
  pt_BR: 'Enfermeira',
  es_ES: 'Enfermera',
};

export const USER_ACCOUNT = {
  en_US: 'User accounts',
  es_ES: 'Cuentas de usuario',
  pt_BR: 'Contas de usuário',
};

export const APPOINTMENTS = {
  en_US: 'Appointments',
  es_ES: 'Citas',
  pt_BR: 'Agendamentos',
};

export const CONTACT_DETALIS = {
  en_US: 'Account information',
  pt_BR: 'Informações da conta',
  es_ES: 'Información de la cuenta',
};

export const SETTINGS = {
  en_US: 'Settings',
  es_ES: 'Configuración',
  pt_BR: 'Configurações',
};

export const COMPLETE_YOUR_REGISTRATION = {
  en_US: 'Complete your registration',
  pt_BR: 'Complete seu cadastro',
  es_ES: 'Completa tu registro',
};
