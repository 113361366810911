export const TEXT_OK = {
  en_US: 'Ok',
  es_ES: 'Ok',
  pt_BR: 'Ok',
};

export const BTN_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};
