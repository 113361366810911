export const UPDATING_DATA = {
  en_US: 'Saving your data',
  pt_BR: 'Salvando seus dados',
  es_ES: 'Guardando tus datos',
};

export const CONGRATULATIONS = {
  en_US: 'Congratulations!',
  pt_BR: 'Parabéns!',
  es_ES: '¡Felicidades!',
};

export const UPDATE_COMPLETE = {
  en_US: 'Your registration is now updated.',
  pt_BR: 'Seu cadastro agora está atualizado.',
  es_ES: 'Tu registro ya está actualizado.',
};

export const UNEXPECTED_ERROR = {
  en_US: 'An unexpected error has occurred.',
  pt_BR: 'Ocorreu um erro inesperado.',
  es_ES: 'Ha ocurrido un error inesperado.',
};

export const BTN_SAVE = {
  en_US: 'Save',
  pt_BR: 'Salvar',
  es_ES: 'Salvar',
};

export const BTN_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};
