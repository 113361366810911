export const USERS_SUBTITLE = {
  en_US: 'Users',
  pt_BR: 'Usuários',
  es_ES: 'Usuarios',
};

export const USERS_PROFILE = {
  en_US: 'Profile',
  pt_BR: 'Perfil',
  es_ES: 'Perfil',
};

export const ADMINISTRATOR_LABEL = {
  en_US: 'Administrator',
  pt_BR: 'Administrador',
  es_ES: 'Administrador',
};

export const NURSE_LABEL = {
  en_US: 'Nurse',
  pt_BR: 'Enfermeira',
  es_ES: 'Enfermera',
};

export const BTN_SEARCH = {
  en_US: 'Search',
  pt_BR: 'Procurar',
  es_ES: 'Buscar',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const UPDATE_SUCCESS = {
  en_US: 'User successfully updated!',
  pt_BR: 'Usuario atualizado com sucesso!',
  es_ES: '¡Usuario actualizado correctamente!',
};

export const DELETED_SUCCESS = {
  en_US: 'Profile successfully removed!',
  pt_BR: 'Perfil removida com sucesso!',
  es_ES: 'Perfil eliminada con éxito!',
};

export const TEXT_ERROR = {
  en_US: 'The operation could not be completed.',
  pt_BR: 'Não foi possível concluir a operação.',
  es_ES: 'No se pudo completar la operación.',
};

export const TEXT_PARTNER = {
  en_US: 'Partner',
  pt_BR: 'Parceiro',
  es_ES: 'Socio',
};

export const CREATED_DATE = {
  en_US: 'Creation date',
  pt_BR: 'Data de criação',
  es_ES: 'Fecha de creacion',
};
