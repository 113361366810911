export const FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const FIELD_VALIDATION_255 = {
  en_US: 'Please enter a maximum of 255 characters.',
  pt_BR: 'Por favor, insira no máximo 255 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 255 caracteres.',
};

export const INVALID_FIELD = {
  en_US: 'Invalid field.',
  pt_BR: 'Campo inválido.',
  es_ES: 'Campo inválido.',
};

export const SCHEDULING_DATE_UNAVAILABLE = {
  en_US: 'Scheduling date unavailable.',
  pt_BR: 'Data de agendamento indisponível.',
  es_ES: 'Fecha de programación no disponible.',
};

export const ENTER_VALID_DATE = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor insira uma data válida.',
  es_ES: 'Por favor introduzca una fecha valida.',
};

export const NAME = {
  en_US: 'Name *',
  pt_BR: 'Nome *',
  es_ES: 'Nombre *',
};

export const LAST_NAME = {
  en_US: 'Last name *',
  pt_BR: 'Sobrenome *',
  es_ES: 'Apellido *',
};

export const EMAIL = {
  en_US: 'E-mail *',
  pt_BR: 'E-mail *',
  es_ES: 'Correo eletrónico *',
};

export const NATIONALITY = {
  en_US: 'Nationality *',
  pt_BR: 'Nacionalidade *',
  es_ES: 'Nacionalidad *',
};

export const BIRTH_DATE = {
  en_US: 'Date of birth *',
  pt_BR: 'Data de nascimento *',
  es_ES: 'Fecha de nacimiento *',
};

export const COUNTRY_PHONE = {
  en_US: 'Phone country *',
  pt_BR: 'País do telefone *',
  es_ES: 'País del teléfono *',
};

export const PHONE_STATE = {
  en_US: 'Phone state *',
  pt_BR: 'Estado do telefone *',
  es_ES: 'Estado del teléfono *',
};

export const PHONE_CITY = {
  en_US: 'Phone city *',
  pt_BR: 'Cidade do telefone *',
  es_ES: 'Ciudad del teléfono *',
};

export const PHONE_NUMBER = {
  en_US: 'Phone number *',
  pt_BR: 'Número de telefone *',
  es_ES: 'Número de teléfono *',
};

export const PHONE_TYPE = {
  en_US: 'Type *',
  pt_BR: 'Tipo *',
  es_ES: 'Tipo *',
};

export const VALUE_PHONE_TYPE_LANDLINE = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MOBILE = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const PREFIX = {
  en_US: 'Country prefix *',
  pt_BR: 'Prefixo do país *',
  es_ES: 'Prefijo del país *',
};

export const AREA_CODE_AND_NUMBER = {
  en_US: 'Number *',
  pt_BR: 'Número *',
  es_ES: 'Número *',
};

export const INCLUDE_COUNTRY_CODE = {
  en_US: 'Include your international country code.',
  pt_BR: 'Inclua seu código internacional do país.',
  es_ES: 'Incluya su código de país internacional.',
};

export const MANDATORY_FIELDS = {
  en_US: 'Fields marked with * are mandatory.',
  pt_BR: 'Os campos marcados com * são obrigatórios.',
  es_ES: 'Los campos marcados con * son obligatorios.',
};

export const BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const NEXT = {
  en_US: 'Next step',
  pt_BR: 'Próximo passo',
  es_ES: 'Próximo paso',
};

export const CONCLUDE = {
  en_US: 'Conclude',
  pt_BR: 'Concluir',
  es_ES: 'Concluir',
};

export const MISTER = {
  en_US: 'Mister',
  pt_BR: 'Senhor',
  es_ES: 'Señor',
};

export const MS = {
  en_US: 'Ms',
  pt_BR: 'Senhora',
  es_ES: 'Señora',
};

export const MISS = {
  en_US: 'Miss',
  pt_BR: 'Senhorita',
  es_ES: 'Señorita',
};

export const DR = {
  en_US: 'Doctor',
  pt_BR: 'Doutor',
  es_ES: 'Doctor',
};

export const PR = {
  en_US: 'Professor',
  pt_BR: 'Professor',
  es_ES: 'Maestra',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};
