import { FocusEvent } from 'react';
import { datefromFormattedDate } from 'utils/date';
import { Idiom } from 'utils/intl';
import { applyDateMask } from 'utils/strings';

const onChangeRaw = (
  event: FocusEvent<HTMLInputElement, Element>,
  onChange: (date: Date, event: FocusEvent<HTMLInputElement, Element>) => void,
  idiom: Idiom
) => {
  const { target } = event;

  target.value = applyDateMask(target.value);

  if (target.value.length > 10) {
    target.value = target.value.substring(0, 10);
  } else if (target.value.length === 10) {
    const date = datefromFormattedDate(target.value, idiom);
    if (String(date) !== 'Invalid Date') {
      onChange(date, event);
    }
  }
};

export { onChangeRaw };
