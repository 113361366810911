import React from 'react';
import styles from './RedBar.module.scss';
import { useTheme } from '../../hooks/theme/index';
import Typography from 'components/Typography';

interface Props {
  message: string;
}

const RedBar = ({ message }: Props) => {
  const { theme } = useTheme();

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <Typography color="background" type="label">
        {message}
      </Typography>
    </div>
  );
};

export default RedBar;
