import React from 'react';
import styles from './ErrorPage.module.scss';
import Button, { ButtonTypes } from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import Typography from 'components/Typography';

interface Props {
  title?: string;
  description?: string;
  reload?: boolean;
  redirect?: string;
  redirectExternally?: boolean;
  typeButton?: ButtonTypes;
}

const ErrorPage = ({
  title,
  description,
  reload = true,
  redirect,
  redirectExternally = false,
  typeButton,
}: Props) => {
  const navigate = useNavigate();
  const { translate } = useIntl();

  const intl = translate(translations);

  return (
    <div className={styles.container}>
      <Typography
        type="h1"
        color="primary"
        weight="light"
        className={styles.title}
      >
        {title || intl.TITLE}
      </Typography>
      <Typography
        type="label"
        weight="light"
        color="subtitle"
        className={`${styles.description}`}
      >
        {description || intl.DESCRIPTION}
      </Typography>
      <div className={styles.wrapperButton}>
        <Button
          onClick={() => {
            if (redirectExternally && redirect) {
              window.location.href = redirect;
            } else {
              navigate(redirect || '/');
              if (reload) {
                window.location.reload();
              }
            }
          }}
          type={typeButton || 'Secondary'}
        >
          {intl.GO_HOMEPAGE}
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
