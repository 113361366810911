export const HELLO_TEXT = {
  en_US: 'Hi',
  pt_BR: 'Oi',
  es_ES: 'Hola',
};

export const MESSAGE_TEXT = {
  en_US: 'Welcome!',
  pt_BR: 'Bem-vindo(a)!',
  es_ES: '¡Bienvenido(a)!',
};

export const BUTTON_ADMIN_TEXT = {
  en_US: 'User Admin',
  pt_BR: 'Administração de Usuários',
  es_ES: 'Administracion de Usuarios',
};

export const BUTTON_APPOINTMENT_TEXT = {
  en_US: 'Appointments',
  pt_BR: 'Agendamentos Existentes',
  es_ES: 'Agendamenientos Existentes',
};

export const BUTTON_CONFIG_TEXT = {
  en_US: 'Appointments Configuration',
  pt_BR: 'Configuração de Agendamentos',
  es_ES: 'Configuracion de Agendamientos',
};

export const TITLE_ASSOCIATE_PROFILE = {
  en_US: 'Access approval',
  pt_BR: 'Aprovar acesso',
  es_ES: 'Aprobar el acceso',
};
export const TITLE_STATUS_APPOINTMENT = {
  en_US: 'Track appointments',
  pt_BR: 'Acompanhar agendamentos',
  es_ES: 'Seguir citas',
};

export const TEXT_APPOINTMENT_DONE = {
  en_US: 'Appointments made today',
  es_ES: 'Citas realizadas hoy',
  pt_BR: 'Agendamentos realizados hoje',
};

export const TEXT_ACTIVE_USERS = {
  en_US: 'Active users',
  es_ES: 'Usuarios activos',
  pt_BR: 'Usuários ativos',
};
export const TEXT_NO_PROFILE_USERS = {
  en_US: 'Pending access approval users',
  es_ES: 'Usuarios en espera de aprobación de acceso',
  pt_BR: 'Usuários aguardando aprovação de acesso',
};

export const TEXT_APPOINTMENT_PENDING = {
  en_US: `Today's pending appointments`,
  es_ES: 'Citas pendientes de hoy',
  pt_BR: 'Agendamentos pendentes de hoje',
};

export const TEXT_APPOINTMENT_WEEK = {
  en_US: `Schedules of the week`,
  es_ES: 'Citas de la semana',
  pt_BR: 'Agendamentos da semana',
};

export const TEXT_APPOINTMENT_MONTH = {
  en_US: `Appointments of the month`,
  es_ES: 'Citas del mes',
  pt_BR: 'Agendamentos do mês',
};
