import { format } from 'date-fns';
import { DATE_FORMAT } from 'utils/date';
import { ValuesExceptionForm } from './initialValues';

export interface ValuesExceptionToAPI {
  affected_date: string;
  nurses_available: {
    weekdays_7_00: number;
    weekdays_7_15: number;
    weekends_and_nights: number;
  };
}

export const formatExceptionToAPI = (
  data: ValuesExceptionForm
): ValuesExceptionToAPI => {
  return {
    affected_date: format(data.affected_date as Date, DATE_FORMAT),
    nurses_available: {
      weekdays_7_00: Number(data.nurses_available.weekdays_7_00),
      weekdays_7_15: Number(data.nurses_available.weekdays_7_15),
      weekends_and_nights: Number(data.nurses_available.weekends_and_nights),
    },
  };
};
