export const BTN_EDIT = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const EDIT_HOLIDAYS_EXCEPTIONS = {
  en_US: 'Edit exception',
  pt_BR: 'Editar exceção',
  es_ES: 'Editar excepción',
};

export const DELETED_SUCCESS = {
  en_US: 'Exception successfully removed!',
  pt_BR: 'Exceção removida com sucesso!',
  es_ES: '¡Excepción eliminada con éxito!',
};

export const TEXT_ERROR = {
  en_US: 'The operation could not be completed.',
  pt_BR: 'Não foi possível concluir a operação.',
  es_ES: 'No se pudo completar la operación.',
};

export const CREATED_SUCCESS = {
  en_US: 'Exception successfully created!',
  pt_BR: 'Exceção criada com sucesso!',
  es_ES: '¡Excepción creada con éxito!',
};

export const UPDATE_SUCCESS = {
  en_US: 'Exception successfully updated!',
  pt_BR: 'Exceção atualizada com sucesso!',
  es_ES: '¡Excepción actualizada con éxito!',
};

//FORM - LABELS
export const LABEL_DATE = {
  en_US: 'Date',
  pt_BR: 'Data',
  es_ES: 'Fecha',
};
export const LABEL_NORMAL_PERIOD = {
  en_US: 'Starting at 7am',
  pt_BR: 'A partir das 7am',
  es_ES: 'A partir de las 7 a.m.',
};

export const LABEL_NURSE_AVAILABILYT_MON_FRI_07_15 = {
  en_US: 'Starting at 7:15am',
  pt_BR: 'A partir das 7:15am',
  es_ES: 'A partir de las 7:15 a.m.',
};

export const LABEL_AFTER_PERIOD = {
  en_US: 'Starting at 7pm',
  pt_BR: 'A partir das 7pm',
  es_ES: 'A partir de las 7 p.m.',
};

export const DELETE_EXCEPTION_TITLE = {
  en_US: 'Are you sure you want to remove the selected exception?',
  pt_BR: 'Você está certo de que deseja remover a exceção selecionada?',
  es_ES: '¿Está seguro de que desea eliminar la excepción seleccionada?',
};

export const DELETE_EXCEPTION_SUBTITLE = {
  en_US: 'This action cannot be reversed.',
  pt_BR: 'Está ação não poderá ser revertida.',
  es_ES: 'No se podrá revertir esta acción.',
};

export const TEXT_YES_I_DO = {
  en_US: `Yes, I want to remove`,
  pt_BR: 'Sim, quero remover',
  es_ES: 'Sí, quiero eliminar',
};

export const TEXT_NO = {
  en_US: 'No, I want to keep the exception',
  pt_BR: 'Não, desejo manter a exceção',
  es_ES: 'No, quiero mantener la excepción',
};
