import { AxiosInstance } from 'axios';
import { errorHandler } from './errorHandler/errorHandler';
import { ValuesExceptionToAPI } from 'pages/Admin/ExceptionsArea/ExceptionForm/formatExceptionAPI';

export default (axios: AxiosInstance) => ({
  getRules: async (partner_id: string) => {
    const url = `/api/v1/partner/${partner_id}/rules`;
    return errorHandler<AppointmentsRules>(
      axios.get(url),
      'getRules - partner/rules',
      {
        url,
      }
    );
  },
  updateRules: async (
    partner_id: string,
    data: {
      nurses_available?: NurseAvailability;
      schedule_rules?: SchedulesRules;
    }
  ) => {
    const url = `/api/v1/partner/${partner_id}/rules`;
    return errorHandler(axios.patch(url, data), 'updateRules - partner/rules', {
      url,
      data,
    });
  },
  createException: async (partner_id: string, data: ValuesExceptionToAPI) => {
    const url = `/api/v1/partner/${partner_id}/rules/exceptions`;
    return errorHandler(
      axios.post(url, data),
      'createException - nurseAvailability',
      {
        url,
        data,
      }
    );
  },
  updateException: async (
    partner_id: string,
    exception_id: string,
    data: ValuesExceptionToAPI
  ) => {
    const url = `/api/v1/partner/${partner_id}/rules/exceptions/${exception_id}`;
    return errorHandler(
      axios.patch(url, data),
      'updateException - nurseAvailability',
      {
        url,
        data,
      }
    );
  },
  deleteException: async (partner_id: string, exception_id: string) => {
    const url = `/api/v1/partner/${partner_id}/rules/exceptions/${exception_id}`;
    return errorHandler(
      axios.delete(url),
      'deleteException - nurseAvailability',
      {
        url,
      }
    );
  },
});
