export const PROFILE_TEXT = {
  en_US: 'Profile',
  pt_BR: 'Perfil',
  es_ES: 'Perfil',
};

export const PARTNER_TEXT = {
  en_US: 'Partner',
  pt_BR: 'Parceiro',
  es_ES: 'Socio',
};

export const YOUR_PROFILES = {
  en_US: 'Your profiles',
  pt_BR: 'Seus perfis',
  es_ES: 'Tus perfiles',
};

export const ADMINISTRATOR_LABEL = {
  en_US: 'Administrator',
  pt_BR: 'Administrador',
  es_ES: 'Administrador',
};

export const NURSE_LABEL = {
  en_US: 'Nurse',
  pt_BR: 'Enfermeira',
  es_ES: 'Enfermera',
};

export const LABEL_FIRST_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo eletrónico',
};

export const LABEL_NATIONALITY = {
  en_US: 'Nationality',
  pt_BR: 'Nacionalidade',
  es_ES: 'Nacionalidad',
};

export const LABEL_MISTER = {
  en_US: 'Mister',
  pt_BR: 'Senhor',
  es_ES: 'Señor',
};

export const LABEL_MS = {
  en_US: 'Ms',
  pt_BR: 'Senhora',
  es_ES: 'Señora',
};

export const LABEL_MISS = {
  en_US: 'Miss',
  pt_BR: 'Senhorita',
  es_ES: 'Señorita',
};

export const LABEL_DR = {
  en_US: 'Doctor',
  pt_BR: 'Doutor',
  es_ES: 'Doctor',
};

export const LABEL_PR = {
  en_US: 'Professor',
  pt_BR: 'Professor',
  es_ES: 'Maestra',
};

export const TELEPHONE_NUMBER = {
  en_US: 'Telephone number',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
};

export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const CONTACT_DETALIS = {
  en_US: 'Personal details',
  es_ES: 'Información personal',
  pt_BR: 'Informações pessoais',
};

export const EDIT_PERSONAL_DETAILS = {
  en_US: 'Edit personal details',
  pt_BR: 'Editar informações pessoais',
  es_ES: 'Editar información personal',
};

export const BTN_TEXT_EDIT = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};
