import styles from './index.module.scss';
import { useTheme } from 'hooks/theme';
import Typography from 'components/Typography';

interface InfoAmountProps {
  Icon: any[];
  amounts: number[];
  labels: string[];
  marginRight?: boolean;
  big?: boolean;
}

const InfoAmountMultiple = ({
  Icon,
  amounts,
  labels,
  marginRight,
  big,
}: InfoAmountProps) => {
  const { theme } = useTheme();
  return (
    <div
      data-testid="info-amount"
      className={`${styles.container} ${styles[theme]} ${
        marginRight ? styles.marginRight : ''
      }
        ${big ? styles.big : ''}
      `}
    >
      <div className={`${styles.infoContainerDouble}`}>
        {amounts.map((amount, index) => {
          const IconItem = Icon[index];
          return (
            <div className={`${styles.amounContainer}`} key={index}>
              <header>
                <IconItem width={30} height={30} />
              </header>
              <div key={amount} className={styles.amount}>
                <Typography color="primary" className={styles.amountText}>
                  {amount}
                </Typography>
                <p className={`${styles[theme]} ${styles.label}`}>
                  {labels[index]}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InfoAmountMultiple;
