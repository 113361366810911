import React from 'react';
import Button from 'components/Button';
import styles from './ButtonWithIcon.module.scss';
import Typography from 'components/Typography';

interface Props {
  Icon: any;
  onClick: VoidFunction;
  label: string;
}

const ButtonWithIcon = ({ Icon, onClick, label }: Props) => {
  return (
    <div className={styles.buttonContainer}>
      <Button onClick={onClick} type="Background">
        <Icon width={20} height={20} />
        {label && (
          <Typography type="label" weight="regular">
            {label}
          </Typography>
        )}
      </Button>
    </div>
  );
};

export default ButtonWithIcon;
