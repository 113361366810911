export const TITLE_PAGE_PATIENT = {
  en_US: 'Your profile is a pacient',
  pt_BR: 'Seu perfil é de paciente',
  es_ES: 'Tu perfil es un paciente',
};

export const DESCRIPTION_PATIENT = {
  en_US: 'Access your account from the portal.',
  pt_BR: 'Acesse sua conta pelo portal.',
  es_ES: 'Accede a tu cuenta a través del portal.',
};

export const TITLE_PAGE_MISSING_APPROVED_BY_ADMIN = {
  en_US: 'Your profile needs to be approved by an administrator',
  pt_BR: 'Seu perfil precisa ser aprovado por um administrador',
  es_ES: 'Tu perfil debe ser aprobado por un administrador',
};

export const DESCRIPTION_MISSING_APPROVED_BY_ADMIN = {
  en_US:
    'Please contact an administrator to have your access properly authorized.',
  pt_BR:
    'Por favor, entre em contato com um administrador para que seus acessos sejam devidamente autorizados.',
  es_ES:
    'Póngase en contacto con un administrador para que autorice correctamente tu acceso.',
};

export const HELLO_TEXT = {
  en_US: 'Hi',
  pt_BR: 'Oi',
  es_ES: 'Hola',
};

export const MESSAGE_TEXT = {
  en_US: 'Welcome!',
  pt_BR: 'Bem-vindo(a)!',
  es_ES: '¡Bienvenido(a)!',
};
