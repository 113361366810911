export const TITLE_SESSION_EXPIRED = {
  en_US: 'Session expired',
  pt_BR: 'Sessão expirada',
  es_ES: 'Sesión expirada',
};

export const DESCRIPTION_SESSION_EXPIRED = {
  en_US:
    'You have been logged out because of inactivity. Click the button below to log in again.',
  pt_BR:
    'Você foi desconectado por inatividade. Clique no botão abaixo para entrar novamente.',
  es_ES:
    'Se ha cerrado la sesión por inactividad. Haga clic en el botón de abajo para iniciar sesión nuevamente.',
};
