import { AxiosInstance } from 'axios';
import { errorHandler } from './errorHandler/errorHandler';

// ! All endpoint needs to be precedded by /api/ when Auth0 is configured

export default (axios: AxiosInstance) => ({
  update: async (user_id: string, affiliates: affiliate[]) => {
    const url = `/api/v1/admin/${user_id}/affiliate`;
    return errorHandler<any>(
      axios.put(url, affiliates),
      'affiliation - update',
      {
        affiliates,
        url,
      }
    );
  },
});
