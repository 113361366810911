// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { Actions as AuthActions } from 'hooks/Auth';
import { Actions as ModalActions, ErrorType } from 'hooks/Modal';
import { kindBusinessExceptions } from './kindBusinessException';
import {
  trackEventUserAction,
  trackExceptionMonitor,
} from '../../AppInsightsTracks';

let authObserver = {} as AuthActions;
let modalObserver = {} as ModalActions;

export const setAuthObserver = (_observer: AuthActions) => {
  authObserver = _observer;
};

export const setModalObserver = (_observer: ModalActions) => {
  modalObserver = _observer;
};

export const unauthorized = () => {
  trackEventUserAction('#### unauthorized ####', null);
  authObserver.sessionExpired();
};

export const timeoutMove = (response: AxiosResponse) => {
  if (response && response.data && response.data.offline) {
    trackEventUserAction('#### timeoutMove ####', null);
    modalObserver.showError(ErrorType.PORTAL_OFFLINE);
  }
};

export const statusReaction = {
  401: () => unauthorized(),
  406: (response: AxiosResponse) => timeoutMove(response),
};

const isBusinessTrackException = (
  error: AxiosError<{ error_description: string }>,
  error_name: string
) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error_description
  ) {
    const error_description = error.response.data.error_description
      .replace(/ /g, '_')
      .toUpperCase();
    const findKindBusinessExceptions = kindBusinessExceptions.find(
      exceptions =>
        exceptions.error_name === error_name &&
        exceptions.error_description === error_description
    );
    return (
      findKindBusinessExceptions &&
      findKindBusinessExceptions.error_description.includes(error_description)
    );
  }
  return false;
};

export const errorHandler = async <T>(
  request: AxiosPromise<T>,
  error_name: string,
  bodyRequest: any
): Promise<AxiosResponse<T>> => {
  try {
    const response = await request;
    if (response && error_name === 'updateBinWithTokenex') {
      trackEventUserAction(
        '#### (updateBinWithTokenex) TOKENEX POLICY SF - SUCCESS ####',
        bodyRequest
      );
    }
    return response;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      modalObserver.closeModal();
      if (
        !isBusinessTrackException(
          error as AxiosError<{ error_description: string }>,
          error_name
        )
      ) {
        trackExceptionMonitor(error_name, error, bodyRequest);
      }

      if (statusReaction[error?.response?.status])
        statusReaction[error.response.status](error.response);
      else throw error;
    } else {
      if (error.message !== 'Cancelation') {
        if (!window.navigator.onLine) {
          modalObserver.showError(ErrorType.CONNECTION_ERROR);
        }
      }
    }
  }
};
