import CheckBox from 'components/Checkbox';
import FormStepWrapper from 'components/FormStepWrapper';
import Typography from 'components/Typography';
import { FormikProps } from 'formik';
import { useIntl } from 'hooks/Intl';
import * as translations from './intl';
import styles from './index.module.scss';
import { RegistrationFormValues } from '../initialValues';

interface TermsAndConditionsProps {
  stepState: State<number>;
  formikProps: FormikProps<RegistrationFormValues>;
}

const TermsAndConditions = ({
  stepState,
  formikProps,
}: TermsAndConditionsProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);

  const validateTerms = () => {
    formikProps.validateField('terms.first');
    formikProps.setFieldTouched('terms.first', true);

    const allAccepted = Object.values(formikProps.values.terms).every(
      term => term
    );
    if (allAccepted) {
      const [step, setStep] = stepState;
      setStep(step + 1);
    }
  };

  return (
    <FormStepWrapper
      title={intl.ACCEPTING_TERMS_CONDITIONS}
      onNext={validateTerms}
      showRequiredDisclaimer={false}
      nextButtonDisabled={!formikProps.values.terms.first}
    >
      <Typography type="p" color="primary" className={styles.termsText}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat
        sagittis nibh ac scelerisque. Integer vel mollis dolor. Nunc orci diam,
        congue non nisi ullamcorper, sollicitudin viverra dui. Nunc iaculis nunc
        sit amet lacus sagittis, et cursus quam fermentum. Integer ultrices ut
        mauris eget tempor. Class aptent taciti sociosqu ad litora torquent per
        conubia nostra, per inceptos himenaeos. Nam varius sodales leo a
        pharetra. Sed gravida velit at accumsan dignissim. Nulla varius erat
        ullamcorper sem faucibus viverra. Nam vulputate, turpis at vestibulum
        laoreet, arcu mi mollis ante, eu vehicula mauris velit eget nunc.
      </Typography>
      <CheckBox name="terms.first" formikProps={formikProps}>
        {intl.READ_ACCEPT_TERMS}
      </CheckBox>
    </FormStepWrapper>
  );
};

export default TermsAndConditions;
