/* eslint-disable no-unused-vars */

export enum Idiom {
  en_US = 'en_US',
  pt_BR = 'pt_BR',
  es_ES = 'es_ES',
}

export enum IdiomForAPI {
  en_US = 'en-US',
  pt_BR = 'pt-BR',
  es_ES = 'es-ES',
}

export const getIdiomByAuth0Locale = (locale: string): Idiom | null => {
  if (locale.includes('en')) {
    return Idiom.en_US;
  } else if (locale.includes('pt')) {
    return Idiom.pt_BR;
  } else if (locale.includes('es')) {
    return Idiom.es_ES;
  }
  return null;
};

/* eslint-enable no-unused-vars */
